.teams-cont {max-width:95vw; margin:1rem auto; width:100%}
.teams-cont .ant-space .ant-space-item {display: inherit;}
.teams-cont .ant-dropdown-trigger {align-items: center; display: flex; gap: 0.6rem;}
.teams-cont .ant-dropdown-trigger svg { width: 12px;height:8px; opacity: 0.7;  }
.addteamwrap.ant-modal .ant-modal-content {padding:0;}
.addteamwrap .ant-modal-header {border-bottom:1px solid rgba(21,21,21,0.06); padding: 0.5rem 1.5em;}
.addteamwrap label.ant-checkbox-wrapper {margin-left: 8px; padding:0.5rem 2px; gap:0.7rem }
.addteamwrap .usertitle {border-bottom:1px solid rgba(21,21,21,0.06)}
.seaechteam {border:none; border-radius:none; height:36px;}
.addteamwrap .ant-modal-body {padding:20px 24px}
.addteamuser .ant-modal-footer{border-top:1px solid rgba(21,21,21,0.06); padding:12px 12px 0}