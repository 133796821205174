/* Colors

primary : #151515
secondary : #8D8D8D
green : #25D366
red : #FF0000

*/
* {font-family: "Poppins-Regular", sans-serif;}
.font-16 {font-size:16px}
.ant-layout-header a { text-decoration: none;}
.ant-menu-submenu .ant-menu-item a {text-decoration: none;}
.ant-menu-submenu .ant-menu-item { border-bottom: 1px solid rgba(21,21,21,0.05); border-radius: 0 !important; margin: 0 !important; height: 46px !important; display: flex !important; align-items: center; width: 100% !important;}
.ant-table-wrapper .ant-table-tbody > tr > td, .ant-table-wrapper .ant-table-thead > tr > th {white-space: nowrap !important;}
div.nav-tabs .nav-link {font-size:12px;}
.name.leadpool { color: #FCBA04;}
.matchitem .matchinfo .col-9 { padding: 0;}
.uploadformgroup .form-group input[type="datetime-local"], .uploadformgroup.form-group select {width:100%;}
.btn-success {
    --bs-btn-bg:#25D366;
    --bs-btn-border-color :#25D366; 
}
.btn-primary {--bs-btn-hover-bg:#FFF; --bs-btn-bg:#fff; --bs-btn-border-color:rgba(21,21,21,0.16)}
.font-bold {font-family: "Poppins-Bold", sans-serif !important}

.ant-table-content {overflow-x:scroll}
span.ant-menu-title-content {font-family: 'Poppins-Regular';}

/* TOP */
.logo {margin:1rem 0; width:fit-content}
header .ant-space .ant-space-item { margin-right: 1rem; margin-bottom: 0;}

/* navbar */
.nav-link {font-size:0.875rem}
ul.ant-menu-overflow.ant-menu.ant-menu-root.ant-menu-horizontal {width: 100%;border-bottom: 1px solid rgba(5, 5, 5, 0.15);}
.ant-menu-sub .ant-menu-item.ant-menu-item-selected.ant-menu-item-only-child { background: rgba(21,21,21,0.06); color: #151515;}

.followup_p .iconwrap {
    background: #C51161 !important;
}

.followup_n .iconwrap {
    background: #C51161 !important;
}
/* Listings */
/* Tabs */
.ant-tabs-tab.ant-tabs-tab-with-remove {font-weight:400}
.ant-tabs .ant-tabs-nav-wrap .tab-btn.ant-tabs-tab-active span {color: #FFF !important; opacity:1 !important}
.ant-tabs-card >.ant-tabs-nav .ant-tabs-tab {background: transparent;border: none;padding: 0 !important; min-width: 100px; justify-content: space-evenly; text-align: center;}
.ant-tabs-tab-btn span { text-transform: capitalize;font-size: 14px;font-family: 'Poppins-Regular';color: #151515;text-decoration: none;}
.ant-tabs-tab {padding: 5px 0.8rem !important;}
.ant-tabs-nav::before{display:none}
.ant-menu-item.ant-menu-item-active::after, .ant-menu-submenu:hover::after {border-bottom-color:#151515 !important} 
.ant-tabs .ant-tabs-nav-wrap .ant-tabs-tab span {color: #151515; display: flex; align-items: center;}
.ant-tabs .ant-tabs-nav-wrap .ant-tabs-tab-active span { display:flex; align-items: center;}
.ant-tabs.ant-tabs-top .ant-tabs-nav {margin-bottom: 1rem; background: rgba(21,21,21,0.02);padding: 0 1rem;border-radius:5px;  height:50px;}
.ant-menu-light.ant-menu-horizontal > .ant-menu-submenu-selected span {color: #151515; font-family: "Poppins-Bold", sans-serif;}
.ant-tabs-tab.ant-tabs-tab-with-remove.ant-tabs-tab-active{position:relative}
.ant-tabs-tab.ant-tabs-tab-with-remove.ant-tabs-tab-active::after {content:""; width:100%; height:2px; background:#151515;display:block; position:absolute; bottom:0; left:0 }
.ant-tabs-tab.ant-tabs-tab-with-remove:hover::after {content:""; width:100%; height:2px; background:#151515;display:block; position:absolute; bottom:0; left:0; transition:all 0.3s ease-in-out }
.ant-tabs-nav-wrap .ant-tabs-nav-list {gap: 1rem;  }
.ant-tabs .ant-tabs-nav-wrap .ant-tabs-tab span.anticon {opacity: 0.3;}

.contentwrap .ant-space.ant-space-vertical, .contentwrap .ant-space.ant-space-vertical .ant-space, .contentwrap .ant-space.ant-space-vertical .ant-space .ant-space-item { width: 100%;}
.ant-table-content table {border:none !important}
th.ant-table-cell {border-start-start-radius: 0 !important;}
button.ant-btn-primary { background: #FFF; border: 1px solid rgba(21,21,21,0.20);color: #151515;display: flex;align-items: center;font-family: Poppins-Regular, sans-serif; height: 36px;}
button.ant-btn-primary:hover {background-color:#151515 !important; color:#FFF}
.ant-btn.ant-btn-primary.ant-btn-background-ghost { background: #FFF;border: 1px solid rgba(21,21,21,0.16) !important;color: #151515;display: flex;align-items: center;font-family: Poppins-Regular, sans-serif;   height: 36px;}
.ant-btn.ant-btn-primary.ant-btn-background-ghost:hover {color:#FFF}
.ant-table .ant-table-content tr{box-shadow: none;}
.ant-table .ant-table-thead > tr > th {border-bottom:none; background: rgba(21,21,21,0.06) !important;padding:9px 16px}
.ant-table-tbody a { color: #151515;text-decoration: none; font-size:12px;}
.ant-table-wrapper .ant-table-thead > tr > th, .ant-table-wrapper .ant-table-tbody > tr > td {padding: 1rem 16px !important; border-bottom: none !important; border-width: 0;border-color: rgba(21,21,21,0.04);font-family: "Poppins-Regular", sans-serif; font-size:12px;color:#151515; white-space: nowrap; vertical-align: top;}
.ant-table-filter-dropdown hr {margin: 3px 0 8px; border: 1px solid rgba(21,21,21,0.16);}
.ant-table-wrapper .ant-table-column-title {font-size: 10px; font-family: "Poppins-semibold", sans-serif;text-transform: uppercase;letter-spacing: 3px;}
.tablelistwrap .ant-table.ant-table-bordered {box-shadow:0 0 1px rgba(21,21,21,0.26) !important; overflow: hidden;}
.ant-table-wrapper .ant-table-tbody > tr > td.ant-table-cell:nth-child(2) {text-transform: capitalize;}
.ant-layout .ant-layout-content.site-layout {padding: 0 !important; background: #FFF;}
.contentwrap { border-radius: 0.5rem; overflow: hidden;; background: url("../../../public/images/maskbg.png");}
.ant-tabs-tab.ant-tabs-tab-with-remove.ant-tabs-tab-active{position:relative; }
.ant-tabs-tab.ant-tabs-tab-with-remove.ant-tabs-tab-active .ant-tabs-tab-btn::after {display:none}
.ant-table-cell .ant-btn.btn.btn-link {color: #151515;}
.ant-table-wrapper .ant-table-content table { border-right: 1px solid rgba(21,21,21,0.16) !important;}
.ant-table-wrapper .ant-table-container:before {display:none}
.ant-table-cell .ant-btn.btn.btn-link:hover {border-color:rgba(21,21,21,0.16); background:rgba(21,21,21,0.16)}
.ant-pagination-options .ant-select-selector {height: 30px !important; display: flex; align-items: center;}
.ant-pagination-options .ant-select-selector span { font-family: Poppins-Regular, sans-serif; font-size: 14px; font-weight: 300;}
.ant-spin-container .ant-pagination.ant-table-pagination {gap: 0.2rem;}
.ant-spin-container .ant-pagination-item {height:30px !important}
.anticon.anticon-double-right.ant-pagination-item-link-icon { color: #151515 !important;}
.ant-pagination-options .ant-select:hover .ant-select-selector { border-color: rgba(21,21,21,0.16) !important;}
.orgroup hr {border-top: 1px solid #151515;margin: 0;}
.orgroup > .d-flex {align-items: center; gap:1rem;}
.orgroup p{font-weight:700; color:#151515;}
.ant-btn.addfilter {box-shadow:none; border-radius:5px;}
.ant-btn.addfilter span {color:rgba(21,21,21,0.70); font-size:14px;}
.ant-btn.addfilter:hover span {color:#FFF}
.ant-table-row .ant-table-cell:nth-child(3) { text-transform: capitalize;}
.ant-table .ant-table-thead > tr > .ant-table-cell { background: #F6F6F9 ; border: none;  }
.ant-table .ant-table-thead > tr > .ant-table-cell::before {display:none}
.ant-select.ant-select-single.ant-select-show-arrow {width: 100% !important;}
.advmatch .form-controls .ant-select .ant-select-selector {padding: 0;}
.advmatch .form-controls .ant-select .ant-select-selector .ant-select-selection-search {inset-inline-start: 0;width: 100%; height: 32px;}
.btn.btn-link.addbtn { font-size: 14px; padding: 5px 0; position:relative;}
.btn.btn-link.addbtn:hover::after { content:""; width:100%; height:2px; background:#151515;display:block; position:absolute; bottom:0; left:0; transition:all 0.3s ease-in-out}

/* .ant-space .ant-space-item {display: none;} */
.ant-space .ant-space-item:first-child{display:flex;}

/* Modal */
.ant-modal-content .ant-modal-header .ant-modal-title {  font-weight: 400;font-family: "Poppins-Bold", sans-serif;text-transform: uppercase; font-size: 14px;}
.ant-modal-footer {justify-content: end;}
.ant-modal .ant-divider {margin: 0 0 24px;}

/* Add new listing */
.titleadd {font-size:26px; font-family:"Poppins-Bold", sans-serif; padding:0;}
.addwrap label {font-family: "Poppins", sans-serif;font-size:12px;margin-bottom:5px;}
.addwrap {box-shadow: 0 0 3px rgba(21,21,21,0.16); border: 1px solid rgba(21,21,21,0.16); border-radius: 0.3rem;}
.addwrap .form-group .ant-select .ant-select-selection-search-input { height: 46px !important;}
.addwrap .form-group .form-control, .addwrap .form-group .ant-select.ant-select-show-search { height: 46px !important;font-family: Poppins-Regular, sans-serif;}
.addwrap .ant-select-single .ant-select-selector {height: 46px; overflow: hidden;}
.ant-select:hover .ant-select-selector {border-color: #151515 !important;}
.addwrap .form-group .form-control:hover, .addwrap .form-group .form-control:focus { box-shadow: none; border: 1px solid #151515 !important;  }
.addwrap .form-control:focus {box-shadow: none; border: 1px solid #151515 !important;}
.addwrap .form-control { font-family: Poppins-Regular, sans-serif; font-size: 14px; height: 46px; line-height: 2.25;}
.ant-btn-primary.btnsave { background: #151515; color: #FFF; box-shadow: 0 0 1px #151515;}
.ant-drawer-title {font-family: Poppins-Regular, sans-serif;}
.ant-btn-primary.ant-btn-dangerous.ant-btn-background-ghost:not(:disabled):hover {color:#FFF}
.ant-select .ant-select-selection-search input {height: 100% !important; line-height: 100%; font-family: Poppins-Regular, sans-serif;}
.ant-select-selector .ant-select-selection-placeholder { font-family: Poppins-Regular, sans-serif; line-height: 46px !important; display: flex;}
.addwrap .form-select { height: 46px; font-family: Poppins-Regular, sans-serif; font-size: 14px; text-transform: capitalize;}
.addwrap .form-select:hover, .addwrap .form-select:focus {box-shadow: none; border-color: #151515;}
.addwrap h3.title {font-size: 21px; font-family: Poppins-Bold, sans-serif;}
.changemode {display:none}

/* Edit Listing */
.border-top {border-top:5px solid #151515 !important;}
.btn.btn-primary.requestchange {font-size: 10px;height: 26px;}
.cont-wrap {display:flex;  flex-direction:column;margin:1rem auto 2rem;}
.toptitle {display:flex; flex-direction:row;align-items: center; width:100%;justify-content: space-between;}
.column-wrap {flex-wrap: nowrap;width: 100%;display: flex;gap:0.6rem}
.column-wrap .col-md-3 > div, .column-wrap .col-md-6 > div {padding:1.5rem;border: 1px solid rgba(21,21,21,0.16); box-shadow: 0 0 4px rgba(21,21,21,0.06);}
.column-wrap h3 {font-size: 16px;color: #151515;margin:5px 0;font-weight:400; white-space:nowrap;font-family: Poppins-Bold, sans-serif }
.formfieldset {display:flex; flex-direction:column; gap:0.6rem}
.form-group {flex-direction:column; display:flex;}
.form-group label {font-size:0.73rem; display:flex; font-weight:normal; margin-bottom:5px;}
.form-group input, .form-group select {height: 36px; width: 100%;font-size: 12px; border: 1px solid #e3e3e3; background: #fff !important; border-radius:0.3rem}
hr {border-top: 1px dashed #e3e3e3;width: 100%;}
.page-title {width: fit-content;}
.listtable-wrap a {border-bottom:1px solid rgba(21,21,21,0.06); }
.listtable-wrap a:hover, .listtable-wrap a:focus {background-color:rgba(21,21,21,0.05)}
.listtable p {margin-bottom:0;}
.listtable .propfor {font-size: 10px; text-transform: uppercase; letter-spacing: 4px;}
.listtable .refno {font-size:16px; font-weight:bold}
.listtable .propadd, .listtable .community {font-size:12px;}
.val-wrap p{margin-bottom: 0;}
.btn.btn-primary.requestchange {color:#151515;}
.btn.btn-primary.requestchange:hover {border-color: #151515;}
.listtable-wrap { padding: 0;}
.border-wrap {border:1px solid #e3e3e3; border-radius: 0.3rem;}
.listtable {padding: 0;}
.agentprop .label {color: #8D8D8D; font-size: 0.7rem; text-transform: uppercase; letter-spacing: 3px; margin-bottom: 0;}
.listtable-wrap .col-4 {padding:0;}
button.btn.btn-primary { color: #151515; font-size: 14px;}
button.btn.btn-primary:hover {border-color:#151515}
.offers .form-group select {padding: 0 10px;}
.btn.btn-secondary { color: #151515; font-size: 12px; height: 36px;}
.form-group input:focus {border:1px solid #e3e3e3 !important}
.form-group input {padding:0 7px;}
.timeline-wrap .tab-content .tab-pane .input-group.uploadgrp { width: 50%; padding: 0 12px;  }
.requesttable, .requesttable .col-6 {padding:0;}

/* listingstats */
.stat {font-size:0.7rem; gap:0.3rem; align-items:center; margin-left:auto; width:fit-content}
.publishstat i, .publishstat span {color:#25D366}
.agentprop {margin-top:1.35rem; text-align:right}
.agentprop .name {font-size:10px; white-space: nowrap;margin-bottom:0}
.listtable-wrap .stat span {font-size: 10px;}


/* marketrequests */
.marketrequest .btn.btn-secondary {background-color:#F3F3F3; border:1px solid #F3F3F3;text-align: left;padding: 0 0.3rem; gap:0.3rem;display:flex; align-items: center;white-space: nowrap;width:49%; font-size:12px;height:36px;}
.marketrequest .btn.btn-secondary:hover {background-color:#151515;border-color:#151515;}
.marketrequest .btn.btn-secondary:hover i, .marketrequest .btn.btn-secondary:hover span {color:#FFF}
.marketrequest .btn.btn-secondary i {width:10px}
.marketrequest > div {gap:0.3rem}
.marketrequest .requestfor {font-size:0.73rem; text-transform: uppercase;}
.marketrequest p {margin-bottom: 0;}
.marketwrap {border-bottom:1px solid #F3F3F3}
.marketrequest .requesttable {width:100%;}
.marketrequest .requestname {font-size:0.8rem; font-weight: bold;white-space: nowrap;}
.marketrequest .requestdate {margin-bottom:3px;}
.marketrequest .requestdate, .marketrequest .requeststat {color:#8D8D8D; font-size:0.73rem}
.timeline-wrap .tab-pane .btn.btn-primary.requestchange {margin: 0 auto;  margin-top: 0px;display: flex;align-items: center;}

/* tabs */
.nav-tabs .nav-link {border:none; color:#151515; font-size:12px;text-transform: capitalize;padding: 8px;}
.nav-tabs .nav-link.active {border: none;border-bottom: 2px solid #151515;font-weight: bold;}
.tab-content {padding: 1rem 0;}
.tab-content textarea {border: 1px solid #CACACA; border-radius: 0.3rem; width: 100%;  font-size: 12px;padding: 0.5rem;}
.tab-content textarea:focus-visible {border-color:rgba(21,21,21,0.80); outline: 2px solid rgba(21,21,21,0.30);}
.tab-content button.btn.btn-primary.requestchange {margin: 0 auto;margin-top: 0px; font-size: 12px; height: 36px;padding: 0 2rem;}
/* timeline */
.timeline-wrap {margin-bottom:1rem}
.timeline-list {display: flex; flex-direction: column;gap:1rem}
.timeline .nav-tabs { background:none; border: none;border-bottom: 1px solid rgba(21,21,21,0.16);}
.timeline .nav-tabs .nav-link.active {border: none;border-radius: 0;border-bottom: 2px solid #151515;}
.timegroup {display:flex; flex-direction:row; padding:0.rem 1rem; width:100%; }
.agentwrap .namewrap {width:32px; height:32px; border-radius:50%; background:rgba(21,21,21,0.17); text-align:center; align-items:center}
.namewrap span {font-size:10px; font-weight:bold; color:#FFF}
.agentdetails {width:100%;}
.agentdetails p {font-size:12px;margin-bottom:0;}
.agentdetails .awriter {font-size:12px;font-family: "Poppins-Regular", sans-serif;color: #151515;}
.atime {color:rgba(21,21,21,0.50)}
.agentdetails .aclass {font-weight:700; margin-bottom:5px}
.adesc {font-size: 12px;border: 1px solid #D4D4D4;width: 100%; padding: 0.55rem 0.5rem; border-radius: 0.3rem;margin-top:5px;}
.adesc p {margin-bottom:0; color:#151515;}
.adesc p.price {color: rgba(21,21,21,0.4); font-size: 12px;}
.agentdetails .whocall {font-size:16px;}
.agentdetails .calldetails {display: flex; flex-direction:row; align-items:center; gap:0.6rem; justify-content: space-between;}
.timegroup .col-1 {position:relative}
.timegroup .col-1::before {display:flex;content:""; border-left:1px solid #cacaca; height:120%; width:1px; position:absolute; z-index:2; top:1.5rem; left:50%;}
.timeline-list .timegroup:last-child .col-1::before {display:none}
.timeline-wrap .nav.nav-tabs button.nav-link:first-child, .nav.nav-tabs button.nav-link:last-child {padding: 8px 0;}

.timegroup.offer {background: none;}
.timegroup.offer .adesc { background: rgba(53,184,224,0.03); border: 1px solid rgba(53,184,224,0.15);color: #35B8E0;}
.timegroup.viewing .adesc { background: rgba(255,159,10,0.03); border: 1px solid rgba(255,159,10,0.15);color: #FF9F0A;}

/* timeline icons */
.iconwrap {border-radius: 50%;height:25px; width:25px; display:flex; align-items:center; justify-content: center; position:relative; z-index:3; left: 50%;transform: translateX(-50%);}
.iconwrap i {color:#FFF; margin-right:0;font-size:11px;}
.timegroup.note .iconwrap {background: #151515;}
.timegroup.req_decline .iconwrap {background: #151515;}
.timegroup.req_expire .iconwrap {background: #151515;}
.timegroup.req_accept .iconwrap {background: #151515;}
.timegroup.viewing .iconwrap {background-color: #F9C851;}
.timegroup.phone .iconwrap {background-color:#7734EA}
.timegroup.whatsapp .iconwrap {background-color:#25D366}
.timegroup.req_t_up .iconwrap {background-color:#25D366}
.timegroup.req_w .iconwrap {background-color:#25D366}
.timegroup.system .iconwrap {background-color:#151515}
.timegroup.email .iconwrap {background-color:#255FD3}
.timegroup.offer .iconwrap {background-color: #35B8E0;}
.timegroup.viewing .iconwrap {background-color:#FF9F0A}
.timegroup.followup .iconwrap {background-color:#C51162}
.tab-pane input {font-size:12px;}

/* Details */
.form-group {margin-bottom:1rem}
.form-group.price .btn.btn-primary.requestchange { font-size: 12px;margin: 0 0 0 auto;    margin-top: 0px;height: 26px; padding: 0 0.8rem;}

.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {background: none; color: #151515;font-weight: 700;box-shadow:none}
.accordion-flush .accordion-item .accordion-button:hover, .accordion-flush .accordion-item .accordion-button.collapsed:focus-visible, .accordion-flush .accordion-item .accordion-button.collapsed:focus, .accordion-flush .accordion-item .accordion-button.collapsed:active {background-color: rgba(21,21,21,0.16);outline:none; box-shadow:none}
.accordion-button:focus {box-shadow:none}
.input-group {width:50%}
.input-group label {font-size:14px;margin-left:1rem; cursor:pointer}
.input-group-text {width:100%;padding:0; background:none; border:none}
.input-group .form-check-input:checked {background-color: #0d6efd; border-color: #0d6efd;}
.column-wrap .col-md-6 > div.accordion.accordion-flush {padding: 0;}
.column-wrap .col-md-6 > div.accordion.accordion-flush .accordion-collapse {padding:.5rem 1.5rem}
.tab-pane .row {padding: 0 1rem}
.form-group select {font-size:12px;padding:0 7px}
.savegroup .col-12 {align-items:end;}
.accordion .nav-tabs.nav-fill {flex-wrap: nowrap;}
.accordion .nav-tabs .nav-link {padding:5px;}
.btnnotes {height:100%; margin-left:auto;display:flex; align-items: center;}
table thead th {font-size:13px;font-weight:400 !important;font-family: Poppins-semibold, sans-serif;}
.ant-table .ant-table-thead > tr > th.ant-table-cell {border-bottom: none;background:#F6F6F9 !important;padding: 9px 16px !important;white-space:nowrap; color:#151515; border-top: 0 solid #151515 !important; font-family:"Poppins-Regular", sans-serif}

/* portal */
div.input-group {width:33.33%;margin-bottom:1rem}
.tab-pane.portal .row {padding:0}
.portal label {font-size:14px; margin-left:5px; }
.portal .nav-tabs .nav-link.active {border: 1px solid rgba(141,141,141,0.25);  }
.portal .nav-tabs .nav-link {padding: 10px 1.5rem; }
.portal .border-wrap {border-radius:0}

/* images
.dropzone {width:100%;height: 300px;border:1px dashed #E6E6E6; justify-content:center;display: flex;flex-direction: column; align-items: center;margin-top:1rem }
.dropzone i {font-size: 4rem;  opacity: 0.15; margin: 0; line-height: 1;}
.dropzone span {margin-bottom:6px; font-size:12px;opacity:0.3}
.images .btngrp {padding:0;gap:0.6rem }
.images .btngrp > div { gap:0.6rem}

/* Uploaded image group */
/* .imageuploadgrp {margin-top:1rem}
.imageuploadgrp label{font-size:12px;margin-left:3px}
.tab-pane .row.imgupgrp {padding:0 5px;}
.imgupgrp div.input-group {width: 50%;margin-top:5px;}
.imageuploadgrp .btn.btn-secondary {height: 26px;} */


/* MATCH */
.showMatchClass span {font-size: 12px; margin-top: -31px; position: absolute;   background: #FFF;padding: 4px 5px 4px 2px;margin-left:-2px}
.advmatch {border:1px solid #e3e3e3; padding:1rem 0.5rem 0; border-radius: 0.3rem;}
.advmatch .form-controls {display: flex;flex-direction: row;gap: 0.7rem;}
.advmatch .col-6.form-group {width: 47.5%;}
.advmatch .form-group label {font-size:10px;}
.advmatch .form-group select {padding:5px;}
.advmatch .form-group input:focus-visible {border-color:rgba(21,21,21,0.16);outline:  2px solid rgba(21,21,21,0.06);}
.advmatch .d-flex input {font-size:12px;height:36px}
.dash {line-height:26px; padding:0 3px;}
.advmatch .form-group select { padding: 2px 5px;height: 31px;line-height: 1;}
.advmatch .form-group {margin-bottom: 9px;}
.advmatch .btn.btn-dark {font-size: 14px;}

/* matchresults */
.matchresults {display:flex;margin-top:1rem; flex-direction:column; padding:0.3rem; border-radius: 0.3rem;}
.matchresults .control-labels {font-weight:400; font-size:12px; margin-bottom:3px;text-transform: capitalize; font-family: "Poppins-Regular", sans-serif}
.matchresults .btn-link {color:#151515; font-size:11px;}
.matchresult {border:1px solid #E6E6E6;border-radius: 0.3rem;}
.matchitem {display:flex; flex-direction:column; padding:7px 5px; border-bottom:1px solid rgba(21,21,21,0.06); width:100% !important;}
.matchinfo {display:flex; flex-direction: row;width:100%;}
.matchitem .name {font-size: 14px;font-weight: 400; font-family:Poppins-Bold; color:#151515}
.matchitem p, .matchitem span {font-size:12px; margin:0; color:#151515}
.matchitem .addeddate, .matchitem .time {font-size:12px;color:#8D8D8D;text-align:unset }
.matchhistory { display: flex; flex-direction: row; justify-content: space-between; margin-top:5px }
.matchhistory .time {color:#8D8D8D}
.matchresult .matchitem:last-child{font-size: 12px; color:#151515; text-align:right; width:100% !important}
.matchresult .address {white-space: nowrap;}
.matchitem:hover {background-color: rgba(21,21,21,0.05);}

.btn.btn-link.addfeedback {color:#FF0000; text-decoration: none; font-size:10px; height:100%; white-space: normal;width: 100%;}
.btn.btn-link.viewfeedback {color:#25D366; text-decoration: none; font-size:10px; height:100%; white-space: normal; width:100%;}
.timeline .tab-content {min-height: 33rem;}
.matchitem.viewall {font-size:12px; text-align:right;color:#151515 !important}
.timeline .tab-content {max-height: 33rem;overflow-y: scroll;min-height: 33rem;}
.uploadformgroup {margin-bottom: 0;}
.ant-space { margin-left: auto;}
.ant-pagination .ant-pagination-item-active { background: #151515 !important; border-color: #151515 !important; line-height: 26px !important; height: 30px !important;}
.ant-pagination-prev, .ant-pagination-next {height: 30px !important; line-height: 1.2 !important;}
.ant-pagination .ant-pagination-total-text {font-family: Poppins-Regular, sans-serif !important; font-size: 12px !important; opacity: 0.6;  }
.btnlist {padding:0; margin:0 -0.8rem}
.timeline-wrap .tab-content .tab-pane .input-group { display: flex; padding: 0 2rem;}
.matchitem p.price {color: #8D8D8D;}
.ant-select.ant-select-show-search .ant-select-selector {height: 36px; border-radius: 5px; margin-top: 0.5rem; line-height: 36px;overflow:hidden}
.ant-select-selector .ant-select-selection-placeholder { line-height: 36px !important;}
.ant-select .ant-select-arrow {margin: 0;  line-height: 7px; display: flex; position: absolute; top: 45%;  transform: translateY(-50%);}
.ant-modal-footer {display: flex; margin-left: auto;}
.ant-modal-footer .ant-btn.ant-btn-default { height: 36px !important; box-shadow: none;}
.ant-modal-footer .ant-btn.ant-btn-default:hover {color:#151515; border-color:#151515}


/* Responsive */
@media (min-width: 1800px) {
.container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl { max-width: 1750px;}
}
@media (max-width: 1280px) and (min-width:768px) {
    .timeline div.tab-content {max-height: 28.5rem; min-height: 28.5rem; }
}

/* Add new filter */
.ant-btn span {font-size: 14px;}
.ant-modal .ant-modal-body {display: flex; flex-direction: column; align-items: center;}
.ant-modal.followupmodal .ant-modal-body { align-items: start;}
.ant-modal.followupmodal .ant-modal-body .ant-picker {width: 100%; height: 36px; margin-bottom: 1rem;}

/* Leads */
.ant-space.ant-space-vertical, .ant-space.ant-space-vertical .ant-space, .ant-space.ant-space-vertical .ant-space-item {width: 100%;}
.leadtable .ant-table-wrapper .ant-table-tbody > tr:first-child > td {padding: 0 16px !important;}
.leadtable .ant-table-tbody a { font-size: 12px;  }
.ant-table .ant-table-thead > tr > th.ant-table-cell {border-left: 0 solid rgba(21,21,21,0.05);}
.ant-table-tbody .ant-table-row .ant-table-cell:hover { border-start-start-radius: 0 !important; border-end-start-radius: 0 !important;}
.leadtable .ant-table-tbody .ant-table-cell:first-child a {font-family: Poppins-Bold, sans-serif;}

.ant-table-wrapper .ant-table-cell { white-space: nowrap;}

/* Listing Status */
.published {color:#25D366}
.comingtomarket {color:#FF9F0A}
.offmarket {color:#4A148C}
.feeds {color:#004D40}
.unpublished {color:#8A8A8A}
.draft {color:#607D8B}
.confirmedowner {color:#795548}
.action {color:#FF453A}
.seo {color:#BF5AF2}
.unlisted {color:#151515}
.sold {color:#5400FF}
.let {color:#5400FF}
.review {color:#AC8E68}
.movetoarchive {color:#8D8D8D}

/* Viewing Status */
.offer, .viewings {color:#25D366; background-color:rgba(37,211,102,1)}
.secondviewing, .unsuccessful {color:#255FD3; background-color:rgba(37,95,211,1)}
.considering, .successful {color:#FF9F0A; background-color:rgba(255,159,10,1)}
.scheduled {color:#FF0000; background-color:rgba(255,0,0,1)}
.notinterested, .cancelled {color:#8A8A8A; background-color:rgba(138,138,138,1)}
.viewstat .ant-table-column-sorter{padding-left:0}
.propref {font-size:13px;}
.propinfo { width: 12rem; white-space: normal;}
.ant-table-wrapper .ant-table-tbody > tr > td.ant-table-cell.noteswrap { width: 25rem;  white-space: normal !important;}

/* Offer Status */
.viewings {color:#25D366}
.scheduled {color:#FF0000; background-color:rgba(255,0,0,1)}
.unsuccessful {color:#151515}
.cancelled {color:#8A8A8A}


/* Lead status */
.pstat {display:block; font-size:12px;color:#FFF}
.pstat.uncontacted {background-color:#580AFF}
.pstat.working {background-color:rgba(37,211,102,1)}
.pstat.hot { background-color:#8d0801}
.pstat.expiry { background-color:#ff002b}
.pstat.followup {background-color:#2153DD}
.pstat.dead {background-color:#151515}
.pstat.offer {background-color:#FFBA08}
.pstat.dealsigned {background-color:#468A3F !important}
.pstat.viewings {background-color:#19C0CC}
.pstat.recruitment {background-color:#BE0AFF}
.pstat.noanswer {background-color:#5D2E8C}


.allviewings .ant-table-wrapper .ant-table-thead > tr > th, .allviewings .ant-table-wrapper .ant-table-tbody > tr > td, .alloffers .ant-table-wrapper .ant-table-thead > tr > th, .alloffers .ant-table-wrapper .ant-table-tbody > tr > td {padding: 0.6rem 16px !important;}
.ant-table-wrapper .ant-table-tbody > tr > td.ant-table-cell.pdets { max-width: 15rem; white-space: normal !important;  }


.ant-modal-body .ant-select.ant-select-show-search .ant-select-selector {height: 46px;  line-height: 46px; margin: 0; font-size: 14px;; }
.ant-modal-body .ant-select.ant-select-show-search .ant-select-selector .ant-select-selection-placeholder {line-height: 46px !important; font-size: 14px;}
.pricedrop .ant-select-selector {height: 46px !important; font-size: 14px;}
.pricedrop .ant-select-selector .ant-select-selection-placeholder, .pricedrop .ant-select-selector .ant-select-selection-item {line-height: 46px !important; font-size: 14px;}

.addfilterwrap .btn.btn-outline-danger {border:none; height:46px;}
.addfilterwrap .ant-select.ant-select-show-search .ant-select-selector .ant-select-selection-item {   height: fit-content !important;line-height: 32px;}
.ant-modal-body .ant-select.ant-select-show-search .ant-select-selector {line-height: 46px; font-size: 14px;}

/* Followups */
.followups .tablelistwrap .ant-table-wrapper .ant-table-thead > tr > th, .followups .tablelistwrap .ant-table-wrapper .ant-table-tbody > tr > td {padding: 1rem 7px !important; white-space: unset !important; vertical-align:top }
.followups .tablelistwrap .ant-table-wrapper .ant-table-tbody > tr > td .btn.btn-link, .followups .tablelistwrap .ant-table-wrapper .ant-table-tbody > tr > td .btn.btn-link span {border:none;padding:0; font-size:12px;color:#FF0000}
.followups .tablelistwrap .ant-table-wrapper .ant-table-tbody > tr > td .btn.btn-link:hover {background: none !important; text-decoration: underline !important;}
.followups .ant-select.ant-select-show-search .ant-select-selector .ant-select-selection-item {height:36px; line-height: 36px;}
.followups .ant-table-wrapper .ant-table-tbody > tr > td.ant-table-cell.propdetails { max-width: 10rem; white-space: normal !important; }
.followups .ant-table-wrapper .ant-table-tbody > tr > td {vertical-align: baseline;}
.followups .ant-table-wrapper .ant-table-tbody > tr > td span {font-family: 'Poppins', sans-serif;color: #151515; text-decoration: none; font-size: 12px;font-weight:400}

/* Viewings */
.react-tabs .react-tabs__tab-list { border: none; margin-bottom: 1rem; background: rgba(21,21,21,0.02); padding: 0 1rem; border-radius: 5px;height: 60px; display: flex;white-space: nowrap; width: 100%; overflow-x: scroll;}
.react-tabs .react-tabs__tab--selected { border: none; background: none;line-height: 50px; border-radius: 0; font-weight: 700;}
.react-tabs .react-tabs__tab--selected:after {content: "";    width: 100%;height: 2px;  background: #151515; display: block;position: absolute; bottom: 0;left: 0;}
.react-tabs .react-tabs__tab:hover:after {content: "";    width: 100%;height: 2px; background: #151515; display: block;position: absolute; bottom: 0;left: 0;}
.react-tabs .react-tabs__tab-list .react-tabs__tab{padding: 0 1.8rem; height: 85%; line-height: 50px; border-radius: 0; display: flex; align-items: center;align-self: center; font-size: 12px; border-right: 1px solid rgba(21,21,21,0.03);top:0}
.react-tabs .react-tabs__tab-list .react-tabs__tab p {   display: flex; flex-direction: row; gap: 1rem; align-items: center;margin:0; line-height: 50px;}
.react-tabs .react-tabs__tab-list .react-tabs__tab p .anticon {font-size:11px; opacity:0.3}
.react-tabs .react-tabs__tab-list .react-tabs__tab p .anticon:hover{ opacity:0.9}

.form-group .ant-select .ant-select-selector {height: 36px; line-height: 36px;}
.ant-select .ant-select-selector .ant-select-selection-item {line-height: 36px;}

.ant-table .ant-table-content tr.ant-table-measure-row { display: none;  }
.tablelistwrap div.ant-table-wrapper .ant-table-thead > tr > th:nth-child(2) {z-index: 99;}
.tablelistwrap div.ant-table-wrapper .ant-table-tbody > tr > td:first-child, .tablelistwrap div.ant-table-wrapper .ant-table-thead > tr > th:first-child  { padding: 1rem 8px !important;  }


/* Popup */
.form-text-wrapper{border:1px solid rgba(21,21,21,0.16); height:36px; padding:0 10px; display:flex; align-items:center; border-radius: 0.3rem; font-size:12px;}
.leadetailsowner .styles-module_Editext__view_container__3oSYx {height: 36px;padding: 0 0 0 10px; background-color: #FFF; border-radius: 0.3rem;}
.leadetailsowner .styles-module_Editext__buttons_container__2za5Q { height: 36px;margin-left: auto;}
.leadetailsowner div[editext="view"] {font-size: 12px;}
.form-text-wrapper .easy-edit-wrapper { height: 100%;line-height: 100%; display: flex; align-items: center;width: 100%;background:#FFF}
.form-text-wrapper .easy-edit-wrapper:after{content:"\F4C9"; font-family:"bootstrap-icons"; transform:rotate(-90deg) ; position:absolute; right:1rem; font-size:10px}
.form-text-wrapper .easy-edit-inline-wrapper {width:100%;justify-content: space-between; display: flex;}
.form-text-wrapper .easy-edit-component-wrapper  {width: 85%; height: 100%; display: flex; margin: 0; border-radius:0; border-right:1px solid rgba(21,21,21,0.16)}
.form-text-wrapper .easy-edit-component-wrapper select, .form-text-wrapper .easy-edit-component-wrapper input{border:none !important; background:none !important; border-radius:0;padding-left:0}
.form-text-wrapper .easy-edit-button-wrapper .easy-edit-button {   height: 100%;line-height: 1;}


/* Property details new tab */
.tabtimeline.tabpropdet .react-tabs__tab-panel--selected {padding:2rem}
.tabtimeline.tabpropdet .react-tabs .react-tabs__tab-list .react-tabs__tab{padding:0 1rem}

/* SweetAlert Popup */
h2.swal2-title {font-size: 16px;color: #151515; font-weight: normal; font-family: "Poppins-Bold", sans-serif; margin: 2rem auto 0; text-align: left; padding: 0; line-height: 1.4;width: 90%;}
.swal2-container .swal2-input {font-size: 14px;margin: 0.5rem auto 0;width: 90%;}
.swal2-container .swal2-actions { margin-top: 0.3rem; width: 100%;}
.swal2-container .swal2-actions button {width: 43%; font-size: 13px;}
.swal2-container .swal2-actions button.swal2-confirm { background-color: #151515; border: 1px solid #151515}
.swal2-container .swal2-actions button.swal2-cancel {background: #FFF; color: #151515; border: 1px solid rgba(21,21,21,0.16);}
.swal2-container .swal2-html-container {font-size: 12px;  text-align: left; margin: 0 auto; padding: 0; width: 90%;}
.confirmedstat {text-align: center;font-family: "Poppins-Bold",sans-serif; font-size: 16px;}

/* Match Listings */
.matchlistings .tabtimeline .react-tabs .react-tabs__tab-list .react-tabs__tab {padding:0 5px}
.matchlistings .matchlistcontentwrap a {border-bottom:1px solid rgba(21,21,21,0.16)}
.matchlistcontentwrap .listtable i.bi.bi-circle-fill {font-size: 8px;}
.matchlistcontentwrap .ant-collapse > .ant-collapse-item > .ant-collapse-header {padding: 5px;}
.matchlistcontentwrap .ant-collapse-expand-icon { position: absolute;right: -7px;top: 50%; transform: translateY(-50%);opacity: 0.3;}
.matchlistcontentwrap .ant-collapse {border-radius: 0; background: #FFF; border: none}
.matchlistcontentwrap .ant-collapse > .ant-collapse-item {   border-radius: 0;border: 1px solid #e3e3e3; margin-bottom: 0.5rem;}
.matchlistcontentwrap .ant-collapse .propadd {white-space: nowrap; text-overflow: ellipsis;}
.matchlistcontentwrap .publishstat .bi {font-size: 8px;}
.matchlistcontentwrap .rowmatchwrap {border:1px solid rgba(21,21,21,0.16); border-radius: 0.3rem; display:flex; flex-direction: row;padding:5px 3px; margin-bottom: 0.4rem;}
.matchlistcontentwrap .imagematchwrap {width:80px; height:80px; display:flex; overflow: hidden;}
.matchlistcontentwrap .imagematchwrap img{position:relative; left:50%; transform: translateX(-50%);}
.matchlistcontentwrap .propfor {letter-spacing: 2px; text-transform: uppercase;}
.matchlistcontentwrap .agentprop {max-width:100px; overflow: hidden; text-overflow: ellipsis;white-space: nowrap; margin-top: 0;}
.matchlistcontentwrap .top-info {position:relative; top:0; margin-bottom:5px; display: flex; flex-direction: row; justify-content: space-between;}
.matchlistcontentwrap .bottominfo {position:relative; bottom:0; margin-top:5px; display: flex; flex-direction: row; justify-content: space-between;}
.matchlistcontentwrap .ant-col {position:relative}
.rowmatchwrap .ant-row {justify-content: space-between;width: 100%;}
.matchlistings .matchlistcontentwrap a.rowmatchviewall {display: flex;   margin-left: auto !important; border-bottom: 0; float: right; margin-top:0.3rem; color:#151515; text-decoration: none;}
.matchlistings .tabtimeline .react-tabs__tab-panel--selected {margin-bottom: 0.5rem;}

@media screen and (max-width:1280px) {
    .matchlistcontentwrap .rowmatchwrap .ant-col {overflow: hidden;}
    .matchlistcontentwrap .rowmatchwrap .ant-col .imagematchwrap {max-height:130px; height:100% !important;}
}


.leaddetails .timeline .tab-content {max-height: 116.8vh;}

/* ShowProperty */
.showpropertycontent {border-bottom:1px solid rgba(21,21,21,0.16); padding:0.55rem 0.5rem; border-radius: 0;}
.showpropertycontent a {color:#151515}
.showprop-actions {display:flex; flex-direction:row; gap:0.5rem;padding:0.6rem 0 }
.showprop-actions .ant-btn {height:36px; }
.showprop-actions .ant-btn span{font-size:12px;}
.showprop-timer {border-right:1px solid rgba(21,21,21,0.16); display:flex; justify-content:center; align-items:center; gap:0.5rem; padding:0.55rem 1rem 0.5rem 0.5rem }
.showpropbtm {display:flex; flex-direction:row; justify-content:space-between; width:100%;}
.ant-btn span {align-self:center}
.atime{margin-bottom:0}
.atime time {font-size: 10px;text-align: right; justify-content: end; display: flex; white-space: nowrap;}
.childcontent .awriter {margin-bottom:0;width: 85%;}
.childcontent .awriter .stat { margin: 0;padding: 3px 0.3rem;  border-radius: 5px;}
.childcontent .adesc p {line-height: 1.4;}
.childcontent .adesc .mentioned {color:#3B5CFF}
.childcontent:last-child {border-bottom: 0;}
.childcontent {  padding: 0.55rem 0.5rem; background: none; border: 1px solid rgba(21,21,21,0.16) !important; border-radius: 0.3rem;}
.timegroup.showproperty .iconwrap { background: #A7D82E;}
.req_expire .adesc {padding:0}
/* Share Property Page*/
.shareproperty {padding-right: 1rem;padding-left: 1rem; margin-top:2rem }
.sharepropwrap .ant-table-wrapper .ant-table-content table {border-collapse: separate; border: 0 !important;border-spacing: 0 1.2rem;}
.sharepropwrap .ant-table-wrapper .ant-table-content table tr {border-bottom:1px solid rgba(21,21,21,0.06) !important; vertical-align:top; background:#FFF}
.sharepropwrap .ant-table-tbody > tr > td {border-bottom:1px solid rgba(21,21,21,0.06) !important}
.sharepropwrap .ant-table-wrapper .ant-table-tbody > tr:first-child td{border-top:1px solid rgba(21,21,21,0.16) !important}
.sharepropwrap .ant-table-wrapper .ant-table-tbody > tr > td:first-child { border-left: 1px solid rgba(21,21,21,0.16) !important;}
.sharepropwrap .ant-table-wrapper .ant-table-tbody > tr > td:last-child { border-right: 1px solid rgba(21,21,21,0.16) !important;}

.listinginfowrap .d-flex.align-items-center {gap:0.6rem}
.sharepropwrap .ant-statistic-content-value {font-size: 14px;  font-family: "Poppins-Bold", sans-serif;}
.sharepropwrap .ant-statistic-content-prefix .bi.bi-hourglass-split{vertical-align:baseline}
.filterwrap .form-group {margin-bottom:0}
.filterwrap .form-group .ant-select .ant-select-selector {margin-top: 0;}
.filterwrap .form-group .ant-select .ant-select-selector span.ant-select-selection-item { font-size: 12px;height: 36px;line-height: 36px !important;}

.filterwrap {background: rgba(21,21,21,0.01);}
.datesfilter-wrap .form-group .titledate {height: 100%;line-height: 1.8;}
.datesfilter-wrap .form-check.mb-0.d-flex.flex-row {border: none;}
.addeddate-wrap .ant-select-borderless .ant-select-selector {border-left: 1px solid rgba(21,21,21,0.16) !important; border-radius: 0 !important;}

.childrenwrap .childcontent p, .childrenwrap .childcontent{font-size:12px}

/* Leads filter by status */
.formcheckboxgroup {border-bottom: 1px solid hsla(0,0%,8%,.06);margin-bottom: 0;width: 100%;}
.formcheckboxgroup .ant-checkbox-group {gap: 0.6rem;    flex-wrap: wrap;row-gap: 0; align-items:center}
.filterswrap.formcheckboxgroup .ant-checkbox-wrapper{position:relative;padding-left: 0.5rem;align-items: center;}
.filterswrap.formcheckboxgroup .ant-checkbox-wrapper span {font-size: .73rem; font-weight: 400;padding: .3rem;position:relative; transition:all 0.3s ease-in-out; white-space:nowrap }
.filterswrap.formcheckboxgroup .ant-checkbox-wrapper .ant-checkbox {display: none;}
.filterswrap.formcheckboxgroup .ant-checkbox-wrapper::before { background: #151515; border-radius: 50%;content: ""; display: flex;height: 9px;left: 0;position: absolute;top: 50%;-webkit-transform: translateY(-50%);transform: translateY(-50%);width: 9px;}
.filterswrap.formcheckboxgroup .ant-checkbox-wrapper.ant-checkbox-wrapper-checked span::after {content: "\F623"; font-family: bootstrap-icons;margin-left: 2px; position: absolute;right: 0;}
.filterswrap.formcheckboxgroup .ant-checkbox-wrapper.ant-checkbox-wrapper-checked span{padding-right: 1.2rem;}
.filterswrap.formcheckboxgroup .ant-checkbox-wrapper.ant-checkbox-wrapper-checked::after {background: #151515;bottom: 0; content: "";display: flex;height: 2px; left: 0; position: absolute;width: 100%;}

.filterswrap.formcheckboxgroup .ant-checkbox-wrapper:first-child::before {background: #580AFF;}
.filterswrap.formcheckboxgroup .ant-checkbox-wrapper:first-child span {color: #580AFF}
.filterswrap.formcheckboxgroup .ant-checkbox-wrapper:nth-child(2)::before {background: #25D366;}
.filterswrap.formcheckboxgroup .ant-checkbox-wrapper:nth-child(2) span {color: #25D366}
.filterswrap.formcheckboxgroup .ant-checkbox-wrapper:nth-child(3)::before {background: #19C0CC;}
.filterswrap.formcheckboxgroup .ant-checkbox-wrapper:nth-child(3) span {color: #19C0CC}
.filterswrap.formcheckboxgroup .ant-checkbox-wrapper:nth-child(4)::before {background: #FFBA08;}
.filterswrap.formcheckboxgroup .ant-checkbox-wrapper:nth-child(4) span {color: #FFBA08}
.filterswrap.formcheckboxgroup .ant-checkbox-wrapper:nth-child(5)::before {background: #5D2E8C;}
.filterswrap.formcheckboxgroup .ant-checkbox-wrapper:nth-child(5) span {color: #5D2E8C}
.filterswrap.formcheckboxgroup .ant-checkbox-wrapper:nth-child(6)::before {background: #F74972;}
.filterswrap.formcheckboxgroup .ant-checkbox-wrapper:nth-child(6) span {color: #F74972}
.filterswrap.formcheckboxgroup .ant-checkbox-wrapper:nth-child(7)::before {background: #FF002B;}
.filterswrap.formcheckboxgroup .ant-checkbox-wrapper:nth-child(7) span {color: #FF002B}
.filterswrap.formcheckboxgroup .ant-checkbox-wrapper:nth-child(8)::before {background: #1DA3A4;}
.filterswrap.formcheckboxgroup .ant-checkbox-wrapper:nth-child(8) span {color: #1DA3A4}
.filterswrap.formcheckboxgroup .ant-checkbox-wrapper:nth-child(9)::before {background: #004D40;}
.filterswrap.formcheckboxgroup .ant-checkbox-wrapper:nth-child(9) span {color: #004D40}

.filterswrap.teamfilter .ant-btn span {font-size:0.73rem}
.filterswrap.teamfilter .ant-col {position:relative}
.filterswrap.teamfilter .ant-btn:hover {background: transparent;}
.filterswrap.teamfilter .react-tabs__tab-list {height: 30px;background: none;margin: 0; border-radius: 0;gap: 2rem;overflow: unset;}
.filterswrap.teamfilter .react-tabs { margin-bottom: -3px;}
.filterswrap.teamfilter .react-tabs .react-tabs__tab-list .react-tabs__tab { border-right: 0; padding: 0;}
.filterswrap.teamfilter .react-tabs .react-tabs__tab-list .react-tabs__tab span{font-size:0.73rem}

/* Match Listing Drawer */
.matchlistingleads.ant-table-wrapper .ant-table-content table {border-collapse:separate; border-spacing:0 1.2rem; border-width:0 !important}
.matchlistingleads .ant-table .ant-table-content tr td {border-top: 1px solid rgba(21,21,21,0.16) !important;border-bottom: 1px solid rgba(21,21,21,0.16) !important;}
.matchlistingleads .ant-table .ant-table-content tr td:last-child{border-right: 1px solid rgba(21,21,21,0.16) !important}
.matchlistingleads .ant-table .ant-table-content tr td:first-child{border-left: 1px solid rgba(21,21,21,0.16) !important}
.matchlistingleads-wrap .react-tabs .react-tabs__tab-list { height: 30px;padding: 0;margin: 0;   background: transparent; border-bottom:1px solid rgba(21,21,21,0.16); border-radius:0 !important}
.matchlistingleads-wrap .react-tabs .react-tabs__tab-list .react-tabs__tab {border: none !important; margin-bottom: -5px;}


/* NEw Timeline Design */
.timelinelist .react-tabs .react-tabs__tab-list { height: 25px; background: transparent;border-bottom: 1px solid rgba(21,21,21,0.16); border-radius: 0;padding: 0; justify-content: space-between; width: 100%; margin-bottom:1rem}
.timelinelist .react-tabs .react-tabs__tab-list .react-tabs__tab {border-right: 0;padding: 0 3px;  height: 100%;}
.timelinelist .ant-avatar.ant-avatar-circle.ant-avatar-icon { background: transparent;margin: 0.25rem 0 0.25rem 5px!important;}
/* .timelinelist .ant-timeline {padding-left: 0.3rem;} */

.timelinelist .ant-timeline-item-head.ant-timeline-item-head-custom { align-items: center; background: #fff; border-radius: 50%; display: flex;height: 25px; justify-content: center; width: 25px;}
.timelinelist .timewhatsapp .ant-timeline-item-head.ant-timeline-item-head-custom {background: #18af9d;}

/* Color Timeline Status */
.timelinelist .timeviewing .ant-timeline-item-head.ant-timeline-item-head-custom { background: #19c0cc;}
.timelinelist .timenoanswer .ant-timeline-item-head.ant-timeline-item-head-custom { background: #5D2E8C;}
.timelinelist .timereq_expire .ant-timeline-item-head.ant-timeline-item-head-custom { background: #34B7F1;}
.timelinelist .timerequested_viewing .ant-timeline-item-head.ant-timeline-item-head-custom { background: #34B7F1;}
.timelinelist .timefollowup .ant-timeline-item-head.ant-timeline-item-head-custom { background: #c51162;}
.timelinelist .timecomment .ant-timeline-item-head.ant-timeline-item-head-custom {background: #151515;}
.timelinelist .timewhatsapp .ant-timeline-item-head.ant-timeline-item-head-custom {background: #25D366;}
.timelinelist .timephone .ant-timeline-item-head.ant-timeline-item-head-custom {background: #580AFF;}

.color-text-black {color:#151515}

/* Pinned Lead */
.ant-table .ant-table-content tr .ant-table-cell.namecolwrap.ant-table-cell-fix-left.ant-table-cell-fix-left-last {background: #FFF;overflow: hidden;}
.namecolwrap .bi.bi-pin-angle-fill {color: #f00; position: absolute; left: 0;}

.adesc .ant-tag {font-family: "Poppins-Regular", sans-serif; font-size:11px}
.adesc .ant-tag.updated {background-color: rgba(37,211,102,.16); border-color: #25d366; color: #25d366;}


.timelinelist .ant-timeline {display:flex; flex-direction:column; width:100%; position:relative}
.ant-timeline .wa_parent:last-child .ant-timeline-item-tail, .ant-timeline .wa_child:nth-last-child(2) .ant-timeline-item-tail {height: 0;}
.ant-timeline .wa_parent .ant-timeline-item-tail {height: 100%;}
.timelinelist .ant-timeline.timewhatsapp.wa_child {padding-left: 2rem;}
.ant-timeline.timewhatsapp.wa_parent.order-3 {order: 3 !important; }
.ant-timeline.timewhatsapp.wa_child.order-2 {order: 4 !important;}
.ant-timeline.timewhatsapp.wa_parent.order-1 {order: 1 !important; }
.ant-timeline.timewhatsapp.wa_child.order-0 {order: 2 !important;}
.ant-timeline .adesc b {font-size: 12px !important; font-weight: normal;font-family: "Poppins-Bold", sans-serif; text-transform: uppercase; padding-bottom: 3px;}
.ant-timeline-item.mt-2.timereq_expire.ant-timeline-item-last {padding-bottom: 0;}

.ant-timeline .adesc {gap:1rem;}
.ant-timeline .adesc .adesc-sched > div {padding-right:1rem; border-right:1px solid rgba(21,21,21,0.06); margin-right:1rem; align-items:center}

.ant-timeline .adesc .lp_details {display:flex; flex-direction:row; gap:0.6rem; margin-top:0.6rem}
.ant-timeline .lp_details .anticon.slick-prev {inset-inline-start: 0;font-size: 12px; color: #FFF;z-index: 9;}
.ant-timeline .lp_details .anticon.slick-next{inset-inline-end: 0;font-size: 12px; color: #FFF;z-index: 9;}
.ant-timeline .adesctarea {border-radius:0; font-size:12px; font-family:'Poppins-Regular', sans-serif; border:1px solid rgba(21,21,21,0.06); background: rgba(21,21,21,0.02);}
.ant-timeline .tagged {display:flex; flex-direction:row; gap:0.6rem; justify-content:space-between}
.ant-timeline .read{white-space:nowrap; }
.ant-timeline .read i, .ant-timeline .read span{color:#25D366}
.ant-timeline .taggedreply {margin-top:0.5rem}
.ant-timeline .taggedreply > .d-flex {display:flex; flex-direction:row; gap:0.6rem}
.ant-timeline .adesc strong{font-weight:normal; font-size:12px;}
.ant-timeline .taggedreply .ant-btn span{font-size:12px}


/* stat colors */
.lpdetails_text .stat {font-size:10px !important; margin-left:0; margin-bottom:5px}
.lpdetails_text .stat:before{display:flex; width:9px; height:9px; content:""; border-radius:50%}
.stat.published {color:#25D366} .stat.published:before {background-color:#25D366}
.stat.confirmedowner {color:#c51162} .stat.confirmedowner:before {background-color:#c51162}
.stat.comingtomarket {color:#ff9f0a} .stat.comingtomarket:before {background-color:#ff9f0a}
.stat.feeds {color:#004d40} .stat.feeds:before {background-color:#004d40}

/* Filterwrap @ Tagged */
.taggednoti .ant-drawer-body{padding:0}
.taggednoti .filterwrap {background:none}
.filterwrap .react-tabs .react-tabs__tab-list{background:none; height:32px;padding: 0 1rem; gap: 1rem;border-bottom: 1px solid rgba(21,21,21,0.06);border-radius: 0;margin-bottom:0; overflow:unset}
.taggednoti .react-tabs .react-tabs__tab-list .react-tabs__tab {border:none; padding:0; margin-bottom:-5px}
.taggednoti .circle {border-radius: 5px; padding: 0.2rem 0.4rem;background: rgba(21,21,21,0.06); display: flex; line-height: 1;  margin-left: 5px; font-size: 11px; border: 1px solid rgba(21,21,21,0.26);}
.filterwrap .react-tabs .react-tabs .react-tabs__tab-list {height: 46px; background: rgba(21,21,21,0.03);}
.filterwrap > .react-tabs > ul.react-tabs .react-tabs__tab-list {box-shadow: 0 0 3px rgba(21,21,21,0.06);}
.filterwrap > .react-tabs > ul.react-tabs .react-tabs__tab-list > li {text-transform:uppercase; width:50%}
.taggednoti .btnfiltergrp {display: flex; flex-direction:row;  margin-top:0.8rem;padding: 0 1rem;gap: 12%; }
.taggednoti .btnfiltergrp .ant-btn{display:flex; flex-direction:row; height:26px;padding:0.2rem}
.taggednoti .btnfiltergrp .ant-btn i {font-size:13px}
.taggednoti .btnfiltergrp .ant-btn span {font-size:12px; }
.taggednoti .btnfiltergrp .ant-btn.ant-btn-text.active {color: rgba(0, 0, 0, 0.88); background-color: rgba(21,21,21,1);}

.taggednoti .filter-content {border-top:1px solid rgba(21,21,21,0.06); margin-top:0.8rem}
.taggednoti .filter-content .filtcont-row {padding:1rem; border-bottom:1px solid rgba(21,21,21,0.06)}
.taggednoti .filter-content p, .taggednoti .filter-content span {font-size:12px; margin-bottom:0}
.taggednoti .filter-content .border p{font-size:11px}
.taggednoti .datestamp{margin-top:0.6rem; display:flex; flex-direction:row; gap:0.3rem; align-items:center}
.taggednoti .datestamp p{font-size:10px;color:rgba(21,21,21,0.5)}
.taggednoti .ant-avatar > img {border-radius: 0;position: absolute;  top: 50%; left: 50%;transform: translate(-50%,-50%); width: unset;  height: 100%;}
.taggednoti .filter-content .filtcont-row.unread {background: rgba(21,21,21,0.01);}
.taggednoti .datestamp .read {color:#25D366}
.taggednoti .replywrap {width:100%;}
.taggednoti .replywrap .bi.bi-reply-fill {rotate: -180deg; line-height: 1;height: 16px; margin-right:3px}
.taggednoti .filterwrap > .react-tabs > .react-tabs__tab-list > .react-tabs__tab {width: 50%; text-transform: uppercase;letter-spacing: 4px;opacity:0.7;}
.taggednoti .filterwrap > .react-tabs > .react-tabs__tab-list > .react-tabs__tab.react-tabs__tab--selected {font-family: 'Poppins-Bold', sans-serif; opacity:1}
.taggednoti .filterwrap > .react-tabs > .react-tabs__tab-list > .react-tabs__tab.react-tabs__tab--selected:after{display:none}
.taggednoti .filterwrap > .react-tabs > .react-tabs__tab-list:hover > .react-tabs__tab:after {display:none}
.taggednoti .filterwrap > .react-tabs > .react-tabs__tab-list > .react-tabs__tab.react-tabs__tab--selected span:after {content: "";width: 100%;height: 2px;background: #151515;display: block;position: absolute;bottom: 0;left: 0;}
.taggednoti .filterwrap > .react-tabs > .react-tabs__tab-list > .react-tabs__tab.react-tabs__tab--selected span {position:relative; line-height:27px}
.taggednoti .btnfiltergrp .ant-btn.active span {color: #FFF;}
.taggednoti .btnfiltergrp .ant-btn.active span.circle {  background: #FFF; color: #151515;}

/* Sidebar */
.leadsidebar {box-shadow:0 -5px 6px rgba(21,21,21,0.06); background:#FFF; border-radius:0; display:flex; flex-direction:column}
.leadsidebar .ant-space {flex-direction:column; margin-left:0; margin-top:5px}

/* Filter tabs */
.filter .react-tabs .react-tabs__tab-list {height:32px; background:none; overflow:hidden}


/* ======= My Activity Feed ======= */
.myactivityfeed .ant-drawer-body{padding:0 }
.myactivityfeed ul.ant-timeline {padding: 1rem;}
.myactivityfeed .adesc .ant-tag {margin-bottom:4px}
.myactivityfeed .filter {border-bottom:1px solid rgba(21,21,21,0.06); padding:1rem; justify-content:end}
.myactivityfeed .ant-timeline p.awriter {margin-bottom: 0;}
/*start Icons type */
.myactivityfeed .ant-timeline .ant-timeline-item-head-custom {background: #151515;border-radius: 50%;height: 24px; width: 24px; margin-top:0 !important; padding: 4px;}
.myactivityfeed .ant-timeline .anticon {color: #FFF; line-height: 1; vertical-align: top; font-size:12px}
.myactivityfeed .ant-timeline .ant-timeline-item-head i.bi {color: #FFF;font-size: 10px;}

/* End: Icons Type */
.myactivityfeed .ant-timeline p {font-size:12px;}
.myactivityfeed .ant-timeline .adesc p, .myactivityfeed .ant-timeline .adesc b {font-size:12px;}
.myactivityfeed .ant-timeline .adesc b {text-transform: capitalize; }
/* start:avatar */
.myactivityfeed .ant-timeline .ant-timeline-item-content .ant-avatar.ant-avatar-icon {background: #FFF; margin-right:3px;flex: 1 1 content;}
.myactivityfeed .ant-timeline-item-content img {   width: 24px; height: 24px;border-radius: 0;}
/* end:avatar */
.myactivityfeed .atime time{justify-content:start;text-align:left;}
.myactivityfeed .datestamp {display:flex; flex-direction:row; gap:0.3rem}
.myactivityfeed .datestamp p, .myactivityfeed .datestamp i {font-size:10px}
.myactivityfeed .viewingbooked .adesc > .d-flex > .d-flex:first-child {border-right:1px solid rgba(21,21,21,0.06); padding-right:5px; margin-right:5px;}
/* Start: colors icons */
li.ant-timeline-item.timenoanswer .ant-timeline-item-head {background: #5D2E8C;}
li.ant-timeline-item.timephone .ant-timeline-item-head {background:#580aff;}
li.ant-timeline-item.timeviewing .ant-timeline-item-head{background: #19c0cc}
li.ant-timeline-item.timenote .ant-timeline-item-head{background:#ff8500}
li.ant-timeline-item.timeoffer .ant-timeline-item-head{background:#ffba08}

.endoftimeline {display:flex; flex-direction:row; gap:0.2rem; justify-content:space-evenly; opacity:0.6; margin:1rem 0}
.endoftimeline p {white-space:nowrap; letter-spacing:3px; text-transform:uppercase; font-size:11px; }
.endoftimeline hr { border-color: rgba(21,21,21,0.8);margin: 0.55rem;}

.ant-popover.filtermyact .ant-btn span{font-size:12px; color:#151515; text-align:left;}
.ant-popover.filtermyact .ant-btn i {color:#151515; margin-right:5px; font-size:12px;}
.ant-popover.filtermyact .ant-btn{justify-content:start; display:flex;align-items: center;}
.ant-popover.filtermyact .ant-btn.active {background:rgba(21,21,21,0.06)}
.avatarwrap {align-self: start;}
.childrenreply.ant-timeline .ant-timeline-item {padding-bottom:5px}
.ant-popover.filtermyact .ant-btn:hover {background: #151515;}
.ant-popover.filtermyact .ant-btn:hover i, .ant-popover.filtermyact .ant-btn:hover span {color:#FFF}
.myactivityfeed .ant-timeline > li:last-child .ant-timeline-item-tail {display:none}

/* Spinner Opportunity Loading */
.spinnerOp img.ant-spin-dot {width: 7rem;height: fit-content;}



.ant-timeline .awriter .pstat { width: fit-content; font-size: 11px; padding: 0 5px;display: flex; width:fit-content}
.ant-timeline-item-content .adesc .ant-tag {width:fit-content; padding: 0 1rem}
.ant-timeline-item-content .ant-tag.notinterested {color: #FFF;}


.ant-timeline .messagewrap { position: relative; margin-left: 20px; margin-bottom: 10px;padding: 10px; background-color: #F6F6F9;  width: 100%; height: fit-content; text-align: left; border: 1px solid #F6F6F9; border-radius: 0;margin-top:5px}
.ant-timeline .messagewrap p, .ant-timeline .messagewrap span {font-size:11px;}
.ant-timeline-item-content .messagewrap .ant-tag {width:fit-content; padding: 0 1rem}
.ant-timeline .messagewrap:after {content: '';position: absolute; width: 0;height: 0; border-top: 10px solid #F6F6F9; border-left: 10px solid transparent; border-right: 10px solid transparent;top: 0; left: -10px;}
.ant-timeline .messagewrap:before { content: ''; position: absolute; width: 0; height: 0; border-top: 10px solid #F6F6F9; border-left: 10px solid transparent; border-right: 10px solid transparent; top: -1px; left: -10px;}

.editleadwrap.referral .ant-collapse-ghost >.ant-collapse-item >.ant-collapse-content >.ant-collapse-content-box, .editleadwrap.referral .ant-collapse-ghost >.ant-collapse-item .ant-collapse-header {padding:0}
.editleadwrap.referral .ant-collapse-ghost >.ant-collapse-item >.ant-collapse-content >.ant-collapse-content-box .ant-col {border:none}
.editleadwrap.referral .ant-collapse-ghost >.ant-collapse-item >.ant-collapse-content >.ant-collapse-content-box .ant-col .ant-select .ant-select-selection-search  input:focus{border:none !important}
.newleadwrap .ant-modal-body .ant-select.ant-select-show-search .ant-select-selector {line-height: 36px; height: 36px;overflow: hidden;}
.newleadwrap .ant-modal-body .form-group .ant-select .ant-select-selection-search input:focus{border:none !important}
.newleadwrap .ant-modal-body .form-group .ant-select .ant-select-selection-search input{height:36px; font-size:14px}


.pstat.expired {border-color:#e4002d; color:#e4002d; width:fit-content;background-color:rgba(228,0,45,0.16)}
.newleadwrap .ant-modal-footer .ant-btn.ant-btn-primary {background:#151515;}
.newleadwrap .ant-modal-footer .ant-btn.ant-btn-primary span {color:#FFF}
button.ant-modal-close { margin-top: -10px;}

.timelineaddbtns .ant-btn span {font-size:12px;}
.timelinelist .timeverify .ant-timeline-item-head.ant-timeline-item-head-custom {background: #28b16d;}

.timelinelist .listinginfowrap {gap:0.3rem;height: unset; }
.timelinelist .listinginfowrap .ant-col {border-right:0;}
.timelinelist .listinginfowrap .info-title {color: #151515;font-size: 11px;margin-bottom: 0;}
.listinginfowrap .info-group p:nth-child(2) {font-family: 'Poppins-Semibold',sans-serif;}
.font-14 {font-size:14px !important}
.font-16 {font-size:16px !important}
.text-green {color:#3EC70B}

.timelinelist .ant-timeline-item-content .adesc.green {background-color: #FFF; color: #25D366; border:.1rem solid #28b16d}
.timelinelist .ant-timeline-item-content .adesc.green p, .timelinelist .ant-timeline-item-content .adesc.green i { color:#151515}
.timelinelist .listinginfowrap .ant-col-8 {display:block;flex: 0 0 50%;max-width: 50%;margin-top:5px;}
.timelinelist .listinginfowrap .ant-col.ant-col-24:first-child {border-bottom:1px solid rgba(21,21,21,0.06)}
.timelinelist .listinginfowrap >div:last-child{border-top:1px solid rgba(21,21,21,0.06)}
.timelinelist .ctype {letter-spacing: 2px; text-transform: uppercase; font-size:10px}
.noborder{border:0 !important}

.timelinelist .timeverify .ant-timeline-item-content > .d-flex > .d-flex:first-child {background: #28b16d;}
.timelinelist .timeverify .ant-timeline-item-content > .d-flex > .d-flex:first-child p {color: #FFF;padding: 0 5px;margin-left:0}
.timelinelist .timeverify .ant-timeline-item-content .adesc {margin-top: 0;border-top: 0 !important;}
.timelinelist .timeverify .ant-avatar {margin-left: 5px;  }

.addtoverification .ant-btn span{font-family: "Poppins-Bold", sans-serif}
.timelinelist .listinginfowrap .info-group . .d-flex {align-items:center}

.timeverify .ant-timeline-item-content .adesc .ant-tag { padding: 0 5px;}
.timeverify .ant-timeline-item-content .adesc .info-group i.bi {display:flex; align-items:center; margin-right:0 !important}

.timelinelist .listinginfowrap tag {font-size: 11px; border: 1px solid #d9d9d9;background: rgba(0, 0, 0, 0.02); padding: 0 5px; border-radius: 4px; color: rgba(21,21,21,0.6);}
.timelinelist .listinginfowrap tag.updated {background-color: #e9f7f0;border-color: #28b16d; color: #28b16d;}
.timelinelist .adesc.green .ant-col-8 .info-group > .d-flex.flex-row { flex-wrap: wrap; gap: 0.3rem;}
.verify-wrap .ant-table .propertyinfo p, .verify-wrap .ant-table .propertyinfo span, .verify-wrap .ant-table .propertyinfo p.stat  {font-size:11px !important;}
.verify-wrap .ant-table .ant-table-cell {padding:5px 10px !important;}
.verify-wrap .ant-pagination button.ant-pagination-item-link  {font-size: 10px;margin-top: 0;}
.verify-wrap .ant-pagination .ant-pagination-item {display: flex;  justify-content: center; align-items: center;}
.verify-wrap .ant-table .ant-table-content {overflow-x: auto;}
.verify-wrap .leadetailsowner div[editext="view"] {white-space: nowrap; overflow: hidden;text-overflow: ellipsis; max-width: 85%;}
.verify-wrap .form-group .leadetailsowner button[editext='edit-button'] { margin-top: 0;}
.verify-wrap .ant-table-wrapper .ant-table-content table {border: 1px solid rgba(21,21,21,0.16) !important; border-radius: 0.3rem;overflow: hidden;}
.ant-modal .ant-modal-footer .ant-btn.ant-btn-primary {background: #151515; color: #FFF; opacity: 1;}
.ant-modal .ant-modal-footer .ant-btn.ant-btn-primary:hover {background: #FFF !important; color: #151515; opacity: 0.8;}
.verify-wrap .ant-table .propertyinfo p.info-title {letter-spacing:2px; text-transform:uppercase}
.verify-wrap .ant-avatar > img {width: 18px; height: 18px; border-radius: 0;}

.timelinelist .timephone .ant-col-8 { flex: 0 0 33.33%; max-width: 33.33%;}

.viewingtable .listinginfowrap {max-width:100%;gap: 0.6rem;height: unset;}
.viewingtable .listinginfowrap .info-group{width:100%;}
.viewingtable .ant-table-cell {vertical-align:top}
.viewingtable .namecolwrap .bi.bi-pin-angle-fill { position: relative;}


.timelinelist .adesc.green i.bi-person-fill-check{font-size:1rem; color:#25d366; width:fit-content; margin:0 auto}
.timelinelist .adesc.green .ant-tag {color:#25d366; border:1px solid #25d366; font-size:9px; margin-inline-end:0}
.timelinelist .adesc.green .cname {font-size:16px;}
.timelinelist .adesc.green .fourcolwrap {display:flex;flex-direction:row; justify-content:space-between; width:100%;  padding-top:5px; }
.timelinelist .adesc.green .fourcolwrap .flex-4 {display:flex; flex-direction:column; flex:0 0 25%; max-width:25%}
.timelinelist .adesc.green .fourcolwrap .flex-4 p:first-child{color:#747177; font-size:10px}
.timelinelist .adesc.green .fourcolwrap .flex-4 p {font-size:12px;}
.timelinelist .adesc.green .fourcolwrap .flex-4 p i.bi {margin:0 3px}


.viewingwrap .filterswrap.formcheckboxgroup .ant-checkbox-wrapper:first-child span {color:red}
.viewingwrap .filterswrap.formcheckboxgroup .ant-checkbox-wrapper:first-child::before{background-color:red}
.viewingwrap .filterswrap.formcheckboxgroup .ant-checkbox-wrapper:nth-child(2) span {color:#255fd3}
.viewingwrap .filterswrap.formcheckboxgroup .ant-checkbox-wrapper:nth-child(2)::before{background-color:#255fd3}
.viewingwrap .filterswrap.formcheckboxgroup .ant-checkbox-wrapper:nth-child(3) span {color:#8a8a8a}
.viewingwrap .filterswrap.formcheckboxgroup .ant-checkbox-wrapper:nth-child(3)::before{background-color:#8a8a8a}
.viewingwrap .filterswrap.formcheckboxgroup .ant-checkbox-wrapper:nth-child(4) span {color:#ff9f0a}
.viewingwrap .filterswrap.formcheckboxgroup .ant-checkbox-wrapper:nth-child(4)::before{background-color:#ff9f0a}


.followupupdatebtn {background:#151515; display:flex; align-items:center}
.followupupdatebtn:hover {border-color:#151515 !important}
.followups .ant-table-wrapper .ant-table-tbody > tr > td .followupupdatebtn span {color:#FFF}
.followups .ant-table-wrapper .ant-table-tbody > tr > td .followupupdatebtn:hover span {color:#151515}
.followup .tablelistwrap div.ant-table-wrapper .ant-table-tbody > tr > td:first-child, .followups .tablelistwrap div.ant-table-wrapper .ant-table-thead > tr > th {padding: 8px !important;}
.followups .folnamebtn {padding:0; border:none; text-align:left; margin-top:0; display:flex; flex-direction:column; align-items:flex-start; height:fit-content; opacity:1; box-shadow:none !important; white-space:unset} 
.folpropdetails .foldetbtn {height: fit-content; width: 100%; border: none; display: flex;  flex-direction: column; color: #151515; margin: 0; padding: 0; box-shadow: none; opacity:1}
.folpropdetails .foldetbtn:hover {color:#151515; opacity:0.7}

.followups .ant-table-wrapper .ant-table-tbody > tr > td span.slick-prev {left: 0;z-index: 99;color: #FFF; font-size:20px}
.followups .ant-table-wrapper .ant-table-tbody > tr > td span.slick-next {right: 0;z-index: 99;color: #FFF; font-size:20px;}

@media(max-width:576px) {
    .folpropdetails .foldetbtn{
        align-items: baseline !important    ;
    }
}