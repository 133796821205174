/* HR PLATFORM */

.border-right {border-right: 1px solid rgba(21,21,21,0.16)}
h1,h2,h3,h4,h5,h6 {font-family: 'Poppins-Bold', sans-serif; margin-bottom:0}
/* .ant-layout-content {background: url("../../../public/images/maskbg.png") #FFF !important;overflow: hidden;} */
.hr-wrap {display:flex; flex-direction:row; width:99%; margin:1.8rem auto !important}
.hr-wrap .ant-space {background:#FFF}
.infowrap { gap: 1.7rem; display: flex;flex-direction: column;}
.infowrap p {margin-bottom:0}
.infowrap .ant-space-item {width:100%}
.infowrap .maininfo .ant-space-item {text-align:center; width:fit-content}

/* button */
.hr-wrap .ant-btn.ant-btn-primary {border:1px solid rgba(21,21,21,0.16); text-align:center; background-color:#151515; border-radius:0; height:2.25rem; opacity:1}
.hr-wrap .ant-btn.ant-btn-primary span{color:#FFF;}
.hr-wrap .ant-btn.ant-btn-primary:hover {border:1px solid #151515; background:#FFF}
.hr-wrap .ant-btn.ant-btn-primary:hover span{color:#151515}
.hr-wrap .btngrp .ant-btn.ant-btn-primary {width:100%}

.hr-wrap .ant-btn.ant-btn-secondary {border:1px solid rgba(21,21,21,0.16); text-align:center; color:#151515; border-radius:0; height:2.25rem; opacity:1}
.hr-wrap .ant-btn.ant-btn-secondary span{color:#151515;}
.hr-wrap .ant-btn.ant-btn-secondary:hover {border:1px solid #151515; background:#151515}
.hr-wrap .ant-btn.ant-btn-secondary:hover span{color:#FFF}
.hr-wrap .btngrp .ant-btn.ant-btn-secondary {width:100%}

.leaveinfo .ant-space-item, .infohistory .ant-space-item {width:100%;}
.leaveinfo .ant-space-item .ant-row .ant-col .ant-row {padding:1rem}

.hr-wrap .ant-progress-text {font-size:12px; font-family: 'Poppins-Bold', sans-serif}
.leaveinfo .ant-table .ant-table-thead > tr > th.ant-table-cell , .infohistory .ant-table .ant-table-thead > tr > th.ant-table-cell {background: transparent !important;  text-transform: capitalize; letter-spacing: 0;}
.leaveinfo .ant-table .ant-table-thead > tr > .ant-table-cell, .infohistory .ant-table .ant-table-thead > tr > .ant-table-cell {background:none !important}
.leaveinfo .ant-table-content, .infohistory .ant-table-content {overflow-x:hidden; border-radius:0;}
.leaveinfo .ant-table-content table, .infohistory .ant-table-content table {border:none !important}

.iconrequest {width:2rem; height:2rem; display:flex; background:rgba(21,21,21,0.16); align-items: center; justify-content: center; color: #FFF;}
.dayswrap {display:flex; flex-direction:row; gap:1.5rem !important; margin-top:0.6rem}

/* Modals */
.hr-wrap .ant-modal-wrap .ant-modal-content {border-radius:0;}
.hr-wrap .ant-modal-wrap .ant-modal-content .ant-modal-header .ant-modal-title {font-family: 'Poppins-Bold', sans-serif;font-weight: 400;}
.hr-wrap .ant-modal-wrap .ant-modal-content .ant-modal-header, .hr-wrap .ant-modal-wrap .ant-modal-content .ant-modal-footer {box-shadow:none;}

/* FORM */
.space-form {display:flex; flex-direction: column; justify-items:start; width:100%;margin-top:0.5rem }
.space-form label {text-transform:uppercase; font-size:11px; color:#151515; text-align:left; font-family: 'Poppins-Bold', sans-serif}
/* Select */
.space-form input, .space-form .ant-select {height:36px; line-height:36px; overflow:hidden; border-radius:0;}
.hr-wrap .ant-modal-body .space-form .ant-select.ant-select-show-search .ant-select-selector {height:36px; line-height:36px;     border: 1px solid rgba(21,21,21,0.16); border-radius: 0;}
.hr-wrap .ant-modal-body .space-form .ant-select.ant-select-show-search .ant-select-selector .ant-select-selection-placeholder {line-height:36px !important}
.leavecalendar {border: 8px solid #F5F6FA; }
.hr-wrap .ant-space .ant-space-item {width: 100%;}
.hr-wrap .ant-space .ant-picker-calendar {border-radius:0}
.hr-wrap .space-form .ant-space-item .ant-radio-group .ant-radio-wrapper { font-size: 14px;text-transform: capitalize;line-height: 2;}
.hr-wrap .space-form textarea {border-radius:0;}

.notileaves p {margin-bottom:0; padding:0.5rem 1rem}
.notileaves .border {border-color:#151515 !important; gap:0.6rem}
.certforms {gap:1rem; }
.certforms > .space-form {flex:0 0 48%; max-width:48%; margin-left:0}
.certificaterequestmodal .space-form .ant-space-item .ant-radio-group .ant-radio-wrapper {line-height: 2.5;}



/* Calendar */
.hr-wrap .ant-picker-calendar {border:8px solid #F5F6FA}
.hr-wrap .ant-picker-calendar .ant-radio-group.ant-picker-calendar-mode-switch {display: flex;}
.hr-wrap .ant-picker-calendar .ant-picker-panel .ant-picker-content {min-height:500px}

/* Pending Requests */
.pendingrequest h6 {padding:1rem}
.hr-wrap .requestitem {display:flex; flex-direction:row;border-bottom:1px solid rgba(21,21,21,0.16); cursor: pointer;}
.hr-wrap .requestitem p{margin-bottom:0}
.ant-space.requestitem .ant-space-item .ant-row {flex-wrap: nowrap;}
.requestitem .ant-col:last-child{margin-left:auto}
/* icon leave stat */
.requestitem .ant-col i.bi { padding: 1rem;background: rgba(21,21,21,0.06); height: 39px; width: 39px; display: flex; justify-content: center;  align-items: center; font-size: 1.2rem;}
.requestitem .ant-col i.bi.bi-suitcase-lg {background:#E8F0FF; color:#5A9EFF}
.requestitem .ant-col i.bi.bi-bandaid {background:#FFF5E6; color:#FEBC5A}
.requestitem .ant-col i.bi.bi-house {background:#B6BBF9; color:#3C2973}

/* leave stat */
.leavestat{font-size:11px; background:rgba(21,21,21,0.06); color:#FFF;border-radius:36px; width:fit-content; padding:2px 8px; white-space:nowrap}
.leavestat.annual {background:#5A9EFF; }
.leavestat.sick {background:#FEBC5A; }
.leavestat.wfh {background:#3C2973; }
.leavehistory .ant-card .ant-card-body, .myupcomingleave .ant-card .ant-card-body, .mypendingrequest .ant-card .ant-card-body { padding: 5px;}

.hr-wrap .infohistory, .hr-wrap .infohistory .ant-space-item,.hr-wrap .infohistory .ant-space-item .ant-card{ height: 100%;}
.myupcomingleave .ant-card-head, .mypendingrequest .ant-card-head {border:none !important}
.mypendingrequest {border-top:0 !important}
.leavehistory .ant-card-head {padding: 1rem 0.5rem 0 1.5rem; min-height: fit-content;}
.hr-wrap .infohistory .ant-card-head-title .ant-space-item { width: fit-content;}

.hr-wrap .infohistory .ant-card-head-title .ant-space-item .ant-leave-filter {border:none}
.hr-wrap .infohistory .ant-card-head-title .ant-space-item .ant-leave-filter label{font-size:12px; border:none}
.hr-wrap .infohistory .ant-card-head-title .ant-space-item .ant-leave-filter label.ant-radio-button-wrapper.ant-radio-button-wrapper-checked { background: transparent;}
.hr-wrap .infohistory .ant-card-head-title .ant-space-item .ant-leave-filter .ant-radio-button-wrapper.ant-radio-button-wrapper-checked span{ color: #151515; font-family: 'Poppins-Bold', sans-serif;}
.hr-wrap .infohistory .ant-card-head-title .ant-space-item .ant-leave-filter .ant-radio-button-wrapper.ant-radio-button-wrapper-checked::after {content:''; height:2px; width:95%; background:#151515; display:flex;position:absolute; left:0; bottom:0}
.hr-wrap .infohistory .ant-card-head-title .ant-space-item .ant-leave-filter .ant-radio-button-wrapper::before{display:none}

.ant-modal .ant-picker-calendar .ant-picker-panel .ant-picker-content {min-height: unset;}

.ant-modal.calendarrequestmodal .ant-picker-calendar .ant-picker-panel .ant-picker-content {  height: 64vh;}

.ant-select.ant-select-sm.ant-picker-calendar-month-select.ant-select-single.ant-select-show-arrow, .ant-select.ant-select-sm.ant-picker-calendar-year-select.ant-select-single.ant-select-show-arrow{ width: fit-content !important;}

/* Pending Request */
.pendingrequestmodal .ant-row p{margin-bottom:0;}
.pendingrequestmodal .ant-row .opacity-5 {opacity:0.5; width:33.33%;}
.pendingrequestmodal .leaveicon {padding:5px; background:#E8F0FF; width:62px; height:62px;display: flex; align-items: center; justify-content: center;color: #FFF;font-size:1.4rem}
.pendingrequestmodal .leaveicon i.bi.bi-suitcase-lg {background:#E8F0FF; color:#5A9EFF}
.pendingrequestmodal .leaveicon i.bi.bi-bandaid {background:#FFF5E6; color:#FEBC5A}
.pendingrequestmodal .leaveicon i.bi.bi-house {background:#B6BBF9; color:#3C2973}

.superadminwrap .pendingrequest .requestitem {padding:0.8rem 1.5rem}
.superadminwrap .calwrap {}
.superadminwrap .bottomeventswrap {gap:0.6rem}
.superadminwrap .bottomeventswrap h6 {padding:1.5rem 1rem 0.5rem}
.superadminwrap .btmevents .border-right { border-right:3px solid #151515; height:100%;padding-right:0.8rem; font-size:16px; display:flex;align-items:center}
.superadminwrap .btmevents p{margin-bottom:0}
.superadminwrap .btmevents {display:flex; flex-direction:row; border-bottom:1px solid rgba(21,21,21,0.16); padding-bottom:10px; width:100%;}
.superadminwrap .btmevents .ant-col {flex:0 1 auto}
/* birthday */
.superadminwrap .btmevents.birthday {color:#ff0000}
.superadminwrap .btmevents.birthday .border-right { border-right:3px solid #ff0000}
.superadminwrap .btmevents.birthday .holidaytype {color:#ff0000}
/* Joining Anniv */
.superadminwrap .btmevents.joining {color:#FF8C00}
.superadminwrap .btmevents.joining .border-right { border-right:3px solid #FF8C00}
.superadminwrap .btmevents.joining .holidaytype {color:#FF8C00}
/* labourcard */
.superadminwrap .btmevents.labour {color:#703800}
.superadminwrap .btmevents.labour .border-right { border-right:3px solid #703800}
.superadminwrap .btmevents.labour .holidaytype {color:#703800}

.leavetodaywrap { gap: 0;padding: 0 1rem;}