.dashboardwrap {padding: 1.5rem 0; background: url("../../../public/images/maskbg.png"); border-radius: .5rem; overflow: hidden; max-width: 94vw; margin: 0 auto;}

.dashboardwrap h1 {font-family: "Poppins-Bold", sans-serif; font-size:21px}
.dashboardwrap h2 {font-family:"Poppins-Bold", sans-serif; font-size:15px}

.dashrow-one {align-items: center;}
.dashrow-two {margin-top:0.3rem; justify-content: space-between;}

.dashboardwrap .border {background-color: #FFF; margin-bottom:1rem}
.dashboardwrap .border .toptitle {background:#fefefe;border-bottom:1px solid rgba(21,21,21,0.06); padding:1rem 1.5rem 5px; overflow:hidden}
.dashboardwrap .border .toptitle p{font-size:11px;}

.dashboardwrap .dashrow-two .border .bordercontent { min-height: 189px; overflow-y: scroll; overflow-x:hidden}
.dashboardwrap .dashrow-two .border.dashtodaysactivity { height: 68.7rem; margin-bottom: 0;}
.dashboardwrap .dashrow-two .border .bordercontentempty {height: 13.8rem;}

/* Pledge */
.dashpledge {padding:1.5rem; margin-bottom: 0;}
.weeksdate span{font-size:10px;}
.pledgecnt, .pledgecnt span {font-size:11px; margin-bottom:0;display:flex; gap:0.4rem}
.pledgecnt.targetaed .nottarget {color:#ff0000}
.pledgecnt.targetaed .target {color:#25D366}
.pledgecnt .nottarget.circle {border-radius: 50%;background:#ff0000; color:#FFF;display: inline-block;width: 18px; height: 18px; text-align: center;line-height: 1.6; margin-right: 3px;}
.pledgecnt .target.circle {border-radius: 50%;background:#25D366; color:#FFF; display: inline-block;width: 18px; height: 18px; text-align: center;line-height: 1.6; margin-right: 3px;}


.todaysdate {font-size:12px; display:flex; flex-direction:row; gap:0.6rem; margin:0; align-items: center;}
.schedulefilter {display:flex; flex-direction:row; gap:0.8rem}
.schedulefilter span {font-size:12px}

.scheddetails {text-decoration: none; }
.scheddetails > .ant-row{ padding: 1rem 1.5rem; border-bottom:1px solid rgba(21,21,21,0.06)}
.scheddetails p {margin:0; font-size:11px}
.scheddetails .schedprop p.font-bold {font-size:14px;overflow: hidden; white-space: nowrap;width: 92%;text-overflow: ellipsis;}
.scheddetails .label {font-size:10px; letter-spacing: 3px;  margin-bottom:0;text-transform: uppercase;}
.scheduletime .font-bold {font-size:18px}
.propertiesnewlymatchbuyers .scheddetails > .ant-row {padding: 0.94rem 1.5rem;}

.lastcall {line-height: 1; margin-bottom:5px;}
.lastcall span {font-size:10px; color:#151515}

.dashviewall {font-size:10px;padding:0.5rem 1.5rem; color:#151515; text-decoration: none; display: flex;;}

.imagewrap { display:flex; overflow:hidden; width:100%;max-height:5.5rem}

.matchlistingcount {gap:1rem}

.scheddetails p.stat, .schedulefilter p.stat { font-size: 10px !important;margin-bottom: 0;}
.scheddetails p.stat:before, .schedulefilter p.stat::before {content:""; width:8px; height:8px; border-radius:50%;background-color: #151515;}
.scheddetails p.stat.published:before, .schedulefilter p.stat.published:before {background-color:#25D366}
.scheddetails p.stat.viewing, .schedulefilter p.stat.viewing {color:#19C0CC}
.scheddetails p.stat.viewing:before, .schedulefilter p.stat.viewing:before {background-color:#19C0CC}
.scheddetails p.stat.followup, .schedulefilter p.stat.followup {color:#2153DD}
.scheddetails p.stat.followup:before, .schedulefilter p.stat.followup:before {background-color:#2153DD}

.moveindate.stat {text-align: center;display:flex;padding:2px 5px; border-radius: 3px;}
.moveindate.stat p{font-size: 10px;}
.moveindate.stat.working p{color:#25D366}
.moveindate.stat.working {background-color: rgba(37, 211,102,.06);}

/* Properties with image */
.scheddetails .noimageyet { height: 78px;}
.scheddetails .noimageyet .defimg {width:45px}
.scheddetails .noimageyet span.font-12 {color:#151515}

/* Empty State */
.emptystate {display:flex; flex-direction:column; opacity:0.4; margin:0 auto;text-align:center; height:100%; justify-content:center; width:70%}
.ant-skeleton {display:flex; margin:1rem auto 0; width:90%}

.dashtodaysactivity .rowdashwrap {height:89%}
.dashtodaysactivity .rowdashwrap .ant-col {height:100%; overflow: hidden scroll;}

.agentwrapfilter .ant-select.ant-select-show-search .ant-select-selector {margin:0;border-radius: 0;}

.agentwrapfilter div.form-group input:focus {border: none !important;}
.imagewrap .slick-arrow.slick-prev {left: 0;inset-inline-start: 0; z-index: 99; position: absolute; top: 50%; margin-top: 0; color: #FFF; transform: translateY(-50%);font-size: 18px;}
.imagewrap .slick-arrow.slick-next {right: 0; inset-inline-start: 0; z-index: 99; position: absolute; top: 50%; margin-top: 0; color: #FFF; transform: translateY(-50%);font-size: 18px;}
.imagewrap .slick-slider.slick-initialized {height: 100%;}
.imagewrap .slick-dots.slick-dots-bottom { bottom: 5px;width: 90%;margin: 0 auto; opacity:1}