.container-login100 {background-color: #000;background-size: 100% auto; background-image:'url("images/addressskyviewblur.webp")';}
.wrap-login100 .login100-form { background-color: #FFF;padding: 0 1rem; align-self: center;display: flex; flex-direction: column; min-height: unset;}
.wrap-login100 { height: 85vh; width: 90vw; box-shadow: 0 0 10px rgba(21,21,21,0.26)}
.login100-form img { margin: 0 auto; display: flex;}
.login100-form-btn {background: #151515; border-radius: 0.3rem;}
.wrap-login100 .txt1 {font-family: "Poppins-Regular", sans-serif; text-decoration: none; font-size: 12px;}
.wrap-login100 .txt2 {font-family: "Poppins-Regular", sans-serif; font-size: 12px;}
.wrap-login100 .label-checkbox100::before {border-color:rgba(21,21,21,0.25); width:15px; height:15px}
.login100-form-social a {text-decoration: none;width: 100%;border-radius: 0; padding: 0 10px; gap: 0.6rem; height: 46px;}

.form-check .form-check-input:checked {background-color: #151515; border-color: #151515;}
.form-check .form-check-input:focus {box-shadow: 0 0 0 .25rem rgba(21,21,21,.10);}
.container-login100-form-btn .ant-btn {width:fit-content !important}
.container-login100-form-btn .ant-btn span {font-size:16px;}
@media screen and (max-width:768px) {
    .limiter .container-login100 { background-size: auto 100%; background-position: center; }
    .wrap-login100 {height:fit-content; padding:4rem 0; border-radius: 10px;}
    .container-login100-form-btn .ant-btn span {font-size:14px;}
    .login100-form img {padding:0}
    .container-login100-form-btn .ant-btn {width:100% !important}
}

