

.fileInput
  {border-bottom: 4px solid lightgray;
  border-right: 4px solid lightgray;
  border-top: 1px solid black;
  border-left: 1px solid black;
  padding: 10px;
  margin: 15px;
  cursor: pointer}
  
.imgPreview
  {text-align: center;
  margin: 3px 10px;
  height: 300px;
  width: 300px;
  border-left: 1px solid gray;
  border-right: 1px solid gray;
  border-top: 1px solid gray;
  border-bottom: 1px solid gray;
  }
  .img_profile
    {width: 100%;
    height: 100%}
  
.previewText
  {width: 100%;
  margin-top: 20px;}
  
.submitButton
  {padding: 12px;
  margin-left: 10px;
  background: white;
  border: 4px solid lightgray;
  border-radius: 15px;
  font-weight: 700;
  font-size: 10pt;
  cursor: pointer;}
  

.centerText
  {text-align: center;
  width: 500px}