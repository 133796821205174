
.videowrap .ant-table-tbody { display: flex; flex-direction:row; gap:2rem; flex-wrap:wrap}
.videowrap .ant-table-tbody tr {display:flex; flex-direction:column; border: 1px solid rgba(21,21,21,0.06);background: #FFF; box-shadow: 0 0 4px rgba(21,21,21,0.06);margin: 1rem 0; flex:1 1 23%; max-width:23%}
.videowrap .ant-table-wrapper .ant-table-content table {border:none !important}
.videowrap .ant-table-tbody > tr > td {border:none !important; padding:0 !important}
.videowrap .ant-table-tbody > tr > td:nth-child(2) {padding:0.3rem 1rem 0.8rem !important}
.ant-picker-disabled input {background: rgba(21,21,21,0.01 ) !important;  }
.btngrp-actions { border-top: 1px solid rgba(21,21,21,0.06); display: flex;justify-content: space-between;flex-direction: row;}
.btngrp-actions .ant-btn span {font-size: 12px;}
.btngrp-actions .ant-btn {  width: 50%; color: #151515; display: flex;  justify-content: center; align-items: center;}
.btngrp-actions .ant-btn:first-child {border-right:1px solid rgba(21,21,21,0.06)}
div.ant-table-wrapper .ant-table {background: transparent;}

.trainingwrap h4 {font-size:16px; border-bottom:1px solid rgba(21,21,21,0.16) ;width:100%; display:flex}
.trainingwrap h4 span {background:#151515; color:#FFF; padding:0.3rem 1rem;text-transform: uppercase;  letter-spacing: 7px;font-size: 12px;}

@media(max-width:900px) {
    .videowrap .ant-table-tbody tr {flex:1 1 46% !important; max-width:46% !important}

}

@media(max-width:550px) {
    .videowrap .ant-table-tbody tr {flex:1 1 100% !important; max-width:100% !important}

}