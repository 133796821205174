/* Leads Page */

.leadswrap {overflow: hidden;min-height:90vh}
.sideactions {border-right: 1px solid #D9D9D9;min-height: 50vh; background: #FFF; display:flex; flex-direction:column; gap:1rem}
.icongroup {display:flex; position:relative; padding:0 1rem 1rem;justify-content:center }
.icongroup .overlay-icon {position:absolute; z-index:2;right: 13px;bottom: -2px;}
.leadswrap .border-bottom{border-bottom:1px solid #D9D9D9}
/* Start: Tabs */
.leadswrap .react-tabs .react-tabs__tab-list {overflow: hidden;height: 40px; margin: 0; padding:0; background-color: transparent; border-radius: 0; gap:0.6rem}
.leads-content .ant-col-21 >.react-tabs {padding: 0 1.5rem; height:100%}
.leadswrap .react-tabs .react-tabs__tab-list .react-tabs__tab {height: 100%;    padding:5px 3px; font-size:12px;border-right: 0;}
.leadswrap .react-tabs .react-tabs__tab-list .react-tabs__tab.react-tabs__tab--selected { font-family: "Poppins-Bold", sans-serif;}
/* End: Tabs */
.leads-content .content {padding:1rem 1.5rem}
.leads-content .content.filterwrap {background:none; gap:0.6rem}
.filterwrap.formcheckboxgroup .ant-checkbox-wrapper span {font-size: 12px;padding-bottom:5px;}
.leads-content .content.filterwrap.formcheckboxgroup {justify-content:space-between; width:100%;padding-bottom: 0;font-size: 0.67rem;}
.leads-content .content.filterwrap p, .leads-content .content.filterwrap  span {white-space:nowrap}
.leadswrap .tableleads table, .leadswrap .viewingtable table{width: 100%; text-align: start;border-radius: 8px 8px 0 0; border-collapse: separate;border-spacing: 0 1.2rem;border-right:0 !important}
.leadswrap .tableleads table, .leadswrap .viewingtables table{width: 100%; text-align: start;border-radius: 8px 8px 0 0; border-collapse: separate;border-spacing: 0 0rem !important;border-right:0 !important; border-spacing: 0 1.2rem !important;}
.leadswrap .tableleads table .ant-table-tbody>tr>td { border-top: 1px solid hsla(0,0%,8%,.16)!important; border-bottom: 1px solid hsla(0,0%,8%,.16)!important; vertical-align:top; padding:1rem 10px !important; border-radius:0}
.leadswrap .tableleads table .ant-table-tbody>tr>td:first-child{border-left: 1px solid hsla(0,0%,8%,.16)!important; border-top-left-radius:4px;border-bottom-left-radius:4px;}
.leadswrap .tableleads table .ant-table-tbody>tr>td:nth-last-child(2) {border-right: 1px solid hsla(0,0%,8%,.16)!important;border-top-right-radius:4px;border-bottom-right-radius:4px;padding: 0 1rem !important; vertical-align: middle;border-left: 1px solid hsla(0, 0%, 8%, .16) !important;}
/* .leadswrap .tableleads table .ant-table-tbody>tr>td:last-child{border-right: 1px solid hsla(0,0%,8%,.16)!important;} */
.ant-table-wrapper.tableleads, .ant-table-wrapper.viewingtable  {padding: 0 1.5rem;}
.leadswrap .tableleads table .ant-table-tbody>tr>td p {margin-bottom:0; font-size:12px;white-space: normal;}

.leadswrap .contactinfo {display:flex; flex-direction:row; gap:0.5rem}
.leadswrap .cname {font-family:'Poppins-Bold', sans-serif; white-space:normal}
.leadswrap .ctype {font-size:10px;}
.leadswrap .cstatus {margin-top:0.5rem}
.leadswrap .cstatus .pstat {font-size:10px;width: fit-content;}
.leadswrap .info-group .infovalue {font-family:'Poppins-Semibold', sans-serif}
.leadswrap .tableleads table .ant-table-tbody>tr>td p.info-title {font-size: 10px;}
.leadswrap .listinginfowrap { max-width: 100%;height: 120px;gap:1rem;}
.leadswrap .lead_stage .listinginfowrap {gap: 0.3rem;}
.leadswrap .lead_stage .listinginfowrap i.bi {width: unset !important;}
.leadswrap .tableleads table .ant-table-tbody>tr>td p.vstat { justify-content: center; gap: 0.3rem; align-items: center;}

.viewswitcher-btn .ant-btn {padding:3px 10px;color: #151515;height: 100%; font-size: 15px;border-radius:0}
.viewswitcher-btn .ant-btn:first-child{border-right:1px solid rgba(21,21,21,0.16)}
.viewswitcher-btn {border: 1px solid rgba(21,21,21,0.16); border-radius: 0.3rem;height: 36px; border-radius:4px; overflow:hidden}
.leads-content .react-tabs .react-tabs__tab .ant-btn i, .leads-content .react-tabs .react-tabs__tab .ant-btn span {font-size: 13px;}
.leads-content .react-tabs .react-tabs__tab .ant-btn {margin-top: 0;}
.ant-input-affix-wrapper input.ant-input:placeholder-shown { font-family: 'Poppins-Regular', sans-serif;color:#151515}
.teamfilter .ant-select .ant-select-selector {margin-top: 0;height: 32px; border: 1px solid rgba(21,21,21,0.16) !important;}
.teamfilter .ant-select.ant-select-show-search .ant-select-selector .ant-select-selection-item {line-height: 36px !important; height: 36px; font-size: 12px;}
.leads-content .tfilterwrap .react-tabs li span {font-size: 0.73rem; line-height: 1.2;}
.leadswrap .tfilterwrap .react-tabs .react-tabs__tab-list {height: fit-content; padding: 0; border-bottom: 0;justify-content: space-between;}
.tfilterwrap {margin: 1rem 0 0;}

.leadswrap .tfilterwrap .react-tabs .react-tabs__tab-list .react-tabs__tab::before {content: "";height: 7px; width: 7px; border-radius: 50%; background-color: #151515; margin-right:5px}
.leadswrap .tfilterwrap .react-tabs .react-tabs__tab-list .react-tabs__tab:first-child span{color:#084b83}
.leadswrap .tfilterwrap .react-tabs .react-tabs__tab-list .react-tabs__tab:first-child::before{background-color: #084b83;}
.leadswrap .tfilterwrap .react-tabs .react-tabs__tab-list .react-tabs__tab:nth-child(2)::before{background-color: #42bfdd;}
.leadswrap .tfilterwrap .react-tabs .react-tabs__tab-list .react-tabs__tab:nth-child(2) span{color: #42bfdd}
.leadswrap .tfilterwrap .react-tabs .react-tabs__tab-list .react-tabs__tab:nth-child(3)::before{background-color: #006989;}
.leadswrap .tfilterwrap .react-tabs .react-tabs__tab-list .react-tabs__tab:nth-child(3) span{color: #006989}

.leads-content .tfilterwrap .react-tabs li.react-tabs__tab--selected span { font-weight: 400; font-family: 'Poppins-Bold', sans-serif;}
.leadswrap .tfilterwrap .react-tabs .react-tabs__tab:after{display:none}
.leadswrap .tfilterwrap .react-tabs .react-tabs__tab:hover:after {display:none}
.leadswrap .formcheckboxgroup .ant-checkbox-group {gap: 0.8rem;}
.leadswrap .ant-checkbox-wrapper+.ant-checkbox-wrapper {margin-inline-start:0}

.verifybtn span{font-size:12px;}
.leadswrap .filterwrap .ant-checkbox-group .ant-checkbox-wrapper::before {height: 7px; width: 7px;}
.leadswrap .filterwrap .ant-checkbox-group .ant-checkbox-wrapper::before {display:none}
.leadswrap .filterwrap .ant-checkbox-group .ant-checkbox-wrapper:first-child span {color:#580aff}
/* .leadswrap .filterwrap .ant-checkbox-group .ant-checkbox-wrapper:first-child::before {background-color:#580aff} */
.leadswrap .filterwrap .ant-checkbox-group .ant-checkbox-wrapper::after {height:2px; position:absolute; width:0; bottom:-2px;transition:all 0.3s ease-in-out }
.leadswrap .filterwrap .ant-checkbox-group .ant-checkbox-wrapper.ant-checkbox-wrapper-checked:first-child::after {background-color:#580aff; width:100%; }
.leadswrap .filterwrap .ant-checkbox-group .ant-checkbox-wrapper:nth-child(2) span {color:#25d366}
.leadswrap .filterwrap .ant-checkbox-group .ant-checkbox-wrapper.ant-checkbox-wrapper-checked:nth-child(2)::after {background-color:#25d366; width:100%;}
.leadswrap .filterwrap .ant-checkbox-group .ant-checkbox-wrapper:nth-child(3) span {color:#19c0cc}
.leadswrap .filterwrap .ant-checkbox-group .ant-checkbox-wrapper.ant-checkbox-wrapper-checked:nth-child(3)::after {background-color:#19c0cc; width:100%;}
.leadswrap .filterwrap .ant-checkbox-group .ant-checkbox-wrapper:nth-child(4) span {color:#ffba08}
.leadswrap .filterwrap .ant-checkbox-group .ant-checkbox-wrapper.ant-checkbox-wrapper-checked:nth-child(4)::after {background-color:#ffba08; width:100%;}
.leadswrap .filterwrap .ant-checkbox-group .ant-checkbox-wrapper:nth-child(5) span {color:#F433AB}
.leadswrap .filterwrap .ant-checkbox-group .ant-checkbox-wrapper.ant-checkbox-wrapper-checked:nth-child(5)::after {background-color:#F433AB; width:100%;}
.leadswrap .filterwrap .ant-checkbox-group .ant-checkbox-wrapper:nth-child(6) span {color:#ff0000}
.leadswrap .filterwrap .ant-checkbox-group .ant-checkbox-wrapper.ant-checkbox-wrapper-checked:nth-child(6)::after {background-color:#ff0000; width:100%;}
.leadswrap .filterwrap .ant-checkbox-group .ant-checkbox-wrapper:nth-child(7) span {color:#151515}
.leadswrap .filterwrap .ant-checkbox-group .ant-checkbox-wrapper.ant-checkbox-wrapper-checked:nth-child(7)::after {background-color:#151515; width:100%;}
.leadswrap .filterwrap .ant-checkbox-group .ant-checkbox-wrapper:nth-child(8) span {color:#1da3a4}
.leadswrap .filterwrap .ant-checkbox-group .ant-checkbox-wrapper.ant-checkbox-wrapper-checked:nth-child(8)::after {background-color:#1da3a4; width:100%;}
.leadswrap .filterwrap .ant-checkbox-group .ant-checkbox-wrapper:nth-child(9) span {color:#004d40}
.leadswrap .filterwrap .ant-checkbox-group .ant-checkbox-wrapper.ant-checkbox-wrapper-checked:nth-child(9)::after {background-color:#004d40; width:100%;}
.leadswrap .filterwrap .ant-checkbox-group .ant-checkbox-wrapper:nth-child(10) span {color:#702b9e}
.leadswrap .filterwrap .ant-checkbox-group .ant-checkbox-wrapper.ant-checkbox-wrapper-checked:nth-child(10)::after {background-color:#702b9e; width:100%;}
.leadswrap .filterwrap .ant-checkbox-group .ant-checkbox-wrapper:nth-child(11) span {color:#b429f9}
.leadswrap .filterwrap .ant-checkbox-group .ant-checkbox-wrapper.ant-checkbox-wrapper-checked:nth-child(11)::after {background-color:#b429f9; width:100%;}
.leadswrap .filterwrap .ant-checkbox-group .ant-checkbox-wrapper:nth-child(12) span {color:#25D366}
.leadswrap .filterwrap .ant-checkbox-group .ant-checkbox-wrapper.ant-checkbox-wrapper-checked:nth-child(12)::after{background-color:#25D366; width:100%;}
.leadswrap .filterwrap .ant-checkbox-group .ant-checkbox-wrapper:nth-child(13) span {color:#151515}
.leadswrap .filterwrap .ant-checkbox-group .ant-checkbox-wrapper.ant-checkbox-wrapper-checked:nth-child(13)::after{background-color:#151515; width:100%;}
.leadswrap .filterwrap .ant-checkbox-group .ant-checkbox-wrapper:nth-child(14) span {color:#41B3FF}
.leadswrap .filterwrap .ant-checkbox-group .ant-checkbox-wrapper.ant-checkbox-wrapper-checked:nth-child(13)::after{background-color:#41B3FF; width:100%;}

.filterwrap.formcheckboxgroup .ant-checkbox-wrapper span.ant-checkbox:after {display:none}

.tableleads .ant-table .ant-table-content tr .ant-table-cell.namecolwrap.ant-table-cell-fix-left.ant-table-cell-fix-left-last 
{  overflow: visible; 
  /* background:transparent; */
}
.leadswrap .namecolwrap .bi.bi-pin-angle-fill { color: #f00;position: absolute;left: -1rem; bottom: 1rem;font-size: 1rem; z-index: 2;}
.leadswrap .tableleads table .ant-table-tbody>tr>td p.vstat { font-size: 10px; border: 1px solid rgba(21,21,21,0.06); border-radius: 36px; width: fit-content;padding: 0 8px;margin-top:0.3rem}
.leadswrap .tableleads table .ant-table-tbody>tr>td p.vstat.whatsapp { border-color: #47da7e; color: #47da7e;}
.leadswrap .tableleads table .ant-table-tbody>tr>td p.vstat.verified { border-color: #702b9e; color: #702b9e;}

.namecolwrap .contactinfo .ant-avatar.ant-avatar-circle > img { width: 80px;  height: 80px;}
.namecolwrap .contactinfo .ant-avatar.ant-avatar-circle {flex:0 0 80px; background: #FFF; color: #D9D9D9; border: 1px solid #D9D9D9;}
.leadswrap .listinginfowrap.listingagentwrap .ant-avatar.ant-avatar-circle > img {width:40px !important; height:40px !important; border-radius: 10px; box-shadow: 1px 1px 10px lightgray;}
.leadswrap .listinginfowrap.listingagentwrap .agentwrap {align-items: start;}
/* .leadswrap .ant-table-wrapper .ant-table-tbody>tr>td.ant-table-cell.actionwrap {border-left: 1px solid hsla(0,0%,8%,.16)!important;} */
.leadswrap .actionwrap .btn.btn-text {font-size: 14px;}
.namecolwrap .contactinfo .ant-avatar.ant-avatar-circle i.bi.bi-person-bounding-box {display: flex;  justify-content: center; align-items: center; height: 100%;font-size: 2rem;}
.filterwrap .ant-checkbox-group label.ant-checkbox-wrapper{transition:0.3s ease-in-out; padding-right:0; position:relative}
.filterwrap .ant-checkbox-group label.ant-checkbox-wrapper-checked { position: relative; padding-right: 12px;}
.leadswrap .filterwrap .ant-checkbox-group .ant-checkbox-wrapper.ant-checkbox-wrapper-checked span:last-child:after { content: "\F623"; font-family: bootstrap-icons; margin-left: 2px;  position: absolute; right: 0;}

.leadswrap .agentwrap span.ant-avatar.ant-avatar-circle { flex: 0 0 40px; border-radius: 10px; box-shadow: 1px 1px 10px lightgray;}

.leadswrap .ant-table-wrapper .ant-table .ant-table-tbody >tr>td.requirements {border-left:1px solid rgba(21,21,21,0.16)}
.leadswrap .ant-spin-container .ant-pagination.ant-table-pagination { gap: 0.2rem; border: 1px solid rgba(21,21,21,0.16); 
   padding: 0;display: flex;justify-content: start; background-color: #FFF; border-radius: 5px;  box-shadow: none;}
.leadswrap .ant-pagination li.ant-pagination-options { margin-left: auto;}
.leadswrap .ant-pagination .ant-pagination-options .ant-select.ant-pagination-options-size-changer .ant-select-selector { border: none; border-left: 1px solid rgba(21,21,21,0.16); border-radius: 0;height: 100% !important; margin-top: 0 !important;}
.leadswrap .ant-pagination .ant-select .ant-select-arrow { top: 50%; right: 5px;}
.leadswrap .ant-pagination .ant-pagination-total-text {height: 36px !important; padding: 3px 1rem;}
.leadswrap .ant-pagination li:not(:first-child):not(:last-child) { align-self:center}
.leadswrap .ant-pagination li:nth-child(2) {position:relative;}
.leadswrap .ant-input-affix-wrapper input.ant-input:placeholder-shown {background-color:transparent}
.leadswrap input.ant-input {font-family: 'Poppins-Regular', sans-serif;}
 
.filterbg {background: #F9FAFB;}
.filterwrap .ant-checkbox-group .ant-checkbox-wrapper:after {content:''}
.resetfilter {border-left:1px solid rgba(21,21,21,0.16)}

.leadswrap .ant-segmented-item {transition: #151515 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);border-radius: 0;    height: 36px; align-items: center; display: flex;}
.leadswrap .ant-segmented-item-selected {background-color: #151515; color:#FFF}
.leadswrap .ant-segmented-thumb {background-color: #151515;color:#FFF}
.leadswrap .ant-segmented { border-radius: 0;background:none; padding:0}
.leadswrap .ant-segmented .ant-segmented-group {justify-content:space-between}
.addleadbtn.ant-btn span, .addleadbtn.ant-btn i { font-size: 12px;}
.leadswrap button.ant-btn.addleadbtn { display: flex; gap: 0.3rem;border-radius:0; background:#151515; color:#FFF}
.leadswrap .ant-btn .icongroup {padding:0}

.ant-spin-dot-item {background-color: #151515 !important;}
button.ant-btn-primary.addnewbtn {background: #151515; color: #FFF; margin-left:auto}

/* Filters leads */
.filterpopup .ant-popover-title { border-bottom: 1px solid rgba(21,21,21,0.06);margin-bottom: 0; padding: 10px 1rem;}
.ant-popover.filterpopup .ant-btn span {font-size: 14px;}
.ant-popover.filterpopup .ant-btn {text-align: left; margin: 0.5rem 0 0; display: flex;  height: 27px; border-radius:0; align-items: center; gap: 0.4rem;}
.filterpopovergrp {display:flex; flex-direction:row; gap:0.6rem; justify-content: end}
.filterpopovergrp .ant-btn {border-radius:0 !important}
.ant-popover.filterpopup-btn .ant-radio-group span {font-size:14px; }
.ant-popover.filterpopup-team .ant-popover-inner-content { display: flex;flex-direction: column; padding: 0;}
.ant-popover label.ant-radio-wrapper.ant-radio-wrapper-checked {background: rgba(21,21,21,0.06); padding: 10px;  margin: 0;}
.ant-popover .ant-popover-inner {padding: 0 0 1rem 0;overflow: hidden;}
.filterpopup-team .ant-popover-inner-content .ant-radio-group label {padding: 10px;}
.leadswrap .filtertaggrp {display: flex; flex-direction: row; gap: 0.6rem; margin: 0;height:0; transition:all 0.4s ease-in-out}
.leadswrap .filtertaggrp.filteryes {margin-top:0.7rem; height:25px;align-items:center; background:#FFF}
.filtertaggrp .ant-tag {  font-size: 12px; font-family: 'Poppins-Regular', sans-serif; align-items: center; display: flex; gap: 0.3rem;height: 27px; border-color: rgba(21,21,21,0.16);color: #151515; border-radius:0}
.ant-popover.fp-content .ant-popover-inner-content {padding: 0.4rem 1rem 1rem;}
.ant-popover .ant-select.ant-select-show-search .ant-select-selector { max-width: 20rem;height: fit-content;}
.ant-table-content {border-radius: 10px;}
.ant-popover .ant-select-multiple .ant-select-selection-item-content { font-size: 12px; height: 100%;}
.ant-popover .ant-select.ant-select-show-search .ant-select-selector .ant-select-selection-item {height: 25px;line-height: 23px !important;}
.ant-popover .ant-select-multiple .ant-select-selection-item-remove >.anticon {vertical-align: 0;}
.ant-btn.reset {margin-left:auto;justify-content:end}
.ant-btn.reset span{font-size:10px;}

.ant-popover.filterpopup {width: 14rem;}
.ant-popover.filterpopup.filterpopup-req {min-width:20rem}
.ant-popover.filterpopup-req .ant-select.ant-select-show-search .ant-select-selector .ant-select-selection-item {height: 26px;line-height: 30px !important; font-size: 12px;}
.ant-popover .reqgrp {margin-top:1rem;}

/* All Leads Detail Page */

.leads-details-section {
    border-radius: 5px;
    border:1px solid rgba(21,21,21,0.16);
    box-shadow:0 2px 0 rgba(5, 145, 255, 0.1);
}

.lead-section-wrapper {
    padding: 20px;
}

.ant-modal-content .ant-modal-header .ant-modal-title {
    font-size: 16px;
    font-weight: 600;
    text-transform: capitalize;
    font-family: unset;
}

.separator-line {
    width: 1px;
    height: auto;
    background-color: #ccc;
    margin-top: 5%;
  }

  .card-container {
    display: flex;
    align-items: flex-start;
    margin-bottom: 5%;
    position: relative;
  }
  
  .card-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 1px;
    height: 100%;
    background-color: #ccc;
  }

  .icon-container {
    margin-top: 15px;
    display: flex;
    align-items: center;
  }

  .icon-size {
    font-size: 25px;
  }

  .noimageyetleadsedit {background-color: #FFF;display: flex; flex-direction: column; margin:0; text-align: center; height:120px;justify-content:center; border:1px solid rgba(21,21,21,0.16); width:190px}

  .social-media-icons {
    height: 20px;
    margin-right: 10px;
  }

  .divider {
    border-right: 2px solid lightgray;
  }

  .circle {
    width: 10px;
    height: 10px;
    background-color: blue;
    border-radius: 50%;
  }

  

  .requirements-styling { 
    font-size: 14px !important;
    margin-right: 25px;
    font-weight: 600 !important;
    width: 35%;
  }

  .ant-avatar > img {
    width: 50px;
    height: 50px;
    border-radius: 10%;
  }

  .match-property-image { 
    height: 130px !important;
  }

  .icon-container-styling {
    display: flex;
    align-content: space-around;
    margin-top: 5px;
  }

  .my-carousel { 
    width: 102%;
    display: flex;
    justify-content: center;
  }

  .carousel-root {
    width: 96%;
  }

  .client-profile img{
    height: 100% !important;
    width: 100% !important;
  }

  .user_profile_image > img {
    width: 100% !important;
    height: 100% !important;
  }

  .adesc {
    border: none;
  }
  
  .ant-tag.updated {
    background-color: rgba(37,211,102,.16);
    border-color: #25d366;
    color: #25d366;
}

.ant-tag {
  font-family: Poppins-Regular,sans-serif;
  font-size: 11px;
}

.messagewrap {
  background-color: #f6f6f9;
  border: 1px solid #f6f6f9;
  border-radius: 0;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-bottom: 10px;
  margin-left: 20px;
  margin-top: 5px;
  padding: 10px;
  position: relative;
  text-align: left;
  width: 100%;
}

.pstat {
  width: fit-content !important;
}

.messagewrap {
margin-bottom: 0;
margin-top: 0;
}

.messagewrap p {
  font-size: 12px;
}

.control-dots{
  display: none;
}

.listinginfowrap {
  height: 100%;
}

.social-media-link {
  text-decoration: none;
  color: black;
}
 .leads-details-section .ant-card-body {
  padding-top: 10px;
 }

 #calllogbtn {
  width: 90%;
  justify-content: center;
  margin-right: 10px;
 }

 .activitybtngrp {
  display: flex;
 }

 .social-media-link img{
height: 35px;
width: 35px;
 }

 .note-crousal .carousel-root {
  width: 70%;
  height: 100px;
 }

 .note-crousal {
  display: flex;
  gap: 10px
 }

 .taggedreply .ant-avatar {
  width: 55px !important;
 }

 .taggedreply {
  gap: 8px;
 }

 .navigation-container {
  margin-top: 10px;
  margin-right: 20px;
  margin-left: 20px;
  margin-bottom: -20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between; 
 }

 .tooltip {
  position: relative;
  display: contents;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  padding-right: 8px;
  margin-left: 100px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}



.lp_details.note-crousal .carousel-root {height:unset;}
.lp_details.note-crousal .carousel .thumbs-wrapper {display: none;}
.ant-segmented .ant-segmented-item-icon+* { font-size: 12px;}

@media screen and (max-width:1450px) {
    .filterbg .ant-col.ant-col-lg-9.ant-col-xl-10 { flex: 0 0 39%; max-width: 39% !important;}
    .leadswrap .tableleads table .ant-table-tbody>tr>td.created {  width: 150px;}
    .leadswrap .tableleads table .ant-table-tbody>tr>td.match { width: 150px;}
    .leads-details-section .ant-avatar.ant-avatar-icon.client-profile {width: 100px !important;height: 100px !important;font-size: 3rem !important; line-height: 100px !important;}
    .leads-details-section .ant-col.ant-col-18 { max-width: 71%;}
}
@media screen and (max-width:1366px) {
    .filterbg .ant-col.ant-col-lg-9.ant-col-xl-10 { flex: 0 0 33%; max-width: 33% !important;}

    .leadswrap .tableleads table .ant-table-tbody>tr>td.created {  width: 140px;}
    .leadswrap .tableleads table .ant-table-tbody>tr>td.match { width: 140px;}
    .leads-details-section .easy-edit-wrapper {font-size:12px}
}

.visit-logs .ant-pagination-options {
  display: none;
}

.timeline-styling {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.status-styling {
  width: 100% !important;
}

.note-styling p {
  text-align: justify;
}

@media screen and (max-width:1280px) {
    
}


/* Advanced FIlters */
.advfilterswrap .ant-col .lblfilter {font-family: 'Poppins-Semibold', sans-serif; font-size:11px;}
.advfilterswrap .ant-col input.form-control, .advfilterswrap .ant-col select.form-select { height: 31px; font-size: 12px; border:none; border-left:1px solid rgba(21,21,21,0.16); border-radius:0;}
.advfilterswrap .filterdate { background:#FFF;border-radius:5px; border:1px solid rgba(21,21,21,0.16); justify-content:space-between; align-items:center; overflow:hidden }
.advfilterswrap .filterdate .form-check {min-height:unset; }
.advfilterswrap .filterdate label { font-size:12px; padding-left:5px; white-space:nowrap}
.advfilterswrap .ant-select.ant-select-show-search .ant-select-selector {margin: -3px 0; height:33px }
.advfilterswrap .ant-select.ant-select-show-search .ant-select-selector .ant-select-selection-item {line-height:24px !important; height:25px; font-size:12px}
.advfilterswrap .filterselect { border: 1px solid rgba(21,21,21,0.16);border-radius: 5px; background: #FFF; overflow: hidden;}
.advfilterswrap .filterselect .ant-select-selector {border:none; border-radius:0; font-size:12px; }
.advfilterswrap .filterdate .form-check .form-check-input {  margin-left: -14px;}

.viewasicongroup {display:flex; flex-direction:column}
.advfilterswrap .ant-select-selector .ant-select-selection-placeholder {font-size:12px}
.leadswrap .ant-segmented .ant-segmented-group {justify-content: space-between; display: flex; flex-direction: column; gap:1.5rem}
.ant-segmented .ant-segmented-item-label {text-overflow: unset; margin:0 auto}
.leadswrap .ant-segmented .ant-segmented-group .ant-segmented-item-label > span:last-child{display:none}
.advfilterswrap .ant-col {align-self:baseline}



.ant-modal div.ant-modal-body {background:#FFF; padding: 5px;}

.followupforgrp {display:flex; flex-direction: row; gap:0.6rem; justify-content: space-between}
.followupforgrp label {width: fit-content; border-radius: 4px; white-space: nowrap; overflow: hidden; border:1px solid #d9d9d9}
.ant-select.ant-select-show-search .ant-select-selector .ant-select-selection-item {height:36px !important;}
.ant-modal-body .ant-select.ant-select-show-search .ant-select-selector {height:36px !important; position: relative; line-height:36px}
.followupleadstimeline .timeviewing span.ant-avatar {width:50px; height:35px !important;}
.followupleadstimeline .messagewrap {margin-left:10px !important; }



.addwrapper{width:100%;height:36px;position:relative;z-index:1}
.addwrapper .circle{width:8px;height:8px;position:absolute;border-radius:50%;background-color:#fff;left:15%;transform-origin:50%;animation:circle7124 .5s alternate infinite ease}
@keyframes circle7124 {
0%{top:20px;height:5px;border-radius:50px 50px 25px 25px;transform:scaleX(1.3)}
40%{height:10px;border-radius:50%;transform:scaleX(1)}
100%{top:0}
}
.addwrapper .circle:nth-child(2){left:45%;animation-delay:.2s}
.addwrapper .circle:nth-child(3){left:auto;right:15%;animation-delay:.3s}
.shadow{width:8px;height:4px;border-radius:50%;background-color:rgba(0,0,0,0.9);position:absolute;top:10px;transform-origin:50%;z-index:-1;left:15%;filter:blur(1px);animation:shadow046 .5s alternate infinite ease}
@keyframes shadow046 {
0%{transform:scaleX(1.5)}
40%{transform:scaleX(1);opacity:.7}
100%{transform:scaleX(.2);opacity:.4}
}
.addwrapper .shadow:nth-child(4){left:45%;animation-delay:.2s}
.addwrapper .shadow:nth-child(5){left:auto;right:15%;animation-delay:.3s}

.btn.btnAccept {background: #25D366; color:#FFF; width:50%; margin:0}
.btn.btnAccept:hover {background: #25D366; color:#FFF; border-color:#25D366}

.btn.btnReject {background: #ff0000; color:#FFF;width:50%; margin:0}
.btn.btnReject:hover {background: #FFF; color:#ff0000}

/* PENDING LEADS */
.pendingleadswrap {width:100%; border-top:1px solid rgba(21,21,21,0.06) }
.pendingleadswrap p { white-space:nowrap}
.pendingLeadsTable { padding: 0 1rem}
.pendingLeadsTable .btn {height:36px; border-radius:0; border: 1px solid rgba(21,21,21,0.16); background:#FFF; color:#151515; padding:0 2rem; opacity:1; }
.pendingLeadsTable .ant-table-cell {vertical-align:top !important}
.pendingLeadsTable .ant-table-row {border-bottom:1px solid rgba(21,21,21,0.16)}
.pendingLeadsTable .ant-table-row:last-child {border-bottom:none}
.pendingLeadsTable .btn.btnAccept {background: #25D366; color:#FFF; width:50%; margin-top:0.5rem}
.pendingLeadsTable .btn.btnAccept:hover {background: #25D366; color:#FFF; border-color:#25D366}
.pendingLeadsTable .btnSelectReassign {margin-top:0.5rem}
.pendingLeadsTable .btnSelectReassign .ant-select-selector {border-radius:0; height:36px}
.pendingLeadsTable .ant-statistic-content-value {color:#095EDD; font-family: 'Poppins-Semibold', sans-serif}
.pendingLeadsTable.ant-table-wrapper .ant-table-tbody > tr > td { border-bottom:1px solid rgba(21,21,21,0.16) !important}
.pendingLeadsTable.ant-table-wrapper .ant-table-content table {border:none !important}
.pendingLeadsTable .ant-statistic {margin-bottom:-10px}
.pendingLeadsTable .ant-statistic .ant-statistic-content-value-int{font-family:'Poppins-Semibold', sans-serif; font-size:21px}
.pendingLeadsTable .lblStatistics {font-size:10px; text-transform:capitalize;color:#095EDD;}
.pendingleadswrap .ant-select.ant-select-show-search .ant-select-selector {margin-top:0; font-size:14px}
.pendingleadswrap .ant-select.ant-select-show-search .ant-select-selector .ant-select-selection-item {font-size:12px; line-height: 40px !important;}
.filterwrap .statPendingLeadbtn {width: fit-content; float: left; display: flex; border: none !important; padding: 0; margin: 0; height: 23px; margin:0 0.5rem; position:relative; line-height: 1;transition: all 0.3s ease-in-out;}
.filterwrap .statPendingLeadbtn span {font-size: 12px;color: #FF1744;    padding-bottom: 5px; transition: all 0.3s ease-in-out;}

.filterwrap .statPendingLeadbtn::after { height: 2px; position: absolute; width: 0; bottom: -2px; transition: all 0.3s ease-in-out; content:''}
.filterwrap .statPendingLeadbtn.showPending::after {width:100%; background-color: #FF1744;}
.filterwrap .statPendingLeadbtn div {display:none}
.filterwrap .statPendingLeadbtn.showPending span {padding-right:1.2rem;}
.filterwrap .statPendingLeadbtn.showPending span::after {content: "\F623"; font-family: bootstrap-icons; margin-left: 2px;  position: absolute; right: 0;}
.pendingLeadsTable .listinginfowrap {height:unset}

.blur-row .ant-table-cell, .leads-details-section.blur-row .cdets{
    filter: blur(6.5px); /* Add a blur effect to the row */
  }
  .blur-row { position:relative     }
.acceptDeclineWrap {display:none}
.blur-row .acceptDeclineWrap {position:absolute !important;top: 0 ;left: 0;  filter: none; z-index: 99; width: 100%; height: 100%; display:block}
.acceptDeclineWrap .d-flex {gap: 0.6rem; width: 99.5%; height: 99.95%;  border: 1px solid rgba(21,21,21,0.06); justify-content: center;margin: 0 auto;}
.blur-row .acceptDeclineWrap .btnAccept, .blurContact .btnAccept {background:#25D366; color: #FFF; padding:4px 1.5rem; border:1px solid #25D366; height:36px !important; opacity:1; margin:0}
.blur-row .acceptDeclineWrap .btnAccept:hover, .blurContact .btnAccept:hover { background:#FFF; color:#25D366}
.blur-row .acceptDeclineWrap .btnDecline, .blurContact .btnDecline {background:#FFF; color: #151515; border:1px solid rgba(21,21,21,0.16); height:36px !important; padding:4px 1.5rem; opacity:1; margin:0}
.blur-row .acceptDeclineWrap .btnDecline:hover, .blurContact .btnDecline:hover {background:#151515; color: #FFF;}
.blurContact .reassignbtn {height:36px; max-width:70%}
.blurContact  {position: absolute; top:0; left:0; height:100%; width:100%; display:flex;z-index:99; justify-content:center; align-items:center; gap:0.3rem ; flex-direction: column; padding-left:1rem; padding-right:1rem}
.blurContact .d-flex {gap:0.3rem; width:70%}
.blurContact .d-flex > button.ant-btn {width:50%; }












.otherinquiries .ant-card-body {padding-left:5px; padding-right:5px}
.ant-card-head-title {font-family: "Poppins-Bold", sans-serif !important;}

@media(max-width:768px) {
  .followupforgrp {display:flex; flex-direction: row; gap:0.6rem; justify-content:unset;
    flex-wrap: wrap;
  
  }
  .leadswrap .tableleads table .ant-table-tbody>tr>td{
    border-bottom: none !important;
  }
  /* .ModelHeight{
    height: 550px;
    overflow: auto;
    border-radius: 10px;
   } */
   .note-crousal {
flex-direction: column;
align-items: center;
  }
  .note-crousal .carousel-root {
    width: 100%;
   }

}
@media(max-width: 425px){
  .activitybtngrp {
    flex-wrap: wrap;
  }
  #calllogbtn {
    width: 100% ;
   }

   .fullsizeButton{
    width: 100% !important;
   }


}


.customCardDesign{background: #FFF; border:1px solid rgba(21,21,21,0.06); border-radius: 5px; padding: 5px; margin: 0 auto; box-shadow:0 0 5px rgba(21,21,21,0.16); width:95%;}
.flexBaseBtween{
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
.flexCenterBtween{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flexCenterBtween .listinginfowrap, .flexBaseBtween .listinginfowrap{
  height: 100%;
}
.width-110{
  width: 110px;
}

.width-100{
  max-width: 100% !important;
  width: 100% !important;
  min-width: 100% !important;
}

.TitleInput,
.custom-file-input-wrapper {
  margin-top: 11px;
}

.custom-file-input-wrapper {
  position: relative;
  width: 100%;
  display: inline-block;
  margin-top: 11px;
}

.custom-file-input {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.custom-file-label {
  display: inline-block;
  padding: 8px 16px;
  background-color: #4f5357;
  color: white;
  text-align: center;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
}

.custom-file-label:hover {
  background-color: #4f5357;
}

.custom-file-input:focus+.custom-file-label {
  outline: 2px solid #4f5357;
}

.leadsfilterwrap .lblfilter {font-size:12px; font-family:'Poppins-Bold', sans-serif}
.leadsfilterwrap .ant-card-body {padding:5px 10px 10px}
.leadsfilterwrap .ant-card-head {padding: 0; overflow: hidden; margin: 0; min-height: 0;}
.leadsfilterwrap .propforFilter .ant-radio-group {gap:0; flex-direction:row;overflow: hidden;}
.leadsfilterwrap .propforFilter .ant-radio-group .ant-radio-button-wrapper {height:46px; line-height:41px}
.leadsfilterwrap .propforFilter .ant-radio-group .ant-radio-button-wrapper span {font-size:14px; padding: 0 1rem;;}

.leadsfilterwrap .ant-select-selection-placeholder {color:#151515; opacity:1}
.leadsfilterwrap .searchbtn {background:#25d366; color:#FFF; border:1px solid #25d366}
.leadsfilterwrap .searchbtn:hover {background:#25d366 !important; opacity:0.8; border:1px solid #25d366 !important}
.leadsfilterwrap input.ant-input:placeholder-shown {color:#151515}
.leadsfilterwrap .ant-select-selector {border-radius:0}
.leadsfilterwrap .contacttypefilt .ant-select-selector {height:46px; border-radius:0}
.leadsfilterwrap input.ant-input::placeholder { color: #151515 !important; font-size:13px}
.leadsfilterwrap .filtersearchwrap .ant-select {border-left:1px solid #FFF;}
.leadsfilterwrap .filtersearchwrap .propertyTypewrap .ant-select-selector {height:46px; border-radius:0 5px 5px 0}
.leadsfilterwrap .leadsfilters-cont .ant-select, .leadsfilterwrap .leadsfilters-cont .ant-select-selector {height:46px; border-radius:0; border-left:1px solid #FFF}
.leadsfilterwrap .leadsfilters-cont .filter-community .ant-select-selector{border-left:1px solid rgba(21,21,21,0.16); border-radius:5px 0 0 5px}
.leadsfilterwrap input.ant-select-selection-search-input, .leadsfilterwrap .ant-select-selection-placeholder {font-size:13px}
button.ant-btn.pondsbtn {box-shadow: none; background:#FFF; border-color:red}
button.ant-btn.pondsbtn span, button.ant-btn.pondsbtn i {font-size: 13px; color: red;}
button.ant-btn.pondsbtn:hover {background:red !important}
button.ant-btn.pondsbtn:hover span, button.ant-btn.pondsbtn:hover i {color:#FFF} 
.leadsfilterwrap .ant-input-affix-wrapper .ant-input-prefix {margin-inline-end: 10px;}
.leadsfilterwrap .dateInquiry .ant-select-selector {padding-left:2rem}
.leadsfilterwrap .agentwrapfilter .ant-select-selector {border-left:1px solid rgba(21,21,21,0.16); border-radius:5px}
.leadsfilterwrap .ant-select .ant-select-clear .close-circle {top:0; right:0; position:absolute}
.leadsfilterwrap .filterselect .ant-btn {height:46px; border-radius:0; width:100%; margin-top:0; text-align:left; border-left: 1px solid #FFF; justify-content:left }

.bedroomfilter .ant-radio-group label {border-radius: 0 !important; width: 50px; text-align: center;}
.bedroomfilter .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {background: #151515; border-color: #151515; }
.bedroomfilter :where(.css-dev-only-do-not-override-d2lrxs).ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {background-color:#151515}
.bedroomfilter .ant-radio-group {display: flex; gap: 0.8rem; margin:5px 0 1rem}
.bedroomfilter .btndone, .pricefiltergrp .btndone {height:36px; background:#151515; color:#FFF}
.bedroomfilter .btnreset, .pricefiltergrp .btnreset {border:none; height:36px; padding:4px 0}
.pricefiltergrp .pricefilt { border:1px solid rgba(21,21,21,0.16); height:46px; border-radius:4px; overflow:hidden; margin-bottom:5px}
.leadsfilterwrap .propforFilter .ant-radio-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {background:#151515; border-color:#151515}
.leadsfilterwrap .ant-select span.ant-select-clear {transform: translateY(-50%);}
.leadsfilterwrap button.ant-btn.ant-btn-default:hover { border-color: #151515 !important; color: #FFF !important; background: #151515 !important;}
.leadsfilterwrap .totalpond {font-size:2rem}
.leadsfilterwrap .propforFilter .ant-radio-group :where(.css-dev-only-do-not-override-d2lrxs).ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: transparent;
    width: 100%;
    height: 91%;
    border: 2px solid rgb(37, 211, 102) !important;
}


.leadsfilterwrap .resetallbtn {background:#fb6107; height:46px; }
.leadsfilterwrap .resetallbtn:hover {background:#f24c00 !important}
.leadsfilterwrap .resetallbtn span, .leadsfilterwrap .resetallbtn i {color:#FFF}


.tableleads .ant-checkbox-checked .ant-checkbox-inner:after { top: 0; left: 1px; width: 7px; border: none; content: '\F26E';font-family: 'bootstrap-icons'; transform: translateY(-34%); height: fit-content; color: #FFF;}

@media screen and (max-width:1024px) {
    .leadswrap .tableleads table .ant-table-tbody>tr>td:nth-last-child(2) {  display:none }
    div.leadswrap .tableleads table .ant-table-tbody>tr>td {border: none !important; padding:0 0 0 8px !important}
    div.leadswrap .tableleads table .ant-table-tbody>tr>td:nth-last-child(2) {padding:0 !important}
    .ant-table-wrapper.tableleads, .ant-table-wrapper.viewingtable {padding:0}
   .leadswrap .tableleads table .ant-table-tbody>tr>td .customCardDesign p.cname {font-size:16px}
   .leadswrap .tableleads .ant-table-wrapper .ant-table-tbody > tr > td.ant-table-cell:nth-child(2) {padding-left: 0 !important;}
   .leadswrap .tableleads table .ant-table-tbody>tr>td p.info-title {font-size:12px}
   .leadswrap .applyfiltersbtn span {font-size:14px;}
   .leadswrap .applyfiltersbtn {margin-top:0; background:#151515; color:#FFF; height:46px;}
   .leadswrap .applyfiltersbtn i {color:#FFF; font-size:16px}

    #root {overflow:hidden}
    .drawermobilefilter .ant-radio-group{width:100%}
    .drawermobilefilter .ant-radio-group label {width:33.33%; height:36px; text-align:center; line-height:33px;}
    .drawermobilefilter .ant-radio-group label.ant-radio-button-wrapper.ant-radio-button-wrapper-checked {background:#151515 !important; border-color:#151515!important}
    .drawermobilefilter .advfilterswrap .ant-select.ant-select-show-search .ant-select-selector {height: 46px;}

    .drawermobilefilter .searchbtn {margin:0; background:#151515; color:#FFF; height:46px; }
    .ant-modal-root .ant-modal.bottom-slide-modal { bottom:-1.5rem; top: unset; max-width:100%; position:fixed;} 
    .bottom-slide-modal .ant-checkbox-group span.ant-checkbox.ant-wave-target {display: none;}
    .bottom-slide-modal .ant-checkbox-group label.ant-checkbox-wrapper {height: 46px;border: 1px solid rgba(21, 21, 21, 0.16); line-height: 46px;border-radius: 5px;margin: 6px 5px; width: 29.6%;justify-content: center;}
    .bottom-slide-modal .ant-checkbox-group label.ant-checkbox-wrapper span {font-size:12px}
    .bottom-slide-modal .ant-checkbox-group label.ant-checkbox-wrapper.ant-checkbox-wrapper-checked {background:#151515; color:#FFF}
    .bottom-slide-modal .btndone { height:36px;background:#151515;}
    .bottom-slide-modal .btndone span{font-size:14px; color:#FFF}
    .bottom-slide-modal .btnreset span{font-size:14px;}
    .drawermobilefilter .advfilterswrap .ant-btn span { font-size: 14px;}
    .drawermobilefilter .advfilterswrap .ant-btn {justify-content:flex-start}
    .filteradvwrap .ant-btn span {font-size:14px}

    .bottom-slide-modal.proptypemodal .ant-checkbox-group label.ant-checkbox-wrapper {width:fit-content; min-width: 29.6%}
    .drawermobilefilter .advfilterswrap .ant-btn.btnWrap { width: 100%; margin: 0;height: 46px;}
    .drawermobilefilter .advfilterswrap .mobfilterwrap .filterselect{border:none}

    .drawermobilefilter .ant-btn span { font-size: 14px;}
    .drawermobilefilter .searchresetbtn {margin:0; padding:0}
    .drawermobilefilter .pondswrap .pondsbtn span {font-size:12px;}
    .pondswrap button.ant-btn.pondsbtn {padding: 0;height:32px;margin-top:0.7rem !important}
   
}

.bottom-slide-modal .ant-modal { bottom: 0; margin: 0; position: fixed; width: 100%; max-width: 100%;  }
/* Slide in animation */
@keyframes slideInFromBottom {from {transform: translateY(100%);}to {transform: translateY(0);}}
.bottom-slide-modal .ant-modal-content {border-radius: 10px 10px 0 0;}


.ant-upload-wrapper{
  width: 100%;
}


.ant-upload-hint{
  margin-bottom: 0;
}

.ant-upload.ant-upload-btn{
  padding: 5px !important;
}

.ant-upload .ant-upload-text{
  font-size: 12px;
}

.ant-upload .ant-upload-hint{
  font-size: 10px;
}


.tabtimeline .timenote .adesc .lp_details .carousel-root {
    max-width: 300px;
    float: left;
    margin-right: 2rem;
}


@media screen and (max-width:768px) {
    .sideactions {display:none}

}