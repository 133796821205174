/* We can remove the border we've set at the beginning */
#gjs {
    border: none;
  }
  /* Theming */
  
  /* Primary color for the background */
  .gjs-pn-panels .gjs-one-bg { background-color: #151515; }
  .gjs-pn-panels .gjs-pn-panel.gjs-pn-views-container {width:20%}
  .gjs-pn-panels .gjs-block svg, .gjs-pn-btn svg {color:#FFF}
  .gjs-pn-panels .gjs-block-categories .gjs-one-bg {border:1px solid rgba(255,255,255,0.6)}
  .gjs-pn-panels .gjs-block .gjs-block__media {display:flex; position:relative; align-items:center; justify-content:center}
  .gjs-pn-panels .gjs-block .gjs-block__media i {font-size: 3rem; height: fit-content; display: flex;}
  .gjs-pn-panels .gjs-block .gjs-block__media svg {width:3rem}
  .gjs-editor .gjs-cv-canvas-bg {width:80%}

  /* Secondary color for the text color */
  .gjs-pn-panel .gjs-two-color { color: rgba(255, 255, 255, 1);}
  
  /* Tertiary color for the background */
  .gjs-three-bg {background-color: #151515; color: white;}
  
  /* Quaternary color for the text color */
  .gjs-four-color,.gjs-four-color-h:hover { color: #151515;}
  .gjs-block-categories .gjs-title {color:#FFF}
  

  .paragraph {line-height:1.8}
  .gjs-pn-panels .gjs-one-bg.gjs-pn-panel.gjs-pn-options {z-index:99}