
h1, h2, h3, h4,h5,h6 {font-family: 'Poppins-Regular', sans-serif}
h1 {font-size:26px; letter-spacing:3px; text-transform:uppercase}
.title.ant-typography {font-family: 'Poppins-Bold', sans-serif; font-size:21px; }

.border-t {border-top:1px solid rgba(21,21,21,0.06)}

.wrap {row-gap:2rem}
.form-group input, .form-group select, .form-group textarea {font-family: "Poppins-Regular", sans-serif !important; font-size:14px}

.border-l {border-left:1px solid rgba(21,21,21,0.16)}
.border-b {border-bottom:1px solid rgba(21,21,21,0.16)}

.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select {width: 100%; height: 100%; margin-bottom: 0; min-height:36px; align-items:center; justify-content:center}
.form-group.animatedtitle input {width:31.33%;}

.websettingwrap {width: 94%; margin: 2rem auto;box-shadow: 0 10px 25px rgba(21, 21, 21, 0.06);}
.websettingwrap .ant-card-head {background: rgba(21, 21, 21, 0.02);}
.websettingwrap .ant-menu-light .ant-menu-submenu-selected >.ant-menu-submenu-title {color: #151515;}
.websettingwrap .autocomplete {width:100%}
.websider .ant-menu {border: 1px solid #f0f0f0; border-radius:8px; overflow:hidden; }
.websider .ant-menu .ant-menu-item {border:none}
.websider .ant-menu.ant-menu-sub.ant-menu-inline {background:none}
.websider .ant-menu .websider .ant-menu {font-family: 'Poppins-Bold,' sans-serif; }
.ant-row.wrap.container .ant-select-selector {margin-top: 0;align-items: center;}
.ant-row.wrap .ant-select.ant-select-show-search .ant-select-selector .ant-select-selection-item {  line-height: 2.9 !important;text-transform: uppercase;}

.ant-btn.savebtn {background:#151515; color:#FFF}
.ant-btn.savebtn:hover {background:#FFF; color:#151515}
.mobwrap.ant-collapse .ant-collapse-item .ant-collapse-header {padding:0; cursor:pointer}
.floatbtn .ant-float-btn-icon {width:26px; font-size:26px}

.text-block {padding: 10px; border: 1px solid #ccc;}
.image-block img { max-width: 100%; height: auto; }
.form-block { display: flex; flex-direction: column; gap: 10px;}
.form-block input,.form-block button {padding: 10px;font-size: 16px;}
.websettingwrap .blogtable.ant-table-wrapper .ant-table-thead > tr > th, .websettingwrap .ant-table-wrapper .ant-table-tbody > tr > td.ant-table-cell { border-bottom: 1px solid rgba(21, 21, 21, 0.16) !important; padding: 1rem 0 !important; border-top: 1px solid rgba(21, 21, 21, 0.16) !important;}
.websettingwrap .blogtable.ant-table-wrapper .ant-table-thead > tr > th, .websettingwrap .ant-table-wrapper .ant-table-tbody > tr > td.ant-table-cell:first-child {padding:0 !important}
.websettingwrap .blogtable.ant-table-wrapper .ant-table-content table {border-spacing: 0 1.2rem;border-right: 0 !important;}
.websettingwrap .blogtable.ant-table-wrapper .ant-table-thead > tr > th:first-child, .websettingwrap .ant-table-wrapper .ant-table-tbody > tr > td.ant-table-cell:first-child { border-left: 1px solid rgba(21, 21, 21, 0.16) !important; }
.websettingwrap .blogtable.ant-table-wrapper .ant-table-thead > tr > th:last-child,.websettingwrap .ant-table-wrapper .ant-table-tbody > tr > td.ant-table-cell:last-child { border-right: 1px solid rgba(21, 21, 21, 0.16) !important; }
.websettingwrap .blogtable.ant-table-wrapper .ant-table-tbody > tr > td.ant-table-cell.actionwrap {border-left: 1px solid rgba(21, 21, 21, 0.16) !important; padding-left: 1rem !important; padding-right:1rem !important}
.websettingwrap .actionwrap button.ant-btn { padding: 0; line-height: 1.8; text-align: left; display: flex;justify-content: flex-start; }
.websettingwrap .blogtable .ant-table .ant-table-content tr {box-shadow: 0 0 3px #e5e5e5;}
.bloglistwrap a:hover {background:#151515 !important; color:#FFF !important}
.linkbtn span{font-size:12px}
.sticky { position: fixed; top: 0; left: 0; width: 100%; z-index: 1000;}

.websettingwrap .ant-card-actions {padding:0 24px; }
.websettingwrap .ant-card-actions li span {display:flex; justify-content:flex-end}

.websettingwrap .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item-container { width: 100%; min-height: 14rem;}
.websettingwrap .mobile-uploader { min-height:100%}
.websettingwrap .blogsection.ant-row.wrap .ant-select.ant-select-show-search .ant-select-selector .ant-select-selection-item {text-transform:unset}

@media screen and (max-width:768px) {

    .websettingwrap .headercard.ant-space {flex-direction:column !important; gap:0.6rem}
}

/* USERS */
.usermgnt-wrap .ant-table-wrapper.userTable .ant-table-content table { border-spacing:0 1rem; border-right:0 !important}
.usermgnt-wrap .userTable.ant-table-wrapper .ant-table-content tr td {border-top:1px solid rgba(21,21,21,0.16) !important;border-bottom:1px solid rgba(21,21,21,0.16) !important; }
.usermgnt-wrap .userTable.ant-table-wrapper .ant-table-content tr td:first-child{border-left:1px solid rgba(21,21,21,0.16) !important; padding:0 !important; border-top-left-radius: 4px; border-bottom-left-radius: 4px;}
.usermgnt-wrap .userTable.ant-table-wrapper .ant-table-content tr td:last-child{border-right:1px solid rgba(21,21,21,0.16) !important;border-left:1px solid rgba(21,21,21,0.16) !important;border-top-right-radius: 4px; border-bottom-right-radius: 4px;}
.usermgnt-wrap .ant-table-wrapper.userTable .ant-table-tbody > tr > td.ant-table-cell:nth-child(2) {text-transform: lowercase}
.usermgnt-wrap .ant-table-wrapper.userTable .ant-table-tbody > tr > td.ant-table-cell:nth-child(2) .capitalize {text-transform: capitalize}
.usermgnt-wrap .userTable.ant-table-wrapper .ant-table-content tr td:last-child.ant-table-cell.actionwrap {padding: 1rem 0 0 !important;}
.loginStyle, .loginStyle .ant-card-body {height:100%; padding:0 8px}

button.ant-pagination-item-link {margin-top:0}
.usermgnt-wrap .userTable.ant-table-wrapper .ant-table-content tr td:nth-last-child(2), .usermgnt-wrap .userTable.ant-table-wrapper .ant-table-content tr td:nth-last-child(3) {border-left:1px solid rgba(21,21,21,0.16) !important;}
.usermgnt-wrap .userTable.ant-table-wrapper .actionwrap button:hover {background:#151515; border-radius:0}
.usermgnt-wrap .userTable.ant-table-wrapper .actionwrap button:hover span {color:#FFF}
.usermgnt-wrap .userTable.ant-table-wrapper .actionwrap .info-title {padding:0 12px}

button.ant-drawer-close {margin: 0;}
.custom-upload-wrapper .ant-upload {position: relative;transition: all 0.3s ease; }  
.custom-upload-wrapper {position:relative; display:flex; min-height: 150px; width:100% }
.custom-upload-wrapper .upload-content {display: flex; flex-direction: column; align-items: center; position:absolute; top:50%; left:50%; transform:translate(-50%, -50%); z-index:3; opacity:0; transition:all 0.3s ease-in-out; height:100%; width:100%; background:rgba(21,21,21,0.16); cursor:pointer }
.custom-upload-wrapper .upload-icon { font-size: 24px;transition: transform 0.3s ease; color:#FFF}
.custom-upload-wrapper:hover .upload-content { opacity:1 }
  
.switchStyle {width:100%; height:36px; line-height:36px}
.switchStyle.ant-switch-checked {background:limegreen}
.switchStyle .ant-switch-inner .ant-switch-inner-unchecked {margin-top: 0;transform: translateY(-100%);}
.switchStyle.ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {background:darkgreen}
.switchStyle.ant-switch.ant-switch-checked .ant-switch-handle { transform: translateY(-50%); top: 50%; width: 25px;  height: 25px; border-radius: 50%; overflow: hidden; inset-inline-start: calc(100% - 30px);}
.switchStyle.ant-switch .ant-switch-handle { transform: translateY(-50%); top: 50%; width: 25px;  height: 25px; border-radius: 50%; overflow: hidden;inset-inline-start:5px}
.switchStyle .ant-switch-inner > span {font-size:14px}

.custom-upload-wrapper .ant-upload-wrapper.profile_photo {width:100%}
.loginStyle .ant-card-head {padding: 0 8px;}

.addtowebwrap{height:100%}
.addtowebwrap .ant-card-body {height:80%}
.removeuser {color:red; border:1px solid #FFF}
.removeuser:hover {border:1px solid red}

.editUserWrap .ant-select.ant-select-show-search .ant-select-selector .ant-select-selection-item {font-size:12px; line-height: 3 !important;}
.editUserWrap .form-group textarea {font-size:12px}

.gjs-editor p {font-family:'Proxima Nova', sans-serif}

.blogcontent {border: 1px solid rgba(21, 21, 21, 0.06);}

.reactquillwrap .ql-editor {min-height: 18em;}
.form-group input.error, .form-group .ant-select.error .ant-select-selector,  .form-group .ant-input-affix-wrapper.error {border:1px solid red;}
.form-group .ant-upload-wrapper.error .ant-upload {border-color:red}