/* Colors

primary : #151515
secondary : #8D8D8D
green : #25D366
red : #FF0000

*/
* {font-family: Poppins-Regular, sans-serif;}
.btn-success {
    --bs-btn-bg:#25D366;
    --bs-btn-border-color :#25D366;
}
.btn-primary {--bs-btn-hover-bg:#FFF; --bs-btn-bg:#fff; --bs-btn-border-color:rgba(21,21,21,0.16)}

.ant-table-content {overflow-x:scroll}
span.ant-menu-title-content {font-family: 'Poppins-Regular', sans-serif;}
h3 {font-family: Poppins-Bold, sans-serif;}



/* TOP */
.logo {margin:1rem 0; width:fit-content}


/* navbar */
.nav-link {font-size:0.875rem}
ul.ant-menu-overflow.ant-menu.ant-menu-root.ant-menu-horizontal.ant-menu-light {width: 100%;border-bottom: 1px solid rgba(5, 5, 5, 0.15);}



/* Listings */
/* Tabs */
.ant-tabs-tab.ant-tabs-tab-with-remove {font-weight:400}
.ant-tabs .ant-tabs-nav-wrap .tab-btn.ant-tabs-tab-active span {color: #FFF !important; opacity:1 !important}
.ant-tabs-card >.ant-tabs-nav .ant-tabs-tab {background: transparent;border: none;padding: 0 !important; min-width: 100px; justify-content: space-evenly; text-align: center;}
.ant-tabs-tab-btn span { text-transform: capitalize;font-size: 14px;font-family: 'Poppins-Regular';color: #151515;text-decoration: none;}
.ant-tabs-tab {padding: 5px 0.8rem !important;}
.ant-tabs-nav::before{display:none}
.ant-menu-item.ant-menu-item-active::after, .ant-menu-submenu:hover::after {border-bottom-color:#151515 !important}
.ant-tabs .ant-tabs-nav-wrap .ant-tabs-tab span {color: #151515; display: flex; align-items: center;}
.ant-tabs .ant-tabs-nav-wrap .ant-tabs-tab-active span { display:flex; align-items: center;}
.ant-tabs.ant-tabs-top .ant-tabs-nav {margin-bottom: 1rem; background: rgba(21,21,21,0.02);padding: 0 1rem;border-radius:5px;  height:50px;}
.ant-menu-light.ant-menu-horizontal > .ant-menu-submenu-selected span {color: #151515; font-family: "Poppins-Bold", sans-serif;}
.ant-tabs-tab.ant-tabs-tab-with-remove.ant-tabs-tab-active{position:relative}
.ant-tabs-tab.ant-tabs-tab-with-remove.ant-tabs-tab-active::after {content:""; width:100%; height:2px; background:#151515;display:block; position:absolute; bottom:0; left:0 }
.ant-tabs-tab.ant-tabs-tab-with-remove:hover::after {content:""; width:100%; height:2px; background:#151515;display:block; position:absolute; bottom:0; left:0; transition:all 0.3s ease-in-out }
.ant-tabs-nav-wrap .ant-tabs-nav-list {gap: 1rem;  }
.ant-tabs .ant-tabs-nav-wrap .ant-tabs-tab span.anticon {opacity: 0.3;}

.contentwrap .ant-space.ant-space-vertical, .contentwrap .ant-space.ant-space-vertical .ant-space, .contentwrap .ant-space.ant-space-vertical .ant-space .ant-space-item { width: 100%;}
.ant-table-content table {border:none !important}
th.ant-table-cell {border-start-start-radius: 0 !important;}
button.ant-btn-primary { background: #FFF; border: 1px solid rgba(21,21,21,0.20);color: #151515;display: flex;align-items: center;font-family: Poppins, sans-serif; height: 36px;}
button.ant-btn-primary:hover {background-color:#151515 !important; color:#FFF}
.ant-btn.ant-btn-primary.ant-btn-background-ghost { background: #FFF;border: 1px solid rgba(21,21,21,0.16) !important;color: #151515;display: flex;align-items: center;font-family: Poppins, sans-serif;   height: 36px;}
.ant-btn.ant-btn-primary.ant-btn-background-ghost:hover {color:#FFF}
.ant-table .ant-table-content tr{box-shadow: none;}
.ant-table .ant-table-thead > tr > th {border-bottom:none; background: rgba(21,21,21,0.06) !important;padding:9px 16px}
.ant-table-tbody a { color: #151515;text-decoration: none;}
.ant-table-wrapper .ant-table-thead > tr > th, .ant-table-wrapper .ant-table-tbody > tr > td {padding: 1rem 16px !important; border-bottom: none !important; border-width: 1px;border-color: rgba(21,21,21,0.04);font-family: Poppins-Regular, sans-serif; font-size:12px;color:#151515}
.ant-table-wrapper .ant-table-column-title {font-size: 12px;}
.tablelistwrap .ant-table.ant-table-bordered {box-shadow:0 0 1px rgba(21,21,21,0.26) !important; overflow: hidden;}
.ant-table-wrapper .ant-table-tbody > tr > td.ant-table-cell:nth-child(2) {text-transform: capitalize;}
.ant-layout .ant-layout-content.site-layout {padding: 0 !important; background: #FFF;}
.contentwrap { border-radius: 0.5rem; overflow: hidden;; background: url("../../../public/images/maskbg.png");}
.ant-tabs-tab.ant-tabs-tab-with-remove.ant-tabs-tab-active{position:relative; }
.ant-tabs-tab.ant-tabs-tab-with-remove.ant-tabs-tab-active .ant-tabs-tab-btn::after {display:none}
.ant-table-cell .ant-btn.btn.btn-link {color: #151515;}
.ant-table-wrapper .ant-table-content table { border-right: 1px solid rgba(21,21,21,0.16) !important;}
.ant-table-wrapper .ant-table-container:before {display:none}
.ant-table-cell .ant-btn.btn.btn-link:hover {border-color:rgba(21,21,21,0.16); background:rgba(21,21,21,0.16)}
.ant-pagination-options .ant-select-selector {height: 30px !important; display: flex; align-items: center;}
.ant-pagination-options .ant-select-selector span { font-family: Poppins, sans-serif; font-size: 14px; font-weight: 300;}
.ant-spin-container .ant-pagination.ant-table-pagination {gap: 0.2rem;}
.ant-spin-container .ant-pagination-item {height:30px !important}
.anticon.anticon-double-right.ant-pagination-item-link-icon { color: #151515 !important;}
.ant-pagination-options .ant-select:hover .ant-select-selector { border-color: rgba(21,21,21,0.16) !important;}
.orgroup hr {border-top: 1px solid #151515;margin: 0;}
.orgroup > .d-flex {align-items: center; gap:1rem;}
.orgroup p{font-weight:700; color:#151515;}
.ant-btn.addfilter {box-shadow:none; border-radius:5px;}
.ant-btn.addfilter span {color:rgba(21,21,21,0.70); font-size:14px;}
.ant-btn.addfilter:hover span {color:#FFF}
.ant-table-row .ant-table-cell:nth-child(3) { text-transform: capitalize;}
.ant-table .ant-table-thead > tr > .ant-table-cell { background: #F6F6F9 ; border: none;  }
.ant-table .ant-table-thead > tr > .ant-table-cell::before {display:none}
.ant-select.ant-select-single.ant-select-show-arrow {width: 100% !important;}
.advmatch .form-controls .ant-select .ant-select-selector {padding: 0;}
.advmatch .form-controls .ant-select .ant-select-selector .ant-select-selection-search {inset-inline-start: 0;width: 100%; height: 32px;}
.btn.btn-link.addbtn { font-size: 14px; padding: 5px 0; position:relative;}
.btn.btn-link.addbtn:hover::after { content:""; width:100%; height:2px; background:#151515;display:block; position:absolute; bottom:0; left:0; transition:all 0.3s ease-in-out}

/* .ant-space .ant-space-item {display: none;} */
.ant-space .ant-space-item:first-child{display:flex;}

/* Modal */
.ant-modal-content .ant-modal-header .ant-modal-title { font-weight: 700; font-family: "Poppins", sans-serif;  text-transform: uppercase; font-size: 1.2rem;}
.ant-modal-footer {justify-content: end;}

/* Add new listing */
.titleadd {font-size:26px; font-family:"Poppins-Bold", sans-serif; padding:0;}
.addwrap label {font-family: "Poppins", sans-serif;font-size:12px;margin-bottom:5px;}
.addwrap {box-shadow: 0 0 3px rgba(21,21,21,0.16); border: 1px solid rgba(21,21,21,0.16); border-radius: 0.3rem;}
.addwrap .form-group .ant-select .ant-select-selection-search-input { height: 46px !important;}
.addwrap .form-group .form-control, .addwrap .form-group .ant-select.ant-select-show-search { height: 46px !important;font-family: Poppins, sans-serif;}
.addwrap .ant-select-single .ant-select-selector {height: 46px; overflow: hidden;}
.ant-select:hover .ant-select-selector {border-color: #151515 !important;}
.addwrap .form-group .form-control:hover, .addwrap .form-group .form-control:focus { box-shadow: none; border: 1px solid #151515 !important;  }
.addwrap .form-control:focus {box-shadow: none; border: 1px solid #151515 !important;}
.addwrap .form-control { font-family: Poppins, sans-serif; font-size: 14px; height: 46px; line-height: 2.25;}
.ant-btn-primary.btnsave { background: #151515; color: #FFF; box-shadow: 0 0 1px #151515;}
.ant-drawer-title {font-family: Poppins, sans-serif;}
.ant-btn-primary.ant-btn-dangerous.ant-btn-background-ghost:not(:disabled):hover {color:#FFF}
.ant-select .ant-select-selection-search input {height: 100% !important; line-height: 100%; font-family: Poppins, sans-serif;}
.ant-select-selector .ant-select-selection-placeholder { font-family: Poppins, sans-serif; line-height: 46px !important; display: flex;}
.addwrap .form-select { height: 46px; font-family: Poppins, sans-serif; font-size: 14px; text-transform: capitalize;}
.addwrap .form-select:hover, .addwrap .form-select:focus {box-shadow: none; border-color: #151515;}
.addwrap h3.title {font-size: 21px; font-family: Poppins-Bold, sans-serif;}
.changemode {display:none}

/* Edit Listing */
.border-top {border-top:5px solid #151515 !important;}
.btn.btn-primary.requestchange {font-size: 10px;height: 26px;}
.cont-wrap {display:flex;  flex-direction:column;margin:1rem auto 2rem;}
.toptitle {display:flex; flex-direction:row;align-items: center; width:100%;justify-content: space-between;}
.column-wrap {flex-wrap: nowrap;width: 100%;display: flex;gap:0.6rem}
.column-wrap .col-md-3 > div, .column-wrap .col-md-6 > div {padding:1.5rem;border: 1px solid rgba(21,21,21,0.16); box-shadow: 0 0 4px rgba(21,21,21,0.06);}
.column-wrap h3 {font-size: 16px;color: #151515;margin:5px 0;font-weight:400; white-space:nowrap;font-family: Poppins-Bold, sans-serif }
.formfieldset {display:flex; flex-direction:column; gap:0.6rem}
.form-group {flex-direction:column; display:flex;}
.form-group label {font-size:0.73rem; display:flex; font-weight:normal; margin-bottom:5px;}
.form-group input, .form-group select {height: 36px; width: 100%;font-size: 12px; border: 1px solid #e3e3e3; background: #fff !important; border-radius:0.3rem}
hr {border-top: 1px dashed #e3e3e3;width: 100%;}
.page-title {width: fit-content;}
.listtable-wrap a {border-bottom:1px solid rgba(21,21,21,0.06); }
.listtable-wrap a:hover, .listtable-wrap a:focus {background-color:rgba(21,21,21,0.05)}
.listtable p {margin-bottom:0;}
.listtable .propfor {font-size: 10px; text-transform: uppercase; letter-spacing: 4px;}
.listtable .refno {font-size:16px; font-weight:bold}
.listtable .propadd, .listtable .community {font-size:12px;}
.val-wrap p{margin-bottom: 0;}
.btn.btn-primary.requestchange {color:#151515;}
.btn.btn-primary.requestchange:hover {border-color: #151515;}
.listtable-wrap { padding: 0;}
.border-wrap {border:1px solid #e3e3e3; border-radius: 0.3rem;}
.listtable {padding: 0;}
.agentprop .label {color: #8D8D8D; font-size: 0.7rem; text-transform: uppercase; letter-spacing: 3px; margin-bottom: 0;}
.listtable-wrap .col-4 {padding:0;}
button.btn.btn-primary { color: #151515; font-size: 14px;}
button.btn.btn-primary:hover {border-color:#151515}
.offers .form-group select {padding: 0 10px;}
.btn.btn-secondary { color: #151515; font-size: 12px; height: 36px;}
.form-group input:focus {border:1px solid #e3e3e3 !important}
.form-group input {padding:0 7px;}
.timeline-wrap .tab-content .tab-pane .input-group.uploadgrp { width: 50%; padding: 0 12px;  }
.requesttable, .requesttable .col-6 {padding:0;}

/* Match */
.matchfilter .form-group {display: flex; flex-direction: row;align-items: center; gap: 0.3rem;border-bottom: 1px solid rgba(21,21,21,0.16);}
.matchfilter .form-group .ant-select-selection-search-input:focus {border: none !important;}
.matchfilter .ant-select-selector { padding: 0 !important;}
.matchfilter .ant-select-selector .ant-select-selection-search {inset-inline-start: 0;inset-inline-end: 0;display: flex;align-items: baseline;}
.matchfilter .ant-select-selector .ant-select-selection-search input {font-family: "Poppins-Regular", sans-serif;}
.matchfilter .form-group label {white-space: nowrap;color:rgba(21,21,21,0.7); font-size:12px;}
.matchfilter .budget input {border: none; height:26px;}
.matchfilter .budget input:hover {border:none}
.matchfilter .form-group select { border: none; height: 26px !important;}
.matchfilter .form-group label {margin-bottom:0}
.matchfilter .ant-select.ant-select-single.ant-select-show-arrow {overflow: hidden;}
.macthfilter .form-group input:focus {border:none !important}
.matchfilter .ant-select-selector {border: none !important; height: 26px !important; margin: 0 !important;}
.matchfilter .ant-select-selector .ant-select-selection-item {font-size:12px;line-height: 26px;font-family: "Poppins-Semibold", sans-serif;}
.matchfilter .budget input:focus {border:none !important; font-family: "Poppins-Semibold", sans-serif;}
.matchfilter .btn.btn-dark.w-100 {font-size: 12px; height: 36px;}
.matchresults .col-9 { padding: 0;}

/* listingstats */
.stat {font-size:12px !important; gap:0.3rem; align-items:center; margin-left:auto; width:fit-content; display:flex}
.publishstat i, .publishstat span {color:#25D366}
.agentprop {margin-top:1.35rem; text-align:right}
.agentprop .name {font-size:12px; white-space: nowrap;margin-bottom:0; max-width: 100px; overflow: hidden;text-overflow: ellipsis;}

.btn.btn-link.viewall {font-size: 12px; padding-right: 0; text-decoration: none; color: #151515;}
.btn.btn-link.viewall:hover {color:#8a8a8a}

/* formupload group */
.uploadformgroup .form-group input[type="checkbox"]{width: 20px; height: 26px;position:relative; margin-left:auto; display:block}
.uploadformgroup.form-group select, .uploadformgroup .form-group input[type="datetime-local"] {width: 90%; float: left;}
.uploadformgroup .form-group { display:block}
.uploadformgroup .form-group input[type="checkbox"]:focus {box-shadow:none}
.uploadformgroup .form-group input[type="checkbox"]:checked {background-color: #0d6efd;border-color: #0d6efd;}

/* marketrequests */
.marketrequest .btn.btn-secondary {background-color:#F3F3F3; border:1px solid #F3F3F3;text-align: left;padding: 0 0.3rem; gap:0.3rem;display:flex; align-items: center;white-space: nowrap;width:49%; font-size:12px;height:36px;}
.marketrequest .btn.btn-secondary:hover {background-color:#151515;border-color:#151515;}
.marketrequest .btn.btn-secondary:hover i, .marketrequest .btn.btn-secondary:hover span {color:#FFF}
.marketrequest .btn.btn-secondary i {width:10px}
.marketrequest > div {gap:0.3rem}
.marketrequest .requestfor {font-size:0.73rem; text-transform: uppercase;}
.marketrequest p {margin-bottom: 0;}
.marketwrap {border-bottom:1px solid #F3F3F3}
.marketrequest .requesttable {width:100%;}
.marketrequest .requestname {font-size:0.8rem; font-weight: bold;white-space: nowrap;}
.marketrequest .requestdate {margin-bottom:3px;}
.marketrequest .requestdate, .marketrequest .requeststat {color:#8D8D8D; font-size:0.73rem}
.timeline-wrap .tab-pane .btn.btn-primary.requestchange {margin: 0 auto;  margin-top: 0px;display: flex;align-items: center;}

/* tabs */
.nav-tabs .nav-link {border:none; color:#151515; font-size:13px;text-transform: uppercase;}
.nav-tabs .nav-link.active {border: none;border-bottom: 2px solid #151515;font-weight: bold;}
.tab-content {padding: 1rem 0;}
.tab-content textarea {border: 1px solid #CACACA; border-radius: 0.3rem; width: 100%;  font-size: 12px;padding: 0.5rem;}
.tab-content textarea:focus-visible {border-color:rgba(21,21,21,0.80); outline: 2px solid rgba(21,21,21,0.30);}
.tab-content button.btn.btn-primary.requestchange {margin: 0 auto;margin-top: 0px; font-size: 12px; height: 36px;padding: 0 2rem;}
/* timeline */
.timeline-wrap {margin-bottom:1rem}
.timeline-list {display: flex; flex-direction: column;gap:1rem}
.timeline .nav-tabs { background:none; border: none;border-bottom: 1px solid rgba(21,21,21,0.16);}
.timeline .nav-tabs .nav-link.active {border: none;border-radius: 0;border-bottom: 2px solid #151515;}
.timegroup {display:flex; flex-direction:row; padding:0 1rem; width:100%; }
.agentwrap .namewrap {width:32px; height:32px; border-radius:50%; background:rgba(21,21,21,0.17); text-align:center; align-items:center}
.namewrap span {font-size:10px; font-weight:bold; color:#FFF}
.agentdetails {width:100%;}
.agentdetails p {font-size:12px;margin-bottom:0;}
.agentdetails .awriter {font-size:14px;font-family: "Poppins-Regular", sans-serif;color: #151515;}
.atime {color:rgba(21,21,21,0.30)}
.agentdetails .aclass {font-weight:700; margin-bottom:5px}
.adesc {font-size: 12px;border: 1px solid #D4D4D4;width: 100%; padding: 0.5rem; border-radius: 0.3rem;margin-top:5px;}
.adesc p {margin-bottom:0; color:#151515;}
.adesc p.price {color: rgba(21,21,21,0.4); font-size: 12px;}
.agentdetails .whocall {font-size:16px;}
.agentdetails .calldetails {display: flex; flex-direction:row; align-items:center; gap:0.6rem}
.timegroup .col-1 {position:relative}
.timegroup .col-1::before {display:flex;content:""; border-left:1px solid rgba(21,21,21,0.06); height:100%; width:1px; position:absolute; z-index:2; top:1.6rem; left:50%;}
.timeline-list .timegroup:last-child .col-1::before {display:none}
.timeline-wrap .nav.nav-tabs button.nav-link:first-child, .nav.nav-tabs button.nav-link:last-child {padding: 8px 0;}
.form-group .form-control-disabled {border: 1px solid rgba(21,21,21,0.16);padding: 5px; height: 38px;border-radius: 0.2rem;line-height: 27px;margin: 0;font-size: 12px;}
/* timeline icons */
.iconwrap {border-radius: 50%;height:25px; width:25px; display:flex; align-items:center; justify-content: center; position:relative; z-index:3; left: 50%;transform: translateX(-50%);}
.iconwrap i {color:#FFF; margin-right:0;font-size:11px;}
.timegroup.note .iconwrap {background: #151515;}
.timegroup.viewing .iconwrap {background-color: #F9C851;}
.timegroup.phone .iconwrap {background-color:#7734EA}
.timegroup.whatsapp .iconwrap {background-color:#25D366}
.timegroup.system .iconwrap {background-color:#151515}
.timegroup.email .iconwrap {background-color:#255FD3}
.timegroup.offer .iconwrap {background-color: #35B8E0;}
.tab-pane input {font-size:12px;}

/* Details */
.form-group {margin-bottom:1rem}
.form-group.price .btn.btn-primary.requestchange { font-size: 12px;margin: 0 0 0 auto;    margin-top: 0px;height: 26px; padding: 0 0.8rem;}

.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {background: none; color: #151515;font-weight: 700;box-shadow:none}
.accordion-flush .accordion-item .accordion-button:hover, .accordion-flush .accordion-item .accordion-button.collapsed:focus-visible, .accordion-flush .accordion-item .accordion-button.collapsed:focus, .accordion-flush .accordion-item .accordion-button.collapsed:active {background-color: rgba(21,21,21,0.16);outline:none; box-shadow:none}
.accordion-button:focus {box-shadow:none}
.input-group {width:50%}
.input-group label {font-size:14px;margin-left:1rem; cursor:pointer}
.input-group-text {width:100%;padding:0; background:none; border:none}
.input-group .form-check-input:checked {background-color: #0d6efd; border-color: #0d6efd;}
.column-wrap .col-md-6 > div.accordion.accordion-flush {padding: 0;}
.column-wrap .col-md-6 > div.accordion.accordion-flush .accordion-collapse {padding:.5rem 1.5rem}
.tab-pane .row {padding: 0 1rem}
.form-group select {font-size:12px;padding:0 7px}
.savegroup .col-12 {align-items:end;}
.accordion .nav-tabs.nav-fill {flex-wrap: nowrap;}
.accordion .nav-tabs .nav-link {padding:5px;}
.btnnotes {height:100%; margin-left:auto;display:flex; align-items: center;}
table thead th {font-size:13px;font-weight:400 !important;font-family: Poppins-semibold, sans-serif;}
.ant-table .ant-table-thead > tr > th.ant-table-cell {border-bottom: none;background:#F6F6F9 !important;padding: 9px 16px !important;white-space:nowrap; color:#151515; border-top: 1px solid #151515 !important;}
.images .ant-upload-wrapper {padding: 1rem 0;}
.images .btngrp .btn.btn-secondary {background: #FFF; border-color: rgba(21,21,21,0.20); font-size: 14px;}
.images .anticon.anticon-cloud-upload { font-size: 3rem; color: rgba(21,21,21,0.16);}
.uploadText p { margin-bottom: 0; font-size: 12px;color: rgba(21,21,21,0.3);}

/* portal */
div.input-group {width:33.33%;margin-bottom:1rem}
.tab-pane.portal .row {padding:0}
.portal label {font-size:14px; margin-left:5px; }
.portal .nav-tabs .nav-link.active {border: 1px solid rgba(141,141,141,0.25);  }
.portal .nav-tabs .nav-link {padding: 10px 1.5rem; }
.portal .border-wrap {border-radius:0}

/* images */
/* .dropzone {width:100%;height: 300px;border:1px dashed #E6E6E6; justify-content:center;display: flex;flex-direction: column; align-items: center;margin-top:1rem }
.dropzone i {font-size: 4rem;  opacity: 0.15; margin: 0; line-height: 1;}
.dropzone span {margin-bottom:6px; font-size:12px;opacity:0.3}
.images .btngrp {padding:0;gap:0.6rem }
.images .btngrp > div { gap:0.6rem} */

/* Uploaded image group */
/* .imageuploadgrp {margin-top:1rem}
.imageuploadgrp label{font-size:12px;margin-left:3px}
.tab-pane .row.imgupgrp {padding:0 5px;}
.imgupgrp div.input-group {width: 50%;margin-top:5px;}
.imageuploadgrp .btn.btn-secondary {height: 26px;} */


/* MATCH */
.showMatchClass span {font-size: 12px; margin-top: -31px; position: absolute;   background: #FFF;padding: 4px 5px 4px 2px;margin-left:-2px}
.advmatch {border:1px solid #e3e3e3; padding:1rem 0.5rem 0; border-radius: 0.3rem;}
.advmatch .form-controls {display: flex;flex-direction: row;gap: 0.7rem;}
.advmatch .col-6.form-group {width: 47.5%;padding:0}
.advmatch .form-group label {font-size:10px;}
.advmatch .form-group select {padding:5px;}
.advmatch .form-group input:focus-visible {border-color:rgba(21,21,21,0.16);outline:  2px solid rgba(21,21,21,0.06);}
.advmatch .d-flex input {font-size:12px;height:36px}
.dash {line-height:26px; padding:0 3px;}
.advmatch .form-group select { padding: 2px 5px;height: 31px;line-height: 1;}
.advmatch .form-group {margin-bottom: 9px;}
.advmatch .btn.btn-dark {font-size: 14px;}

/* matchresults */
.matchresults {display:flex;margin-top:1rem; flex-direction:column; padding:0.3rem; border-radius: 0.3rem;}
.matchresults .control-labels {font-weight: 400; font-size: 12px; margin-bottom: 3px;text-transform: capitalize; font-family: "Poppins-Semibold", sans-serif;}
.matchresults .btn-link {color:#151515; font-size:11px;}
.matchresult {border:1px solid #E6E6E6;border-radius: 0.3rem;}
.matchitem {display:flex; flex-direction:column; padding:7px 5px; border-bottom:1px solid rgba(21,21,21,0.06); width:100% !important;}
.matchinfo {display:flex; flex-direction: row;width:100%;}
.matchitem .name {font-size: 14px;font-weight: 400; font-family:Poppins-Bold; color:#151515}
.matchitem p, .matchitem span {font-size:12px; margin:0; color:#151515}
.matchitem .addeddate, .matchitem .time {font-size:10px;color:#8D8D8D; text-align:right }
.matchhistory { display: flex; flex-direction: row; justify-content: space-between; margin-top:5px }
.matchhistory .time {color:#8D8D8D}
.matchresult .matchitem:last-child{font-size: 12px; color:#151515; text-align:right; width:100% !important}
.matchresult .address {white-space: nowrap;max-width: 100%; overflow: hidden; text-overflow: ellipsis;}
.matchitem:hover {background-color: rgba(21,21,21,0.05);}

.btn.btn-link.addfeedback {color:#FF0000; text-decoration: none; font-size:10px; height:100%; white-space: normal;width: 100%;padding: 0;text-align: center;}
.btn.btn-link.viewfeedback {color:#25D366; text-decoration: none; font-size:10px; height:100%; white-space: normal; width:100%;padding: 0;text-align: center;}
.timeline .tab-content {min-height: 33rem;}
.matchitem.viewall {font-size:12px; text-align:right}
.timeline .tab-content {max-height: 33rem;overflow-y: scroll;min-height: 33rem;}
.uploadformgroup {margin-bottom: 0;}
.ant-space { margin-left: auto;}
.ant-pagination .ant-pagination-item-active { background: #151515 !important; border-color: #151515 !important; line-height: 26px !important; height: 30px !important;}
.ant-pagination-prev, .ant-pagination-next {height: 30px !important; line-height: 1.2 !important;}
.ant-pagination .ant-pagination-total-text {font-family: Poppins, sans-serif !important; font-size: 12px !important; opacity: 0.6;  }
.btnlist {padding:0; margin:0 -0.8rem}
.timeline-wrap .tab-content .tab-pane .input-group { display: flex; padding: 0 2rem;}
.matchitem p.price {color: #8D8D8D;}
.ant-select.ant-select-show-search .ant-select-selector {height: 36px; border-radius: 5px; margin-top: 0.5rem; line-height: 36px;overflow:hidden}
.ant-select-selector .ant-select-selection-placeholder { line-height: 36px !important;}
.ant-select .ant-select-arrow {margin: 0;}
.ant-modal-footer {display: flex; margin-left: auto;}
.ant-modal-footer .ant-btn.ant-btn-default { height: 36px !important; box-shadow: none;}
.ant-modal-footer .ant-btn.ant-btn-default:hover {color:#151515; border-color:#151515}

.accordion-item .ant-select.ant-select-show-search .ant-select-selector {margin-top: 0;}


/* Responsive */
@media (min-width: 1800px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl { max-width: 1750px;}
}
@media (max-width: 1280px) and (min-width:768px) {
    .timeline div.tab-content {max-height: 28.5rem; min-height: 28.5rem; }
}

/* Add new filter */
.ant-btn span {font-size: 14px;}
.ant-modal .ant-modal-body {display: flex; flex-direction: column; align-items: center;}

/* Leads */
.ant-space.ant-space-vertical, .ant-space.ant-space-vertical .ant-space, .ant-space.ant-space-vertical .ant-space-item {width: 100%;}
.leadtable .ant-table-wrapper .ant-table-tbody > tr:first-child > td {padding: 0 16px !important;}
.leadtable .ant-table-tbody a { font-size: 12px;  }
.ant-table .ant-table-thead > tr > th.ant-table-cell {border-left: 1px solid rgba(21,21,21,0.05);}
.ant-table-tbody .ant-table-row .ant-table-cell:hover { border-start-start-radius: 0 !important; border-end-start-radius: 0 !important;}
.leadtable .ant-table-tbody .ant-table-cell:first-child a {font-family: Poppins-Bold, sans-serif;}
.timeline-list .timegroup .col-10, .timeline-list .timegroup .col-10 .col-12 {padding: 0;}
.ownersdetails .form-group .styles-module_Editext__view_container__3oSYx > div:first-child { width: 100%;padding: 0 0.5rem;font-size: 12px;}
.styles-module_Editext__main_container__2azCD { border: 1px solid rgba(21,21,21,0.16);border-radius: 0.3rem; padding: 0;}
.styles-module_Editext__buttons_container__2za5Q button {border: none;}
.form-group input.styles-module_Editext__input__2-M50 {border: none !important; background: rgba(21,21,21,0.06) !important; border-radius: 0;height: 38px;}
.formfieldset .form-group {margin-bottom: 0;}

/* Listing Status */
.published {color:#25D366}
.comingtomarket {color:#FF9F0A}
.offmarket {color:#4A148C}
.feeds {color:#004D40}
.unpublished {color:#8A8A8A}
.draft {color:#607D8B}
.confirmedowner {color:#795548}
.action {color:#FF453A}
.seo {color:#BF5AF2}
.unlisted {color:#151515}
.sold {color:#5400FF}
.let {color:#5400FF}
.review {color:#AC8E68}
.movetoarchive {color:#8D8D8D}

/* Viewing Status */
.offer {color:#25D366}
.considering {color:#FF9F0A}
.secondviewing {color:#FFD60A}
.notinterested {color:#8A8A8A}

/* Offer Status */
.viewings {color:#25D366}
.scheduled {color:#FF9F0A}
.unsuccessful {color:#151515}
.cancelled {color:#8A8A8A}


.documentlist-wrap .ant-card-actions{position:absolute; bottom:0; width:100%; align-items:center}
.documentlist-wrap .ant-card-meta-detail {min-height:2.8rem}
.documentlist-wrap .ant-card-cover .preview-overlay { background: rgba(21, 21, 21, 0.9); position: absolute; top: 0; width: 100%; height: 100%; opacity:0; transition:all 0.3s ease-in-out; cursor:pointer}
.documentlist-wrap .ant-card-cover .preview-overlay a {color:#FFF; height:100%; width:100%; align-items:center; justify-content:center;  }
.documentlist-wrap .ant-card-cover {overflow:hidden; display:flex; min-height:10rem; position:relative; background:#fafafa}
.documentlist-wrap .ant-card-cover:hover .preview-overlay {opacity:1}

@media screen and (max-width:1440px) and (min-width:980px) {
    .documentlist-wrap .ant-card-cover {min-height:10rem}
    .documentlist-wrap .ant-card-cover iframe, .documentlist-wrap .ant-card-cover img, .documentlist-wrap .ant-card-cover .docxwrap{height:10rem !important}
}