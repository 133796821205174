.notifications .contentwrap {padding:0 3rem}
.border-top-3 {border-top:3px solid #151515}
.border-bottom-3 {border-bottom:3px solid #151515}

.ant-picker.ant-picker-disabled.todaysdate {color:#151515; border:none; background:none}
.ant-picker.ant-picker-disabled.todaysdate input {color:#151515; text-align:right}
.ant-picker.ant-picker-disabled.todaysdate .anticon.anticon-calendar {color: #151515;}

.recentnoti {gap:1.5rem}
.recentnoti > div {border-radius:0.3rem}
.recentnoti > div p.font-bold {color:#151515}

.recentnoti .recentwrap {max-height:calc(27rem); overflow-y:scroll ;}

.noti-group {display:flex; flex-direction:row; padding:1.3rem 0.8rem; gap:0.6rem}
.noti-group p.title {font-size:14px}
.noti-group .icon {border: 1px solid #151515; background-color:rgba(21,21,21,0.05); display:flex; align-items: center; height:49px; width:49px; justify-content:center; border-radius:0.3rem}
.noti-group i {font-size:1.4rem;}
.noti-group p {margin-bottom:0; font-size:12px;}
.noti-group a {font-size:12px; color:#151515; text-decoration:none}
.noti-group .datestamp {margin-left: auto;}
.noti-group .btn.btn-link {border-bottom:1px solid #151515; padding:5px 0 0; border-radius:0; margin-top:5px}
.noti-group i.bi-arrow-right {color:#151515; font-size:14px}
.noti-group.viewingfeedback .icon {border:none; background-color:transparent;}

/* Upcoming Event */
.upcomingevent {border-bottom:1px solid rgba(21,21,21,0.16); padding:1rem 0}

/* Color coded notifications */
.noti-group i.fa, .noti-group .anticon {font-size:1.4rem;}
/* Viewing Feedback */
.noti-group.viewingfeedback i.fa, .noti-group.viewingfeedback p.title {color:#FF0A0A;}
.noti-group.viewingfeedback i.fa {font-size:2rem}
.noti-group.newviewing i.fa, .noti-group.newviewing p.title {color:#FF9F0A}
.noti-group.noti-group.newviewing .icon {background:rgba(255,159,10,0.20); border-color:#FF9F0A}
/* Scheduled Viewing */
.noti-group.scheduledviewing i.fa, .noti-group.scheduledviewing p.title {color:#EB6E00}
.noti-group.scheduledviewing .icon {background:rgba(235,110,0,0.20); border-color:#EB6E00}
/* New Lead */
.noti-group.newlead .anticon, .noti-group.newlead p.title {color:#25D366}
.noti-group.newlead .icon {background:rgba(37,211,102,0.20); border-color:#25D366}


/* Fixed notifications */
.noti-fixed {background-color:#FBFBFB; border-top:3px solid #151515; border-bottom:3px solid #151515; position:sticky; top:0}

/* Coded Activity */
.orange {background-color:rgba(255,197,107,0.20);color: #FFC56B;border:1px solid #FFC56B;}
.darkorange {background-color:rgba(235,110,0,0.20);color: #EB6E00;border:1px solid #EB6E00;}
.green {background-color:rgba(37, 211, 102,0.20);color: #25D366;border:1px solid #25D366;}
.purple {background-color:rgba(191, 90, 242,0.20);color: #BF5AF2;border:1px solid #BF5AF2;}
.navy {background-color:rgba(2, 81, 106,0.20);color: #02516A;border:1px solid #02516A;}
.blue {background-color:rgba(0, 17, 255,0.20);color: #0011FF;border:1px solid #0011FF;}




/* KPI  */
.kpi-wrap .ant-col {background-color: #FFF; padding:1rem}
.kpi-wrap .tabs-team > .ant-tabs-nav {background:none; margin:0; padding:0; height:40px}
.kpi-wrap .tabs-team > .ant-tabs-nav .ant-tabs-tab {margin-right: 0.8rem; background:#151515; color:#FFF; padding:0 0.5rem; border: 1px solid #151515;}
.kpi-wrap .tabs-team > .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-btn {padding:0 0.8rem; box-shadow:none}
.kpi-wrap .tabs-team > .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active {background:#FFF; border: 1px solid rgba(21,21,21,0.06);border-bottom: 0;padding:0 0.8rem}
.kpi-wrap .tabs-team > .ant-tabs-content-holder {border:1px solid rgba(21,21,21,0.06); padding:1.5rem; background:#FFF}
.kpi-wrap .tabs-months > .ant-tabs-nav {background: #FAFAFA; border: 1px solid #F0F0F0; height: 36px; padding:0;}
.kpi-wrap .ant-tabs-nav-wrap .ant-tabs-nav-list {gap:0; width:100%}
.kpi-wrap .ant-tabs.tabs-months .ant-tabs-tab-btn {font-size: 12px;}
.kpi-wrap .ant-tabs-card.ant-tabs.tabs-months > .ant-tabs-nav .ant-tabs-tab {width:100%; border-right: 1px solid rgba(21,21,21,0.06);border-radius: 0;}
.kpi-wrap .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab:last-child {border-right: 0;}
.kpi-wrap .ant-tabs-card.ant-tabs.tabs-months > .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active { border: 1px solid rgba(21,21,21,0.16); background: #F0F0F0; margin: 0;}
.kpi-wrap .ant-tabs-card.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {color:#151515;position:relative;}
.kpi-wrap .ant-tabs-card.ant-tabs.tabs-team > .ant-tabs-tab.ant-tabs-tab-active > .ant-tabs-tab-btn::before {position:absolute; height:2px; background:#151515; width:100%; content:""; bottom:0; left:0; display:flex;}
.kpi-wrap .tabs-team > .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active::before {position: absolute; height:2px; background-color: #FFF; width:100%; display:flex; bottom:1px; left: 0; z-index:3}
.kpi-wrap p {color:#151515;}
.kpi-wrap .kpi-totals-wrap {padding:0 0.3rem; gap: 5px;}
.kpi-wrap .kpi-totals-wrap .ant-col.border {border-radius: 0.3rem;}
.kpi-wrap .tabs-team > .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-btn:focus {color: #fff}
.kpi-wrap .tabs-team > .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn:focus {color:#151515}
.kpi-table .ant-table-content .ant-table-thead th.ant-table-cell{font-size:9px;}
.kpi-table .react-tabs .react-tabs__tab-list .react-tabs__tab {padding:0}
.kpiwrap .ant-select.ant-select-show-search .ant-select-selector {margin:0}
.kpiwrap .react-tabs .react-tabs__tab-list {height:30px; margin:0;justify-content: space-evenly; overflow-y:hidden; padding:0; border:1px solid #f0f0f0;}
.kpiwrap .react-tabs .react-tabs__tab-list .react-tabs__tab { padding:0; border:none; height:100%;}
.kpiwrap .react-tabs .react-tabs__tab--selected::after, .kpiwrap .react-tabs .react-tabs__tab:hover::after  {height:100%; border:1px solid #707070; background-color: #F0F0F0; z-index:1;}
.kpiwrap .react-tabs .react-tabs__tab span {z-index: 2;}
.kpiwrap .ant-row.tablelistwrap {border: 1px solid #dee2e6;margin-top:0.7rem;margin-bottom:0.7rem; border-radius:0.3rem; overflow:hidden}
.kpiwrap .table-responsive .table > thead { background: #fafafa;padding:1rem 0; border-bottom:none}
.kpiwrap .table-responsive .table > thead th {font-size: 9px; text-transform: uppercase; letter-spacing: 1px; text-align: center;}
.kpiwrap .table-responsive .table > thead th:first-child{text-align: left;}
.kpiwrap .tablelistwrap .table tbody tr { border-color: #E7E7E7;}
.kpiwrap .tablelistwrap .table tbody tr td {   padding: 1rem 0.5rem;font-size: 13px;text-align: center;}
.kpiwrap .tablelistwrap .table tbody tr td:first-child { text-align: left;}
.kpiwrap .tablelistwrap .table tbody tr td .targetsales {background:#25D366; border-radius: 0.3rem; padding:0.3rem 0.6rem; color:#FFF}
.kpiwrap .tablelistwrap tr td {white-space: nowrap;}
.kpiwrap .ant-select-selector .ant- select-selection-placeholder {  font-size: 12px !important;}
.kpiwrap .form-group .ant-picker input {font-size:12px; }

.ant-spin-text {color:#151515}
.ant-select.ant-select-show-search .ant-select-selector .ant-select-selection-item {line-height:2.5 !important; font-size:14px}
.ant-select-item-option-content {font-size:12px; padding:0.3rem 0 }
.comborder {border-left:1px solid rgba(21,21,21,0.16) !important; border-right:1px solid rgba(21,21,21,0.16) !important}

.kpicontentwrap {padding: 2rem;border:1px solid rgba(21,21,21,0.16); background: #FFF; border-top:0}
.tabs-team .react-tabs__tab-list { gap: 1rem; display: flex;margin:0; border-bottom-color: rgba(21,21,21,0.16);}
.react-tabs__tab-list .react-tabs__tab {border-radius: 0.3rem 0.3rem 0 0;}
.tabs-team .react-tabs__tab-list .react-tabs__tab { border-radius: 0.3rem 0.3rem 0 0; background: #151515; color: #FFF;}
.tabs-team .react-tabs__tab-list .react-tabs__tab.react-tabs__tab--selected { color: #151515; background: #FFF;border: none; border:1px solid rgba(21,21,21,0.16); border-bottom:none; position:relative}
.tabs-team .react-tabs__tab-list .react-tabs__tab.react-tabs__tab--selected span {font-weight:700;}


@media(max-width:1024px) {
    .kpicontentwrap {
        padding: 5px !important; border: none !important;
    }
    .kpiwrap .ant-row.tablelistwrap{
        border:none !important
    }

   
}

@media(max-width:768px) {
    .tabs-team .react-tabs__tab-list{
        gap:5px
    }
    .tabs-team .react-tabs__tab-list .react-tabs__tab.react-tabs__tab--selected, .tabs-team .react-tabs__tab-list .react-tabs__tab {
        padding: 5px 0 !important;
        font-size: 10px !important;
    }
}