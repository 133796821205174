
.BodyFirstRowRight p{
    font-size: 12px;
    margin-bottom: 5px;
    text-wrap: wrap;
}
.HeaderMainDivRight .info-title, .BodyFirstRowRight .info-title {
    font-weight: 500;
}

.HeaderMainDivRight p, .BodyFirstRowRight p {
    font-weight: 600;
}
.justify-end{
    justify-content: end;
}

.alignCenter{
    align-items: center;
}

.borderBottom{
    border-bottom: 1px solid rgba(21,21,21,0.16);
}

.HeaderMainLeft p{
    font-size: 16px; font-weight: 600;
    margin-bottom: 0 !important;
}

.ant-table-cell.namecolwrap{
    border-radius: 10px;
    padding-top: 0 !important;
}
.tablelistwrap.tblistingwrap div.ant-table-wrapper .ant-table-tbody > tr > td:first-child{padding-top: 0 !important;padding-bottom: 0 !important;}
.TableCard{
    padding: 0 5px 0 5px;
}
.headerGrayBgColor {
    background: rgba(21,21,21,0.08);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding-top: 5px;
}


.BodyFirstRowLeft .noimageyet {
    background-color: #FFF;
    display: flex;
    flex-direction: column;
    margin: 0;
    text-align: center;
    height: 157px;
    justify-content: center;
    border: 1px solid rgba(21, 21, 21, 0.16);
    width: 100%;
}

.slick-list .slick-track{
    padding: 0;
    margin: 0;
    height: 11rem;
    display: flex;
    align-items: center;
}

.footerAlignment{
    align-items: center;
    justify-content: center
}
.footerAlignment a { border-right:1px solid rgba(21,21,21,0.16); height:46px; width:fit-content; padding:8px; text-align:center; display:flex; flex: 1 0 auto; border-radius:0; justify-content:center}
.footerAlignment a:last-child {border-right:0; margin:-5px 0}


@media(max-width:768px) {
    .ant-table-content{
        overflow-x:unset !important;
    }
    .sharepropwrap .ant-table-wrapper.TableborderNoneMain .ant-table-tbody > tr > td:last-child {
        border-right: none !important;
    }
    
    .sharepropwrap .ant-table-wrapper.TableborderNoneMain .ant-table-tbody > tr > td:first-child {
        border-left: none !important;
    }
    .sharepropwrap .ant-table-wrapper.TableborderNoneMain .ant-table-tbody > tr:first-child td {
        border-top: none !important;
    }
     .sharepropwrap .TableborderNoneMain .ant-table-tbody > tr > td {
        border-bottom: none !important;
    }
}
@media(max-width:590px) {
    .imgupgrp > div {
        gap: 0.5rem;
    }
    .ui-sortable-handle, .sortable-container.ui-sortable > div {
        flex: 0 0 48%;
        max-width: 50%;
        display: flex;
        flex-direction: column;
        background: #FFF;
        border: 1px solid rgba(21, 21, 21, 0.04);
        cursor: grab;
    }
    div.input-group.col-sm-6 {
        width: 50%;
        max-width: 50%;
        margin-bottom: 1rem;
    }
}
@media(max-width:430px) {
    .imgupgrp > div {
        gap: 0.5rem;
    }
    .ui-sortable-handle, .sortable-container.ui-sortable > div {
        flex: 0 0 100%;
        max-width: 100%;
        display: flex;
        flex-direction: column;
        background: #FFF;
        border: 1px solid rgba(21, 21, 21, 0.06);
        cursor: grab;
    }
}

@media(max-width:450px) {
    .edittabs .react-tabs .react-tabs__tab-list {
        overflow-x: scroll !important;
    }
    .edittabs .react-tabs .react-tabs__tab-list .react-tabs__tab {
        border: 0 !important;
        padding: 0 10px;
    }
    
}