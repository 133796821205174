/* Colors

primary : #151515
secondary : #8D8D8D
green : #25D366
red : #FF0000

*/

* {
  font-family: Poppins-Regular, sans-serif;
}
.btn-success {
  --bs-btn-bg: #25d366;
  --bs-btn-border-color: #25d366;
}
.btn-primary {
  --bs-btn-hover-bg: #fff;
  --bs-btn-bg: #fff;
  --bs-btn-border-color: rgba(21, 21, 21, 0.16);
}
/* .FilterButtonStyling:hover{
  background: none !important;
  background-color: transparent !important;
  color: black !important;
} */
.ant-table-content {
  overflow-x: scroll;
}
span.ant-menu-title-content {
  font-family: "Poppins-Regular", sans-serif;
}
h3 {
  font-family: Poppins-Bold, sans-serif;
}

button.ant-btn-primary.sendtowhatsapp {
  border-color: #25d366;
  color: #25d366;
}
button.ant-btn-primary.sendtowhatsapp:hover,
button.ant-btn-primary.sendtowhatsapp:focus,
button.ant-btn-primary.sendtowhatsapp:active {
  background: #25d366 !important;
  color: #fff !important;
}
.btn.btn-default {
  background-color: #151515;
  color: #fff;
  border: 1px solid #151515;
}
.btn.btn-default:hover {
  background-color: #fff;
  color: #151515;
}
.btn-preview {
  gap: 0.3rem;
  display: flex;
  align-items: center;
  padding: 0 16px;
}
.toptitle .d-flex.flex-row {
  padding: 0 1.3rem;
  border: none;
}
/* TOP */
.logo {
  margin: 1rem 0;
  width: fit-content;
}
.rentalyield p.form-control {
  background: rgba(21, 21, 21, 0.06);
  text-align: center;
  font-size: 12px;
}

/* navbar */
.nav-link {
  font-size: 0.875rem;
}
ul.ant-menu-overflow.ant-menu.ant-menu-root.ant-menu-horizontal.ant-menu-light {
  width: 100%;
  border-bottom: 1px solid rgba(5, 5, 5, 0.15);
}

.ant-select span.ant-select-clear {
  padding-top: 0.5rem;
}

/* Listings */
/* Tabs */
.ant-tabs-tab.ant-tabs-tab-with-remove {
  font-weight: 400;
}
.ant-tabs .ant-tabs-nav-wrap .tab-btn.ant-tabs-tab-active span {
  color: #fff !important;
  opacity: 1 !important;
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
  background: transparent;
  border: none;
  padding: 0 !important;
  min-width: 100px;
  justify-content: space-evenly;
  text-align: center;
}
.ant-tabs-tab-btn span {
  text-transform: capitalize;
  font-size: 14px;
  font-family: "Poppins-Regular";
  color: #151515;
  text-decoration: none;
}
.ant-tabs-tab {
  padding: 5px 0.8rem !important;
}
.ant-tabs-nav::before {
  display: none;
}
.ant-menu-item.ant-menu-item-active::after,
.ant-menu-submenu:hover::after {
  border-bottom-color: #151515 !important;
}
.ant-tabs .ant-tabs-nav-wrap .ant-tabs-tab span {
  color: #151515;
  display: flex;
  align-items: center;
}
.ant-tabs .ant-tabs-nav-wrap .ant-tabs-tab-active span {
  display: flex;
  align-items: center;
}
.ant-tabs.ant-tabs-top .ant-tabs-nav {
  margin-bottom: 1rem;
  background: rgba(21, 21, 21, 0.02);
  padding: 0 1rem;
  border-radius: 5px;
  height: 50px;
}
.ant-menu-light.ant-menu-horizontal > .ant-menu-submenu-selected span {
  color: #151515;
  font-family: "Poppins-Bold", sans-serif;
}
.ant-tabs-tab.ant-tabs-tab-with-remove.ant-tabs-tab-active {
  position: relative;
}
.ant-tabs-tab.ant-tabs-tab-with-remove.ant-tabs-tab-active::after {
  content: "";
  width: 100%;
  height: 2px;
  background: #151515;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
}
.ant-tabs-tab.ant-tabs-tab-with-remove:hover::after {
  content: "";
  width: 100%;
  height: 2px;
  background: #151515;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  transition: all 0.3s ease-in-out;
}
.ant-tabs-nav-wrap .ant-tabs-nav-list {
  gap: 1rem;
}
.ant-tabs .ant-tabs-nav-wrap .ant-tabs-tab span.anticon {
  opacity: 0.3;
}
.ck.ck-editor__main > .ck-editor__editable {
  min-height: 200px;
}
.contentwrap .ant-space.ant-space-vertical,
.contentwrap .ant-space.ant-space-vertical .ant-space,
.contentwrap .ant-space.ant-space-vertical .ant-space .ant-space-item {
  width: 100%;
}
.ant-table-content table {
  border: none !important;
}
th.ant-table-cell {
  border-start-start-radius: 0 !important;
}
button.ant-btn-primary {
  background: #fff;
  border: 1px solid rgba(21, 21, 21, 0.2);
  color: #151515;
  display: flex;
  align-items: center;
  font-family: Poppins, sans-serif;
  height: 36px;
}
button.ant-btn-primary:hover {
  background-color: #151515 !important;
  color: #fff;
}
.ant-btn.ant-btn-primary.ant-btn-background-ghost {
  background: #fff;
  border: 1px solid rgba(21, 21, 21, 0.16) !important;
  color: #151515;
  display: flex;
  align-items: center;
  font-family: Poppins, sans-serif;
  height: 36px;
}
.ant-btn.ant-btn-primary.ant-btn-background-ghost:hover {
  color: #fff;
}
.ant-table .ant-table-content tr {
  box-shadow: none;
  background: #fff;
}
.ant-table .ant-table-thead > tr > th {
  border-bottom: none;
  background: rgba(21, 21, 21, 0.06) !important;
  padding: 9px 16px;
}
.ant-table-tbody a {
  color: #151515;
  text-decoration: none;
}
.tablelistwrap .ant-table-wrapper .ant-table-thead > tr > th,
.tablelistwrap .ant-table-wrapper .ant-table-tbody > tr > td {
  padding: 1rem 16px !important;
  border-bottom: none !important;
  border-width: 1px;
  border-color: rgba(21, 21, 21, 0);
  font-family: Poppins-Regular, sans-serif;
  font-size: 12px;
  color: #151515;
  text-transform: capitalize;
}
.ant-table-wrapper .ant-table-tbody > tr.ant-table-row:hover > td,
.ant-table-wrapper .ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: transparent;
}

.ant-table-wrapper .ant-table-column-title {
  font-size: 10px;
  font-family: "Poppins-semibold", sans-serif;
  text-transform: uppercase;
  letter-spacing: 3px;
}
.tablelistwrap .ant-table.ant-table-bordered {
  box-shadow: 0 0 1px rgba(21, 21, 21, 0.26) !important;
  overflow: hidden;
}
.ant-table-wrapper .ant-table-tbody > tr > td.ant-table-cell:nth-child(2) {
  text-transform: capitalize;
}
.ant-layout .ant-layout-content.site-layout {
  padding: 0 !important;
  background: #fff;
}
.contentwrap {
  border-radius: 0;
  overflow: hidden;
  background: url("../../../public/images/maskbg.png");
}
.ant-tabs-tab.ant-tabs-tab-with-remove.ant-tabs-tab-active {
  position: relative;
}
.ant-tabs-tab.ant-tabs-tab-with-remove.ant-tabs-tab-active
  .ant-tabs-tab-btn::after {
  display: none;
}
.ant-table-cell .ant-btn.btn.btn-link {
  color: #151515;
}
.ant-table-wrapper .ant-table-content table {
  border-right: 1px solid rgba(21, 21, 21, 0.16);
}
.ant-table-wrapper .ant-table-container:before {
  display: none;
}
.ant-table-cell .ant-btn.btn.btn-link:hover {
  border-color: rgba(21, 21, 21, 0.16);
  background: rgba(21, 21, 21, 0.16);
}
.ant-pagination-options .ant-select-selector {
  height: 30px !important;
  display: flex;
  align-items: center;
}
.ant-pagination-options .ant-select-selector span {
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 300;
}
.ant-spin-container .ant-pagination.ant-table-pagination {
  gap: 0.2rem;
}
.ant-spin-container .ant-pagination-item {
  height: 30px !important;
}
.anticon.anticon-double-right.ant-pagination-item-link-icon {
  color: #151515 !important;
}
.ant-pagination-options .ant-select:hover .ant-select-selector {
  border-color: rgba(21, 21, 21, 0.16) !important;
}
.orgroup hr {
  border-top: 1px solid #151515;
  margin: 0;
}
.orgroup > .d-flex {
  align-items: center;
  gap: 1rem;
}
.orgroup p {
  font-weight: 700;
  color: #151515;
}
.ant-btn.addfilter {
  box-shadow: none;
  border-radius: 5px;
}
.ant-btn.addfilter span {
  color: rgba(21, 21, 21, 0.7);
  font-size: 14px;
}
.ant-btn.addfilter:hover span {
  color: #fff;
}
.ant-table-row .ant-table-cell:nth-child(3) {
  text-transform: capitalize;
}
.ant-table .ant-table-thead > tr > .ant-table-cell {
  background: #f6f6f9;
  border: none;
}
.ant-table .ant-table-thead > tr > .ant-table-cell::before {
  display: none;
}
.ant-btn.ant-btn-primary.addnewlisting {
  background: #fff !important;
  color: #151515 !important;
  box-shadow: none;
  border-radius: 5px;
  border-color: rgba(21, 21, 21, 0.16);
  width: 171px;
}
.ant-btn.ant-btn-primary.addnewlisting span {
  color: #151515;
}
.ant-btn.ant-btn-primary.addnewlisting:hover {
  background: #151515 !important;
  color: #fff;
  border-color: rgba(21, 21, 21, 0.16);
}
.ant-btn.ant-btn-primary.addnewlisting:hover span {
  color: #fff;
}
.ant-btn.ant-btn-primary.resetallbtn {
  background: #fb6107;
  width: 171px;
  height: 46px;
}
.ant-btn.ant-btn-primary.resetallbtn span {
  color: #fff;
}
.ant-btn.ant-btn-primary.resetallbtn:hover {
  background: #f24c00 !important;
}
.ant-select.ant-select-single.ant-select-show-arrow {
  width: 100% !important;
}
.advmatch .form-controls .ant-select .ant-select-selector {
  padding: 0;
}
.advmatch
  .form-controls
  .ant-select
  .ant-select-selector
  .ant-select-selection-search {
  inset-inline-start: 0;
  width: 100%;
  height: 32px;
}
.btn.btn-link.addbtn {
  font-size: 14px;
  padding: 5px 0;
  position: relative;
}
.btn.btn-link.addbtn:hover::after {
  content: "";
  width: 100%;
  height: 2px;
  background: #151515;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  transition: all 0.3s ease-in-out;
}

/* Table info */
.listinginfo p {
  font-size: 12px;
  white-space: normal !important;
}
.listinginfowrap {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: start;
  gap: 0.8rem;
  height: 120px;
}
.info-group {
  margin-bottom: 0;
}
.info-group p {
  margin-bottom: 0;
  white-space: normal;
  font-size: 12px;
}
.info-group .info-title {
  margin-bottom: 0;
  color: #151515;
  font-size: 11px;
}
.listinginfowrap .info-group p:nth-child(2),
.listinginfowrap .info-group p:nth-child(2) time {
  font-family: "Poppins-Semibold", sans-serif;
}

/* .ant-space .ant-space-item {display: none;} */
.ant-space .ant-space-item:first-child {
  display: flex;
}

/* Modal */
.ant-modal-content .ant-modal-header .ant-modal-title {
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  font-size: 1.2rem;
}
.ant-modal-footer {
  justify-content: end;
}

/* Add new listing */
.titleadd {
  font-size: 26px;
  font-family: "Poppins-Bold", sans-serif;
  padding: 0;
}
.addwrap label {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  margin-bottom: 5px;
}
.addwrap {
  box-shadow: 0 0 3px rgba(21, 21, 21, 0.16);
  border: 1px solid rgba(21, 21, 21, 0.16);
  border-radius: 0.3rem;
}
.addwrap .form-group .ant-select .ant-select-selection-search-input {
  height: 46px !important;
}
.addwrap .form-group .form-control,
.addwrap .form-group .ant-select.ant-select-show-search {
  height: 46px !important;
  font-family: Poppins, sans-serif;
}
.addwrap .ant-select-single .ant-select-selector {
  height: 46px;
  overflow: hidden;
}
.ant-select:hover .ant-select-selector {
  border-color: #151515 !important;
}
.addwrap .form-group .form-control:hover,
.addwrap .form-group .form-control:focus {
  box-shadow: none;
  border: 1px solid #151515 !important;
}
.addwrap .form-control:focus {
  box-shadow: none;
  border: 1px solid #151515 !important;
}
.addwrap .form-control {
  font-family: Poppins, sans-serif;
  font-size: 14px;
  height: 46px;
  line-height: 2.25;
}
.ant-btn-primary.btnsave {
  background: #151515;
  color: #fff;
  box-shadow: 0 0 1px #151515;
}
.ant-drawer-title {
  font-family: Poppins, sans-serif;
}
.ant-btn-primary.ant-btn-dangerous.ant-btn-background-ghost:not(
    :disabled
  ):hover {
  color: #fff;
}
.ant-select .ant-select-selection-search input {
  height: 100% !important;
  line-height: 100%;
  font-family: Poppins, sans-serif;
}
.ant-select-selector .ant-select-selection-placeholder {
  font-family: Poppins, sans-serif;
  line-height: 46px !important;
  display: flex;
}
.addwrap .form-select {
  height: 46px;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  text-transform: capitalize;
}
.addwrap .form-select:hover,
.addwrap .form-select:focus {
  box-shadow: none;
  border-color: #151515;
}
.addwrap h3.title {
  font-size: 21px;
  font-family: Poppins-Bold, sans-serif;
}
.changemode {
  display: none;
}

/* Edit Listing */
.border-top {
  border-top: 5px solid #151515 !important;
}
.btn.btn-primary.requestchange {
  font-size: 10px;
  height: 26px;
}
.cont-wrap {
  display: flex;
  flex-direction: column;
  margin: 0 auto !important;
  padding: 0;
}
.toptitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
.column-wrap {
  flex-wrap: nowrap;
  width: 100%;
  display: flex;
  gap: 0.6rem;
}
.column-wrap .col-md-3 > div,
.column-wrap .col-md-6 > div {
  padding: 1.5rem;
  border: 1px solid rgba(21, 21, 21, 0.16);
  box-shadow: 0 0 4px rgba(21, 21, 21, 0.06);
}
.column-wrap h3 {
  font-size: 16px;
  color: #151515;
  margin: 5px 0;
  font-weight: 400;
  white-space: nowrap;
  font-family: Poppins-Bold, sans-serif;
}
.formfieldset {
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
}
.form-group {
  flex-direction: column;
  display: flex;
}
.form-group label {
  font-size: 0.73rem;
  display: flex;
  font-weight: normal;
  margin-bottom: 5px;
}
.form-group input,
.form-group select {
  height: 36px;
  width: 100%;
  font-size: 12px;
  border: 1px solid #e3e3e3;
  background: #fff !important;
  border-radius: 0.3rem;
}
hr {
  border-top: 1px dashed #e3e3e3;
  width: 100%;
}
.page-title {
  width: fit-content;
}
.listtable-wrap a {
  border-bottom: 1px solid rgba(21, 21, 21, 0.06);
}
.listtable-wrap a:hover,
.listtable-wrap a:focus {
  background-color: rgba(21, 21, 21, 0.05);
}
.listtable p {
  margin-bottom: 0;
}
.listtable .propfor {
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 4px;
}
.listtable .refno {
  font-size: 16px;
  font-weight: bold;
}
.listtable .propadd,
.listtable .community {
  font-size: 12px;
}
.val-wrap p {
  margin-bottom: 0;
}
.btn.btn-primary.requestchange {
  color: #151515;
}
.btn.btn-primary.requestchange:hover {
  border-color: #151515;
}
.listtable-wrap {
  padding: 0;
}
.border-wrap {
  border: 1px solid #e3e3e3;
  border-radius: 0.3rem;
}
.listtable {
  padding: 0;
}
.agentprop .label {
  color: #8d8d8d;
  font-size: 0.7rem;
  text-transform: uppercase;
  letter-spacing: 3px;
  margin-bottom: 0;
}
.listtable-wrap .col-4 {
  padding: 0;
}
button.btn.btn-primary {
  color: #151515;
  font-size: 14px;
}
button.btn.btn-primary:hover {
  border-color: #151515;
}
.offers .form-group select {
  padding: 0 10px;
}
.btn.btn-secondary {
  color: #151515;
  font-size: 12px;
  height: 36px;
}
.form-group input:focus {
  border: 1px solid #e3e3e3 !important;
}
.form-group input {
  padding: 0 7px;
}
/* .timeline-wrap .tab-content .tab-pane .input-group.uploadgrp { width: 50%; padding: 0 12px;  } */
.requesttable,
.requesttable .col-6 {
  padding: 0;
}

/* Match */
.matchfilter .form-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.3rem;
  border-bottom: 1px solid rgba(21, 21, 21, 0.16);
}
.matchfilter .form-group .ant-select-selection-search-input:focus {
  border: none !important;
}
.matchfilter .ant-select-selector {
  padding: 0 !important;
}
.matchfilter .ant-select-selector .ant-select-selection-search {
  inset-inline-start: 0;
  inset-inline-end: 0;
  display: flex;
  align-items: baseline;
}
.matchfilter .ant-select-selector .ant-select-selection-search input {
  font-family: "Poppins-Regular", sans-serif;
}
.matchfilter .form-group label {
  white-space: nowrap;
  color: rgba(21, 21, 21, 0.7);
  font-size: 12px;
}
.matchfilter .budget input {
  border: none;
  height: 26px;
}
.matchfilter .budget input:hover {
  border: none;
}
.matchfilter .form-group select {
  border: none;
  height: 26px !important;
}
.matchfilter .form-group label {
  margin-bottom: 0;
}
.matchfilter .ant-select.ant-select-single.ant-select-show-arrow {
  overflow: hidden;
}
.macthfilter .form-group input:focus {
  border: none !important;
}
.matchfilter .ant-select-selector {
  border: none !important;
  height: 26px !important;
  margin: 0 !important;
}
.matchfilter .ant-select-selector .ant-select-selection-item {
  font-size: 12px;
  line-height: 26px;
  font-family: "Poppins-Semibold", sans-serif;
}
.matchfilter .budget input:focus {
  border: none !important;
  font-family: "Poppins-Semibold", sans-serif;
}
.matchfilter .btn.btn-dark.w-100 {
  font-size: 12px;
  height: 36px;
}
.matchresults .col-9 {
  padding: 0;
}

/* listingstats */
.stat {
  font-size: 12px !important;
  gap: 0.3rem;
  align-items: center;
  margin-left: auto;
  width: fit-content;
  display: flex;
}
.publishstat i,
.publishstat span {
  color: #25d366;
}
.agentprop {
  margin-top: 1.35rem;
  text-align: right;
}
.agentprop .name {
  font-size: 12px;
  white-space: nowrap;
  margin-bottom: 0;
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.btn.btn-link.viewall {
  font-size: 12px;
  padding-right: 0;
  text-decoration: none;
  color: #151515;
}
.btn.btn-link.viewall:hover {
  color: #8a8a8a;
}

/* formupload group */
.uploadformgroup .form-group input[type="checkbox"] {
  width: 20px;
  height: 26px;
  position: relative;
  margin-left: auto;
  display: block;
}
.uploadformgroup.form-group select,
.uploadformgroup .form-group input[type="datetime-local"] {
  width: 90%;
  float: left;
}
.uploadformgroup .form-group {
  display: block;
}
.uploadformgroup .form-group input[type="checkbox"]:focus {
  box-shadow: none;
}
.uploadformgroup .form-group input[type="checkbox"]:checked {
  background-color: #0d6efd;
  border-color: #0d6efd;
}

/* marketrequests */
.marketrequest .btn.btn-secondary {
  background-color: #f3f3f3;
  border: 1px solid #f3f3f3;
  text-align: left;
  padding: 0 0.3rem;
  gap: 0.3rem;
  display: flex;
  align-items: center;
  white-space: nowrap;
  width: 49%;
  font-size: 12px;
  height: 36px;
}
.marketrequest .btn.btn-secondary:hover {
  background-color: #151515;
  border-color: #151515;
}
.marketrequest .btn.btn-secondary:hover i,
.marketrequest .btn.btn-secondary:hover span {
  color: #fff;
}
.marketrequest .btn.btn-secondary i {
  width: 10px;
}
.marketrequest > div {
  gap: 0.3rem;
}
.marketrequest .requestfor {
  font-size: 0.73rem;
  text-transform: uppercase;
}
.marketrequest p {
  margin-bottom: 0;
}
.marketwrap {
  border-bottom: 1px solid #f3f3f3;
}
.marketrequest .requesttable {
  width: 100%;
}
.marketrequest .requestname {
  font-size: 0.8rem;
  font-weight: bold;
  white-space: nowrap;
}
.marketrequest .requestdate {
  margin-bottom: 3px;
}
.marketrequest .requestdate,
.marketrequest .requeststat {
  color: #8d8d8d;
  font-size: 0.73rem;
}
.timeline-wrap .tab-pane .btn.btn-primary.requestchange {
  margin: 0 auto;
  margin-top: 0px;
  display: flex;
  align-items: center;
}

/* tabs */
.nav-tabs .nav-link {
  border: none;
  color: #151515;
  font-size: 13px;
  text-transform: uppercase;
}
.nav-tabs .nav-link.active {
  border: none;
  border-bottom: 2px solid #151515;
  font-weight: bold;
}
.tab-content {
  padding: 1rem 0;
}
.tab-content textarea {
  border: 1px solid #cacaca;
  border-radius: 0.3rem;
  width: 100%;
  font-size: 12px;
  padding: 0.5rem;
}
.tab-content textarea:focus-visible {
  border-color: rgba(21, 21, 21, 0.8);
  outline: 2px solid rgba(21, 21, 21, 0.3);
}
.tab-content button.btn.btn-primary.requestchange {
  margin: 0 auto;
  margin-top: 0px;
  font-size: 12px;
  height: 36px;
  padding: 0 2rem;
}
/* timeline */
.timeline-wrap {
  margin-bottom: 1rem;
}
.timeline-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.timeline .nav-tabs {
  background: none;
  border: none;
  border-bottom: 1px solid rgba(21, 21, 21, 0.16);
}
.timeline .nav-tabs .nav-link.active {
  border: none;
  border-radius: 0;
  border-bottom: 2px solid #151515;
}
.timegroup {
  display: flex;
  flex-direction: row;
  padding: 0 1rem;
  width: 100%;
}
.agentwrap .namewrap {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: rgba(21, 21, 21, 0.17);
  text-align: center;
  align-items: center;
}
.namewrap span {
  font-size: 10px;
  font-weight: bold;
  color: #fff;
}
.agentdetails {
  width: 100%;
}
.agentdetails p {
  font-size: 12px;
  margin-bottom: 0;
}
.agentdetails .awriter {
  font-size: 14px;
  font-family: "Poppins-Regular", sans-serif;
  color: #151515;
}
.atime {
  color: rgba(21, 21, 21, 0.3);
}
.agentdetails .aclass {
  font-weight: 700;
  margin-bottom: 5px;
}
.adesc {
  font-size: 12px;
  border: 1px solid #d4d4d4;
  width: 100%;
  padding: 0.5rem;
  border-radius: 0.3rem;
  margin-top: 5px;
}
.adesc p {
  margin-bottom: 0;
  color: #151515;
}
.adesc p.price {
  color: rgba(21, 21, 21, 0.4);
  font-size: 12px;
}
.agentdetails .whocall {
  font-size: 16px;
}
.agentdetails .calldetails {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.6rem;
}
.timegroup .col-1 {
  position: relative;
}
.timegroup .col-1::before {
  display: flex;
  content: "";
  border-left: 1px solid rgba(21, 21, 21, 0.06);
  height: 100%;
  width: 1px;
  position: absolute;
  z-index: 2;
  top: 1.6rem;
  left: 50%;
}
.timeline-list .timegroup:last-child .col-1::before {
  display: none;
}
.timeline-wrap .nav.nav-tabs button.nav-link:first-child,
.nav.nav-tabs button.nav-link:last-child {
  padding: 8px 0;
}
.form-group .form-control-disabled {
  border: 1px solid rgba(21, 21, 21, 0.16);
  padding: 5px;
  height: 38px;
  border-radius: 0.2rem;
  line-height: 27px;
  margin: 0;
  font-size: 12px;
}
/* timeline icons */
.iconwrap {
  border-radius: 50%;
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 3;
  left: 50%;
  transform: translateX(-50%);
}
.iconwrap i {
  color: #fff;
  margin-right: 0;
  font-size: 11px;
}
.timegroup.note .iconwrap {
  background: #151515;
}
.timegroup.viewing .iconwrap {
  background-color: #f9c851;
}
.timegroup.phone .iconwrap {
  background-color: #7734ea;
}
.timegroup.whatsapp .iconwrap {
  background-color: #25d366;
}
.timegroup.system .iconwrap {
  background-color: #151515;
}
.timegroup.email .iconwrap {
  background-color: #255fd3;
}
.timegroup.offer .iconwrap {
  background-color: #35b8e0;
}
.timegroup.noanswer .iconwrap {
  background-color: #f00;
}
.timegroup.noanswer .iconwrap:before {
  height: 1px;
  width: 65%;
  background: #fff;
  position: absolute;
  transform: rotate(-40deg);
  content: "";
}
.tab-pane input {
  font-size: 12px;
}

/* Details */
.form-group {
  margin-bottom: 1rem;
}
.form-group.price .btn.btn-primary.requestchange {
  font-size: 12px;
  margin: 0 0 0 auto;
  margin-top: 0px;
  height: 26px;
  padding: 0 0.8rem;
}

.accordion-flush .accordion-item .accordion-button,
.accordion-flush .accordion-item .accordion-button.collapsed {
  background: none;
  color: #151515;
  font-weight: 700;
  box-shadow: none;
}
.accordion-flush .accordion-item .accordion-button:hover,
.accordion-flush .accordion-item .accordion-button.collapsed:focus-visible,
.accordion-flush .accordion-item .accordion-button.collapsed:focus,
.accordion-flush .accordion-item .accordion-button.collapsed:active {
  background-color: rgba(21, 21, 21, 0.16);
  outline: none;
  box-shadow: none;
}
.accordion-button:focus {
  box-shadow: none;
}
.input-group {
  width: 50%;
}
.input-group label {
  font-size: 14px;
  margin-left: 1rem;
  cursor: pointer;
}
.input-group-text {
  width: 100%;
  padding: 0;
  background: none;
  border: none;
}
.input-group .form-check-input:checked {
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.column-wrap .col-md-6 > div.accordion.accordion-flush {
  padding: 0;
}
.column-wrap .col-md-6 > div.accordion.accordion-flush .accordion-collapse {
  padding: 0.5rem 1.5rem;
}
.tab-pane .row {
  padding: 0 1rem;
}
.form-group select {
  font-size: 12px;
  padding: 0 7px;
}
.savegroup .col-12 {
  align-items: end;
}
.accordion .nav-tabs.nav-fill {
  flex-wrap: nowrap;
}
.accordion .nav-tabs .nav-link {
  padding: 5px;
}
.btnnotes {
  height: 100%;
  margin-left: auto;
  display: flex;
  align-items: center;
}
table thead th {
  font-size: 13px;
  font-weight: 400 !important;
  font-family: Poppins-semibold, sans-serif;
}
.ant-table .ant-table-thead > tr > th.ant-table-cell {
  border-bottom: none;
  background: #fafafa !important;
  padding: 9px 16px !important;
  white-space: nowrap;
  color: #151515;
  border-top: 1px solid #151515 !important;
  font-size: 10px;
  font-family: "Poppins-semibold", sans-serif;
  text-transform: uppercase;
  letter-spacing: 3px;
}
.images .ant-upload-wrapper {
  padding: 1rem 0;
}
.images .btngrp .btn.btn-secondary {
  background: #fff;
  border-color: rgba(21, 21, 21, 0.2);
  font-size: 14px;
}
.images .anticon.anticon-cloud-upload {
  font-size: 3rem;
  color: rgba(21, 21, 21, 0.16);
}
.uploadText p {
  margin-bottom: 0;
  font-size: 12px;
  color: rgba(21, 21, 21, 0.3);
}

/* portal */
div.input-group {
  width: 33.33%;
  margin-bottom: 1rem;
}
.tab-pane.portal .row {
  padding: 0;
}
.portal label {
  font-size: 14px;
  margin-left: 5px;
}
.portal .nav-tabs .nav-link.active {
  border: 1px solid rgba(141, 141, 141, 0.25);
}
.portal .nav-tabs .nav-link {
  padding: 10px 1.5rem;
}
.portal .border-wrap {
  border-radius: 0;
}

/* images */
/* .dropzone {width:100%;height: 300px;border:1px dashed #E6E6E6; justify-content:center;display: flex;flex-direction: column; align-items: center;margin-top:1rem }
.dropzone i {font-size: 4rem;  opacity: 0.15; margin: 0; line-height: 1;}
.dropzone span {margin-bottom:6px; font-size:12px;opacity:0.3}
.images .btngrp {padding:0;gap:0.6rem }
.images .btngrp > div { gap:0.6rem}
.imgupgrp > div { display: flex; flex-wrap: wrap;} */

/* Uploaded image group */
/* .imageuploadgrp {margin-top:1rem;  opacity: 1 !important; z-index: 9999 !important; background: #FFF; position: relative;}
.imageuploadgrp label{font-size:12px;margin-left:3px}
.tab-pane .row.imgupgrp {padding:0 5px;}
.imgupgrp div.input-group {width: 50%;margin-top:5px;}
.imageuploadgrp .btn.btn-secondary {height: 26px;} */

.imgupgrp > .container {
  display: flex;
  touch-action: none;
  width: 100%;
  height: 100%;
  margin: 1rem auto;
}

.dropzone {
  flex: 1;
  height: 100vh;
  border: 1px dashed rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
}

.dropzone.left {
  margin-right: 10px;
}

.grid-item {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 0.5em;
}

.grid-item-content {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background: #08e;
  display: flex;
  justify-content: center;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  align-items: center;
  border-radius: 0.5em;
}

/* MATCH */
.showMatchClass span {
  font-size: 12px;
  margin-top: -31px;
  position: absolute;
  background: #fff;
  padding: 4px 5px 4px 2px;
  margin-left: -2px;
}
.advmatch {
  border: 1px solid #e3e3e3;
  padding: 1rem 0.5rem 0;
  border-radius: 0.3rem;
}
.advmatch .form-controls {
  display: flex;
  flex-direction: row;
  gap: 0.7rem;
}
.advmatch .col-6.form-group {
  width: 47.5%;
  padding: 0;
}
.advmatch .form-group label {
  font-size: 10px;
}
.advmatch .form-group select {
  padding: 5px;
}
.advmatch .form-group input:focus-visible {
  border-color: rgba(21, 21, 21, 0.16);
  outline: 2px solid rgba(21, 21, 21, 0.06);
}
.advmatch .d-flex input {
  font-size: 12px;
  height: 36px;
}
.dash {
  line-height: 26px;
  padding: 0 3px;
}
.advmatch .form-group select {
  padding: 2px 5px;
  height: 31px;
  line-height: 1;
}
.advmatch .form-group {
  margin-bottom: 9px;
}
.advmatch .btn.btn-dark {
  font-size: 14px;
}
.matchfilter
  .ant-select.ant-select-show-search
  .ant-select-selector
  .ant-select-selection-item {
  height: 26px;
  line-height: 26px;
}

/* matchresults */
.matchresults {
  display: flex;
  margin-top: 1rem;
  flex-direction: column;
  padding: 0.3rem;
  border-radius: 0.3rem;
}
.matchresults .control-labels {
  font-weight: 400;
  font-size: 12px;
  margin-bottom: 3px;
  text-transform: capitalize;
  font-family: "Poppins-Semibold", sans-serif;
}
.matchresults .btn-link {
  color: #151515;
  font-size: 11px;
}
.matchresult {
  border: 1px solid #e6e6e6;
  border-radius: 0.3rem;
}
.matchitem {
  display: flex;
  flex-direction: column;
  padding: 7px 5px;
  border-bottom: 1px solid rgba(21, 21, 21, 0.06);
  width: 100% !important;
}
.matchinfo {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.matchitem .name {
  font-size: 14px;
  font-weight: 400;
  font-family: Poppins-Bold;
  color: #151515;
}
.matchitem p,
.matchitem span {
  font-size: 12px;
  margin: 0;
  color: #151515;
}
.matchitem .addeddate,
.matchitem .time {
  font-size: 10px;
  color: #8d8d8d;
  text-align: right;
}
.matchhistory {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 5px;
}
.matchhistory .time {
  color: #8d8d8d;
}
.matchresult .matchitem:last-child {
  font-size: 12px;
  color: #151515;
  text-align: right;
  width: 100% !important;
}
.matchresult .address {
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.matchitem:hover {
  background-color: rgba(21, 21, 21, 0.05);
}

.btn.btn-link.addfeedback {
  color: #ff0000;
  text-decoration: none;
  font-size: 10px;
  height: 100%;
  white-space: normal;
  width: 100%;
  padding: 0;
  text-align: center;
}
.btn.btn-link.viewfeedback {
  color: #25d366;
  text-decoration: none;
  font-size: 10px;
  height: 100%;
  white-space: normal;
  width: 100%;
  padding: 0;
  text-align: center;
}
.timeline .tab-content {
  min-height: 33rem;
}
.matchitem.viewall {
  font-size: 12px;
  text-align: right;
}
.timeline .tab-content {
  max-height: 33rem;
  overflow-y: scroll;
  min-height: 33rem;
}
.uploadformgroup {
  margin-bottom: 0;
}
.ant-space {
  margin-left: auto;
}
.ant-pagination .ant-pagination-item-active {
  background: #151515 !important;
  border-color: #151515 !important;
  line-height: 26px !important;
  height: 30px !important;
}
.ant-pagination-prev,
.ant-pagination-next {
  height: 30px !important;
  line-height: 1.2 !important;
}
.ant-pagination .ant-pagination-total-text {
  font-family: Poppins, sans-serif !important;
  font-size: 12px !important;
  opacity: 0.6;
}
.btnlist {
  padding: 0;
  margin: 0 -0.8rem;
}
.timeline-wrap .tab-content .tab-pane .input-group {
  display: flex;
  padding: 0 2rem;
}
.matchitem p.price {
  color: #8d8d8d;
}
.ant-select.ant-select-show-search .ant-select-selector {
  height: 46px;
  border-radius: 5px;
  margin-top: 0.5rem;
  line-height: 46px;
  overflow: hidden;
}
.ant-select-selector .ant-select-selection-placeholder {
  line-height: 36px !important;
}
.ant-select .ant-select-arrow {
  margin: 0;
}
.ant-modal-footer {
  display: flex;
  margin-left: auto;
}
.ant-modal-footer .ant-btn.ant-btn-default {
  height: 36px !important;
  box-shadow: none;
}
.ant-modal-footer .ant-btn.ant-btn-default:hover {
  color: #151515;
  border-color: #151515;
}

.accordion-item .ant-select.ant-select-show-search .ant-select-selector {
  margin-top: 0;
}

/* Responsive */
@media (min-width: 1800px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1750px;
  }
}
@media (max-width: 1280px) and (min-width: 768px) {
  .timeline div.tab-content {
    max-height: 28.5rem;
    min-height: 28.5rem;
  }
}

/* Add new filter */
.ant-btn span {
  font-size: 14px;
}
.ant-modal .ant-modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Leads */
.ant-space.ant-space-vertical,
.ant-space.ant-space-vertical .ant-space,
.ant-space.ant-space-vertical .ant-space-item {
  width: 100%;
}
.leadtable .ant-table-wrapper .ant-table-tbody > tr:first-child > td {
  padding: 0 16px !important;
}
.leadtable .ant-table-tbody a {
  font-size: 12px;
}
.ant-table .ant-table-thead > tr > th.ant-table-cell {
  border: none;
}
.ant-table-tbody .ant-table-row .ant-table-cell:hover {
  border-start-start-radius: 0 !important;
  border-end-start-radius: 0 !important;
}
.leadtable .ant-table-tbody .ant-table-cell:first-child a {
  font-family: Poppins-Bold, sans-serif;
}
.timeline-list .timegroup .col-10,
.timeline-list .timegroup .col-10 .col-12 {
  padding: 0;
}
.ownersdetails
  .form-group
  .styles-module_Editext__view_container__3oSYx
  > div:first-child {
  width: 100%;
  padding: 0 0.5rem;
  font-size: 12px;
}
.styles-module_Editext__main_container__2azCD {
  border: 1px solid rgba(21, 21, 21, 0.16);
  border-radius: 0.3rem;
  padding: 0;
}
.styles-module_Editext__buttons_container__2za5Q button {
  border: none;
}
.form-group input.styles-module_Editext__input__2-M50 {
  border: none !important;
  background: rgba(21, 21, 21, 0.06) !important;
  border-radius: 0;
  height: 38px;
}
.formfieldset .form-group {
  margin-bottom: 0;
}

/* Listing Status */
.listinginfo .pstat {
  width: fit-content;
  font-size: 11px;
}
.pstat.published {
  color: #25d366;
  background-color: rgba(37, 211, 102, 0.15);
}
.pstat.comingtomarket {
  color: #ff9f0a;
  background-color: rgba(255, 159, 10, 0.15);
}
.pstat.offmarket {
  color: #4a148c;
  background-color: rgba(74, 20, 140, 0.15);
}
.pstat.feeds {
  color: #004d40;
  background-color: rgba(0, 77, 64, 0.15);
}
.pstat.unpublished {
  color: #8a8a8a;
  background-color: rgba(138, 138, 138, 0.15);
}
.pstat.draft {
  color: #607d8b;
  background-color: rgba(138, 138, 138, 0.15);
}
.pstat.confirmedowner {
  color: #c51162;
  background-color: rgba(197, 17, 98, 0.15);
}
.pstat.action {
  color: #ff0000;
  background-color: rgba(255, 0, 0, 0.15);
}
.pstat.seo {
  color: #bf5af2;
  background-color: rgba(191, 90, 242, 0.15);
}
.pstat.unlisted {
  color: #151515;
  background-color: rgba(21, 21, 21, 0.15);
}
.pstat.sold {
  color: #5400ff;
  background-color: rgba(84, 0, 255, 0.15);
}
.pstat.let {
  color: #5400ff;
  background-color: rgba(84, 0, 255, 0.15);
}
.pstat.review {
  color: #ac8e68;
  background-color: rgba(172, 142, 104, 0.15);
}
.pstat.movetoarchive {
  color: #8d8d8d;
  background-color: rgba(141, 141, 141, 0.15);
}
.pstat.deal {
  color: #468a3f;
  background: rgba(70, 138, 63, 0.15);
}

/* Viewing Status */
.offer {
  color: #25d366;
}
.considering {
  color: #ff9f0a;
}
.secondviewing {
  color: #ffd60a;
}
.notinterested {
  color: #8a8a8a;
}
.deal {
  color: #468a3f;
}

/* Offer Status */
.viewings {
  color: #25d366;
}
.scheduled {
  color: #ff9f0a;
}
.unsuccessful {
  color: #151515;
}
.cancelled {
  color: #8a8a8a;
}

.pstat {
  width: 100%;
  padding: 5px 10px;
  background-color: rgba(21, 21, 21, 0.06);
  border-radius: 5px;
  text-transform: capitalize;
  text-align: center;
  font-size: 11px;
}
.ant-table-wrapper .ant-table-tbody > tr > td.ant-table-cell.liststat {
  padding: 0 16px !important;
}

.tablelistwrap .ant-table-wrapper .ant-table {
  border: 1px solid rgba(21, 21, 21, 0.06);
}
.ant-table-column-sorter.ant-table-column-sorter-full {
  padding-left: 1rem;
}

/* Status Bar - Listing Info*/
.pinfo {
  display: flex;
  gap: 1rem;
  flex-direction: row;
  align-items: center;
  text-transform: capitalize;
  white-space: nowrap;
  flex-wrap: wrap;
  line-height: 1;
  row-gap: 0.2rem;
}
.pinfo .pstat {
  width: fit-content;
}
.pinfo p,
.pinfo span {
  font-size: 12px;
}

.followups .ant-select.ant-select-show-search .ant-select-selector {
  margin: 0;
}
.form-group .ant-picker input:focus {
  border: none !important;
}
.form-group .ant-picker input {
  height: 36px;
  line-height: 36px;
  padding: 0;
}

.font-12 {
  font-size: 12px;
}
.font-14 {
  font-size: 14px;
}
.form-group p.form-controls {
  border: 1px solid rgba(21, 21, 21, 0.16);
  border-radius: 0.3rem;
  padding: 6px;
}
.ant-select.ant-select-show-search
  .ant-select-selector
  .ant-select-selection-item {
  height: 46px;
  line-height: 46px;
}

.ant-dropdown .ant-table-filter-dropdown > div {
  min-width: 17rem;
  padding: 1rem !important;
}

/* Listing Dropdown Info */
.listingdropinfo {
  display: flex;
  flex-direction: row;
  gap: 0.6rem;
}
.imgcarousel {
  margin: 0;
  height: "160px";
  color: "#fff";
}
.listdropinfo-info {
  display: flex;
  flex-direction: column;
  max-width: 60%;
}
.listdropinfo-info p {
  white-space: normal;
}

em {
  color: #ff0000;
}

.pledge .ant-modal-content .ant-modal-body {
  background-color: #f8fafb;
}
.pledge .ant-modal-content .ant-modal-body .row {
  padding: 20px 24px 0;
}
.ant-modal-footer {
  padding: 0 2.25rem 10px;
}
.pledge .ant-modal-content {
  padding: 0;
}
.pledge .title {
  background-color: #fff;
  width: 100%;
}
.pledge .form-controls {
  font-family: "Poppins", sans-serif;
  height: 46px;
  font-size: 14px;
}
.font-12 {
  font-size: 12px;
}
.pledge .ant-table .ant-table-thead .ant-table-cell {
  font-family: "Poppins-Bold", sans-serif !important;
}
.pledge.tablelistwrap
  .ant-table-wrapper
  .ant-table-tbody
  > tr
  > td.ant-table-cell {
  color: #151515;
  vertical-align: top;
}
.pledge .mgmtfilter label {
  font-size: 14px;
}
.pledge.tablelistwrap
  .ant-table-wrapper
  .ant-table-tbody
  > tr
  > td.ant-table-cell
  a:hover {
  color: #0d6efd;
}
.pledge .ant-picker {
  height: 36px;
  overflow: hidden;
  margin-right: 1rem;
  display: flex;
  align-items: center;
}

/* All Viewings */
.allviewings.tablelistwrap .ant-table-tbody .ant-table-measure-row td,
.tablelistwrap
  div.ant-table-wrapper
  .ant-table-tbody
  > tr.ant-table-measure-row
  > td:first-child {
  padding: 0 !important;
}
.allviewings.tablelistwrap .ant-table.ant-table tr {
  box-shadow: none;
}
.allviewings .clientwrap a {
  white-space: normal;
}
.allviewings.tablelistwrap .ant-table-wrapper {
  padding: 0;
}
/* Tabs */
.monthlyviews {
  margin: 1rem 0;
}
.monthlyviews .react-tabs__tab-list {
  height: 36px;
  background: #fafafa;
  border: 1px solid #f0f0f0;
  display: flex;
  justify-content: space-between;
  border-radius: 0.3rem;
  overflow: hidden;
}
.monthlyviews .react-tabs__tab-list li.react-tabs__tab--selected {
  border: 1px solid rgba(21, 21, 21, 0.16);
  background: #f0f0f0;
  border-radius: 0;
}
.monthlyviews .react-tabs__tab-list li {
  flex: 0 0 8.33%;
  max-width: 8.33%;
  font-size: 12px;
  text-align: center;
  margin: -1px 0;
  height: 100%;
}
p {
  color: #151515;
}
.statwrap {
  border-radius: 0.3rem;
  overflow: hidden;
  background: #fff;
  border: 1px solid rgba(21, 21, 21, 0.06);
}
.monthlyviews .ant-col {
  border-right: 1px solid rgba(21, 21, 21, 0.06);
}
.monthlyviews .ant-col:last-child {
  border-right: none;
}

.tabs-months .react-tabs .react-tabs__tab-list {
  height: 36px;
  border: 1px solid rgba(21, 21, 21, 0.06);
  width: 98%;
  margin: 0 auto 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.tabs-months .react-tabs .react-tabs__tab-list .react-tabs__tab {
  padding: 0;
  line-height: 1;
  border: 0;
}
.tabs-view .tabscontentwrap {
  padding: 1.5rem;
  border: 1px solid rgba(21, 21, 21, 0.16);
  background: #fff;
}
.tabs-view .react-tabs .react-tabs__tab-list {
  margin: 0 0 -1px;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  height: 46px;
  line-height: 1;
  padding: 0;
  background: none;
  border-radius: 0;
  overflow: hidden;
  z-index: 3;
  position: relative;
}
.tabs-view .react-tabs .react-tabs__tab-list .react-tabs__tab {
  padding: 0 1rem;
  background: #151515;
  color: #fff;
  border-radius: 0.3rem 0.3rem 0 0;
  height: 100%;
}
.tabs-view
  .react-tabs
  .react-tabs__tab-list
  .react-tabs__tab.react-tabs__tab--selected {
  color: #151515;
  background: #fff;
}
.tabs-view
  .react-tabs
  .react-tabs__tab-list
  .react-tabs__tab.react-tabs__tab--selected {
  color: #151515;
  background: #fff;
  border: 1px solid rgba(21, 21, 21, 0.16);
  border-bottom: 0;
}
.tabs-view .react-tabs .react-tabs__tab--selected::after {
  display: none;
}
.tabs-view .react-tabs__tab-panel {
  position: relative;
  z-index: 1;
}

.form-group input[type="datetime-local"] {
  min-width: 100%;
}

/* Viewing Feedback Blocker */
.viewfeedback-modal .ant-modal-content {
  padding: 0;
  max-height: 70vh;
  overflow-y: scroll;
}
.viewfeedback-modal .title {
  background-color: #fff;
  width: 100%;
  padding: 20px 24px 0;
}
.viewfeedback-modal .ant-modal-content .ant-modal-body {
  background-color: #f8fafb;
}
.viewfeedback-modal .ant-modal-body > .ant-row {
  padding: 20px 24px 0;
}
.viewfeedback-modal .ant-modal-footer {
  padding: 10px 24px;
  margin: 0;
}
.viewfeedback-modal .vfeedbackgroup {
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 0.8rem;
}
.viewfeedback-modal .vfeedbackgroup:last-child {
  border-bottom: 0;
}
.viewfeedback-modal .vfeedbackgroup p {
  color: #151515;
}
.viewfeedback-modal textarea {
  font-family: "Poppins", sans-serif;
}

/* All Offers */
.alloffers .ant-table-tbody .ant-table-measure-row td,
.tablelistwrap
  div.ant-table-wrapper
  .ant-table-tbody
  > tr.ant-table-measure-row
  > td:first-child {
  padding: 0 !important;
}
.alloffers .clientwrap a {
  white-space: normal;
}
.alloffers .ant-table.ant-table tr {
  box-shadow: none;
}
.alloffers .tabs-months .react-tabs .react-tabs__tab-list {
  width: 100%;
  padding: 0;
}
.alloffers .tabs-months .react-tabs .react-tabs__tab-list .react-tabs__tab {
  padding: 0 1rem;
}
.menurequest a {
  color: #151515;
}
.alloffers .tablelistwrap .ant-table-wrapper {
  padding: 0;
}
.alloffers .ant-table-body {
  overflow-y: hidden !important;
}
.alloffers .ant-table-wrapper .ant-table-tbody > tr.ant-table-measure-row > td {
  padding: 0 !important;
}

#sortable {
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 60%;
}
#sortable li {
  margin: 0 3px 3px 3px;
  padding: 0.4em;
  padding-left: 1.5em;
  font-size: 1.4em;
  height: 18px;
}
#sortable li span {
  position: absolute;
  margin-left: -1.3em;
}

/* Listing Process */
.info-group-check {
  display: flex;
  gap: 0.6rem;
  align-items: baseline;
}
.info-group-check .anticon.anticon-check {
  color: #1bdd77;
}
.info-group-check .anticon.anticon-close {
  color: #ff0000;
}
.listingprocess-wrap .info-check a:hover {
  text-decoration: underline;
}

.seogroup .form-group textarea:focus {
  border: 1px solid rgba(21, 21, 21, 0.16) !important;
}

.react-tabs {
  width: 100%;
}

/* Image Tab- */
.imgupgrp .list {
  display: flex;
  flex-wrap: wrap;
}
.imgupgrp {
  --bs-gutter-x: 0;
}
.imgupgrp > div {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
.dzu-submitButton {
  display: none;
}
.upload-group .dzu-dropzone {
  border: 1px dashed rgba(21, 21, 21, 0.06);
  background: rgba(21, 21, 21, 0.01);
  margin: 1rem 0;
}
.upload-group .dzu-dropzone:hover {
  opacity: 0.6;
  border-color: rgba(21, 21, 21, 1);
}
.upload-group .dzu-dropzone label.dzu-inputLabel {
  font-size: 12px;
  color: #151515;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}
.ui-sortable-handle,
.sortable-container.ui-sortable > div {
  flex: 0 0 31.33%;
  max-width: 31.33%;
  display: flex;
  flex-direction: column;
  background: #fff;
  border: 1px solid rgba(21, 21, 21, 0.06);
  cursor: grab;
}
.imgupgrp .input-group label {
  font-size: 0.73rem;
  margin-left: 0.3rem;
}
.upload-group .dzu-previewImage {
  max-width: 240px;
  max-height: 100px;
}

.imgupgrp .ui-sortable-handle > div:nth-child(2) {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 0.5rem;
}
.imgupgrp .ui-sortable-handle button i {
  font-size: 14px;
}
.ui-sortable-handle div.input-group {
  width: unset;
  margin-top: 5px;
}
.ant-table-wrapper .ant-table-tbody > tr > td.whitespacenormal {
  white-space: normal !important;
}
.ui-sortable-handle > .form-group {
  width: 90%;
  margin: 0 auto;
}
.ui-sortable-handle > .d-flex.flex-row.justify-content-between {
  width: 90%;
  margin: 0 auto;
}
.ui-sortable-handle.ui-sortable-helper {
  box-shadow: 0 0 6px rgba(21, 21, 21, 0.16);
}
.ui-sortable-handle .input-group .form-check-input:checked {
  background-color: #151515;
  border-color: #151515;
}

/* New Listing V2 */
.listingfilter-wrap {
  background: #fff;
  border: 0;
  width: 100%;
  margin: 0 auto !important;
  padding: 0.5rem 0;
}
.listingfilter-second {
  width: 97.9%;
  margin: 1rem auto 0 !important;
}
.listingfilter-third {
  background: #fff;
  width: 97.9%;
  margin: 5px auto !important;
}
.listingfilter-four {
  width: 97.9%;
  margin: 1rem auto 0 !important;
}

.tblistingwrap.tablelistwrap .ant-table-wrapper .ant-table-tbody > tr > td {
  padding: 0.2rem 5px !important;
  border-bottom: 1px solid rgba(21, 21, 21, 0.16) !important;
  border-top: 1px solid rgba(21, 21, 21, 0.16) !important;
}
.tblistingwrap.tablelistwrap .ant-table-wrapper .ant-table {
  border: none;
}
.tblistingwrap.tablelistwrap
  .ant-table-wrapper
  .ant-table-tbody
  > tr
  > td.ant-table-selection-column {
  border-left: 1px solid rgba(21, 21, 21, 0.16) !important;
}
.tblistingwrap .ant-table-wrapper .ant-table-content table {
  border-right: 0 !important;
}
.tblistingwrap.tablelistwrap
  .ant-table-wrapper
  .ant-table-tbody
  > tr
  > td:first-child {
  border-left: 1px solid rgba(21, 21, 21, 0.16) !important;
}
.tblistingwrap.tablelistwrap
  .ant-table-wrapper
  .ant-table-tbody
  > tr
  > td:last-child {
  border-right: 1px solid rgba(21, 21, 21, 0.16) !important;
}

.tablelistwrap.tblistingwrap
  div.ant-table-wrapper
  .ant-table-tbody
  > tr
  > td:first-child,
.tablelistwrap.tblistingwrap
  div.ant-table-wrapper
  .ant-table-thead
  > tr
  > th:first-child {
  padding: 0.2rem 5px !important;
}
.tablelistwrap.tblistingwrap .ant-table-wrapper {
  padding: 0;
}
.listingimagewrap .slick-slider .slick-track::before {
  display: none;
}
.listingimagewrap .ant-carousel .slick-dots-bottom {
  bottom: 0;
  width: 90%;
  margin: 0 auto 5px;
}
.listingimagewrap .ant-carousel .slick-dots li button {
  opacity: 0.5;
}
.noimageyet {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  margin: 0;
  text-align: center;
  height: 157px;
  justify-content: center;
  border: 1px solid rgba(21, 21, 21, 0.16);
  width: 280px;
}
.listingfilter-wrap .ant-select.ant-select-show-search .ant-select-selector {
  margin: 0;
  border: none;
  width: 100%;
}
/* select multiple */
.listingfilter-wrap
  .ant-select.ant-select-multiple.ant-select-show-search
  .ant-select-selector
  .ant-select-selection-item {
  height: 100%;
  line-height: 1.8 !important;
  font-size: 13px;
  width: 100%;
}
.listingfilter-wrap .ant-select-multiple .ant-select-selection-overflow-item {
  height: 100%;
  line-height: 1.8 !important;
  font-size: 13px;
}
.listingfilter-wrap
  .ant-select-multiple
  .ant-select-selection-overflow-item
  .ant-select-selection-search {
  padding: 0 3px;
}
.listingfilter-wrap .form-group input:focus {
  border: none !important;
}
/* select single */
.listingfilter-wrap
  .ant-select.ant-select-show-search
  .ant-select-selector
  .ant-select-selection-item {
  height: 100%;
  font-size: 13px;
}
.listingfilter-wrap .ant-select-selector .ant-select-selection-placeholder {
  font-size: 13px;
  color: #151515;
}

/* Checkbox */
.listingfilter-second .formcheckboxgroup {
  border-bottom: 1px solid rgba(21, 21, 21, 0.06);
  width: 100%;
  margin-bottom: 0;
  justify-content: space-between;
  align-items: flex-end;
}
.listingfilter-second .formcheckboxgroup .ant-checkbox-group {
  gap: 1rem;
  flex-wrap: nowrap;
}
.listingfilter-second .formcheckboxgroup .ant-checkbox-wrapper {
  position: relative;
  margin-bottom: 0;
  transition: 0.2s all ease-in-out;
  padding-left: 0.5rem;
}
.listingfilter-second
  .formcheckboxgroup
  .ant-checkbox-wrapper.ant-checkbox-wrapper-checked {
  padding-right: 1.2rem;
}
.listingfilter-second
  .formcheckboxgroup
  .ant-checkbox-wrapper.ant-checkbox-wrapper-checked:after {
  height: 2px;
  background: #151515;
  width: 100%;
  display: flex;
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
}
.listingfilter-second
  .formcheckboxgroup
  .ant-checkbox-wrapper.ant-checkbox-wrapper-checked
  span:after {
  content: "\F623";
  position: absolute;
  font-family: "bootstrap-icons";
  margin-left: 2px;
  right: 0;
}
.listingfilter-second .ant-checkbox-wrapper .ant-checkbox {
  display: none;
}
.listingfilter-second .formcheckboxgroup .ant-checkbox-wrapper span {
  white-space: nowrap;
  padding: 0.3rem;
}
.listingfilter-second
  .formcheckboxgroup
  .ant-checkbox-wrapper.ant-checkbox-wrapper-checked
  span {
  font-family: "Poppins-Bold", sans-serif;
}

.listingfilter-third .form-label {
  white-space: nowrap;
}
.closepop {
  position: relative;
  right: 0;
}

.listingfilter-wrap .price-wrap .form-select {
  border: none;
  width: fit-content;
  border-right: 1px solid rgba(21, 21, 21, 0.06);
  border-radius: none !important;
  font-size: 13px;
  border-radius: 0;
}
.listingfilter-wrap .price-wrap .form-control {
  border-radius: none;
}
.listingfilter-wrap .price-wrap p,
.listingfilter-wrap .unitwrap p {
  background-color: rgba(21, 21, 21, 0.1) !important;
}
.listingfilter-wrap .price-wrap .selectwrap {
  position: relative;
}
.listingfilter-wrap .price-wrap .selectwrap:after {
  content: "\F282";
  position: absolute;
  font-family: "bootstrap-icons";
  margin-left: 2px;
  right: 0;
  width: 23px;
  opacity: 0.5;
  top: 50%;
  transform: translateY(-50%);
}
.priceinrangewrap .form-control {
  border: none;
  border-radius: 0;
  width: 48% !important;
}
.listingfilter-wrap .form-group .priceinputwrap input:focus {
  border: none !important;
  border-radius: 0;
}

.listingimagewrap .anticon.anticon-left-circle {
  position: absolute;
  z-index: 9;
  top: 50%;
  left: 13px;
  transform: translateY(-50%);
  color: #fff;
}
.listingimagewrap .anticon.anticon-right-circle {
  position: absolute;
  z-index: 9;
  top: 50%;
  right: 13px;
  transform: translateY(-50%);
  color: #fff;
}

.listingfilter-wrap .propertyfor-wrap .ant-checkbox {
  display: none;
}
.listingfilter-wrap
  .propertyfor-wrap
  .ant-checkbox-wrapper.ant-checkbox-group-item {
  height: 36px;
  border: 1px solid rgba(21, 21, 21, 0.06);
  line-height: 36px;
  font-size: 13px;
  margin-bottom: 0;
  border-radius: 0.3rem;
  width: 5rem;
  justify-content: center;
  align-content: center;
}
.listingfilter-wrap
  .propertyfor-wrap
  .ant-checkbox-wrapper.ant-checkbox-group-item
  span {
  line-height: 36px;
}
.listingfilter-wrap
  .ant-select.ant-select-show-search
  .ant-select-selector:after {
  content: "\F282";
  position: absolute;
  font-family: "bootstrap-icons";
  margin-left: 2px;
  right: 0;
  width: 23px;
  opacity: 0.5;
}
.listingfilter-wrap .ant-select .ant-select-arrow .anticon > svg {
  display: none;
}
.listingfilter-wrap .ant-select .ant-select-arrow:after {
  content: "\F282";
  position: absolute;
  font-family: "bootstrap-icons";
  margin-left: 2px;
  right: 0;
  width: 25px;
  top: 48%;
  font-size: 14px;
}
.listingfilter-wrap input.form-control {
  font-size: 13px;
  color: #151515;
  font-family: "Poppins", sans-serif;
}
.listingfilter-wrap input.form-control:focus {
  box-shadow: none;
  border: 1px solid #151515 !important;
  border-radius: 0;
}
.listingfilter-wrap .price-wrap .form-select:focus {
  box-shadow: none;
}
.listingfilter-wrap .matchbedroom {
  position: relative;
}
.listingfilter-wrap .matchbedroom:after {
  content: "\F282";
  position: absolute;
  font-family: "bootstrap-icons";
  margin-left: 2px;
  right: 0;
  width: 23px;
  opacity: 0.5;
  top: 50%;
  transform: translateY(-50%);
}
.listingfilter-second .formcheckboxgroup .ant-checkbox-wrapper span:before {
  content: "";
  width: 9px;
  height: 9px;
  border-radius: 50%;
  display: flex;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background: #151515;
}
.listingfilter-second
  .formcheckboxgroup
  .ant-checkbox-wrapper:first-child
  span {
  color: #25d366;
}
.listingfilter-second
  .formcheckboxgroup
  .ant-checkbox-wrapper:first-child
  span:before {
  background-color: #25d366;
}
.listingfilter-second
  .formcheckboxgroup
  .ant-checkbox-wrapper:nth-child(2)
  span {
  color: #ff9f0a;
}
.listingfilter-second
  .formcheckboxgroup
  .ant-checkbox-wrapper:nth-child(2)
  span:before {
  background-color: #ff9f0a;
}
.listingfilter-second
  .formcheckboxgroup
  .ant-checkbox-wrapper:nth-child(3)
  span {
  color: #bf5af2;
}
.listingfilter-second
  .formcheckboxgroup
  .ant-checkbox-wrapper:nth-child(3)
  span:before {
  background-color: #bf5af2;
}
.listingfilter-second
  .formcheckboxgroup
  .ant-checkbox-wrapper:nth-child(4)
  span {
  color: #8a8a8a;
}
.listingfilter-second
  .formcheckboxgroup
  .ant-checkbox-wrapper:nth-child(4)
  span:before {
  background-color: #8a8a8a;
}
.listingfilter-second
  .formcheckboxgroup
  .ant-checkbox-wrapper:nth-child(5)
  span {
  color: #9e0059;
}
.listingfilter-second
  .formcheckboxgroup
  .ant-checkbox-wrapper:nth-child(5)
  span:before {
  background-color: #9e0059;
}
.listingfilter-second .leadgengrp label:before {
  content: "";
  width: 9px;
  height: 9px;
  border-radius: 50%;
  display: flex;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background: #f74972;
}
.listingfilter-second
  .formcheckboxgroup
  .leadgengrp
  .ant-checkbox-wrapper:first-child
  span {
  color: #f74972;
}
.listingfilter-second
  .formcheckboxgroup
  .leadgengrp
  .ant-checkbox-wrapper:first-child
  span::before {
  background: #f74972;
}
.listingfilter-second
  .formcheckboxgroup
  .pledge
  .ant-checkbox-wrapper:first-child
  span {
  color: #1da3a4;
}
.listingfilter-second
  .formcheckboxgroup
  .pledge
  .ant-checkbox-wrapper:first-child
  span::before {
  background: #1da3a4;
}
.listingfilter-second
  .formcheckboxgroup
  .pond
  .ant-checkbox-wrapper:first-child
  span {
  color: #ff002b;
}
.listingfilter-second
  .formcheckboxgroup
  .pond
  .ant-checkbox-wrapper:first-child
  span::before {
  background: #ff002b;
}
.listingfilter-second
  .formcheckboxgroup
  .refreceived
  .ant-checkbox-wrapper:first-child
  span {
  color: #00af91;
  padding: 0 8px;
}
.listingfilter-second
  .formcheckboxgroup
  .refreceived
  .ant-checkbox-wrapper:first-child
  span::before {
  background: #00af91;
}
.listingfilter-second
  .formcheckboxgroup
  .refSent
  .ant-checkbox-wrapper:first-child
  span {
  color: #007965;
  padding: 0 8px;
}
.listingfilter-second
  .formcheckboxgroup
  .refSent
  .ant-checkbox-wrapper:first-child
  span::before {
  background: #007965;
}
/* Sold/Rent by LP */
.listingfilter-second
  .formcheckboxgroup
  .refineSoldRent
  .ant-checkbox-wrapper:first-child
  span {
  color: #0353a4;
}
.listingfilter-second
  .formcheckboxgroup
  .refineSoldRent
  .ant-checkbox-wrapper:first-child
  span::before {
  background: #007965;
}
.listingfilter-second
  .formcheckboxgroup
  .refineSoldRent
  .ant-checkbox-wrapper:nth-child(2)
  span {
  color: #5d2e8c;
}
.listingfilter-second
  .formcheckboxgroup
  .refineSoldRent
  .ant-checkbox-wrapper:nth-child(2)
  span::before {
  background: #5d2e8c;
}
.listingfilter-second
  .formcheckboxgroup
  .refineSoldRent
  .ant-checkbox-wrapper:nth-child(3)
  span {
  color: #129490;
}
.listingfilter-second
  .formcheckboxgroup
  .refineSoldRent
  .ant-checkbox-wrapper:nth-child(3)
  span::before {
  background: #129490;
}
.listingfilter-second
  .formcheckboxgroup
  .archive
  .ant-checkbox-wrapper:first-child
  span {
  color: #1b5299;
}
.listingfilter-second
  .formcheckboxgroup
  .archive
  .ant-checkbox-wrapper:first-child
  span::before {
  background: #1b5299 !important;
}

/* Index Status */
.listingfilter-second
  .formcheckboxgroup
  .indexstat
  .ant-checkbox-wrapper:first-child
  span {
  color: #8f2d56;
}
.listingfilter-second
  .formcheckboxgroup
  .indexstat
  .ant-checkbox-wrapper:first-child
  span:before {
  background-color: #8f2d56;
}
.listingfilter-second
  .formcheckboxgroup
  .indexstat
  .ant-checkbox-wrapper:nth-child(2)
  span {
  color: #38b000;
}
.listingfilter-second
  .formcheckboxgroup
  .indexstat
  .ant-checkbox-wrapper:nth-child(2)
  span:before {
  background-color: #38b000;
}
.listingfilter-second
  .formcheckboxgroup
  .indexstat
  .ant-checkbox-wrapper:nth-child(3)
  span {
  color: #f26419;
}
.listingfilter-second
  .formcheckboxgroup
  .indexstat
  .ant-checkbox-wrapper:nth-child(3)
  span:before {
  background-color: #f26419;
}

.listingfilter-four .matchnoviewings.form-group .ant-checkbox {
  display: none;
}
.listingfilter-four .matchnoviewings {
  display: flex;
  flex-direction: row;
}
.listingfilter-four
  .matchnoviewings
  .ant-checkbox-wrapper.ant-checkbox-wrapper-checked
  span {
  font-family: "Poppins-Bold", sans-serif;
}
.listingfilter-four
  .matchnoviewings
  .ant-checkbox-wrapper.ant-checkbox-wrapper-checked
  span:before {
  content: "\F272";
  position: relative;
  left: 0;
  margin-right: 5px;
  font-family: "bootstrap-icons";
  font-size: 16px;
  top: 4px;
}

/* Filters by Leads, Viewings, Offers, Match Leads, Last Call */
/* Using POPOVER antd */
.popoverclose {
  position: absolute;
  right: 1rem;
  top: 5px;
}
.countsfilterwrap .titlefilter {
  font-size: 0.73rem;
  margin: 0 2px 0 0;
}
.countsfilterwrap button.ant-btn-primary {
  border: none;
  padding: 0 0.5rem;
  font-size: 0.73rem;
  height: unset;
  box-shadow: none;
  border-right: 1px solid rgba(21, 21, 21, 0.16);
  border-radius: 0;
  margin: 0 0.2rem;
}
.countsfilterwrap button.ant-btn-primary:last-child {
  border: none;
}
.countsfilterwrap button.ant-btn-primary span {
  font-size: 0.73rem;
}
.countsfilterwrap button.ant-btn-primary:hover,
.countsfilterwrap button.ant-btn-primary:focus,
.countsfilterwrap button.ant-btn-primary:active {
  background: none !important;
  box-shadow: none;
  outline: none;
  color: #151515;
}
.countsfilterwrap button.ant-btn-primary:hover:after,
.countsfilterwrap button.ant-btn-primary:focus:after {
  content: "";
  position: absolute;
  height: 2px;
  background-color: #151515;
  width: 100%;
  bottom: -5px;
  left: 0;
}
.countsfilterwrap {
  display: flex;
  flex-direction: row;
}

/* Filter by dates - Available Date, Added Date, Published Date */
.datesfilter-wrap {
  align-items: center;
  margin-top: 1rem !important;
}
.datesfilter-wrap .d-flex {
  align-items: center;
  justify-content: space-between;
  border: 1px solid rgba(21, 21, 21, 0.16);
  border-radius: 0.3rem;
  height: 31px;
  overflow: hidden;
}
.datesfilter-wrap .titledate {
  font-size: 11px;
  white-space: nowrap;
  margin-bottom: 0;
  background: rgba(21, 21, 21, 0.1);
  padding: 5px 5px 5px;
}
.datesfilter-wrap .ant-picker input,
.datesfilter-wrap .form-check label {
  font-size: 0.73rem;
}
.datesfilter-wrap .ant-picker {
  border: none;
  box-shadow: none;
}
.datesfilter-wrap .form-check label {
  white-space: nowrap;
  padding-left: 0;
}
.datesfilter-wrap .form-check-input[type="radio"] {
  width: 12px;
  height: 12px;
  margin-left: -1.2em;
  margin-top: 0;
  top: 50%;
  transform: translateY(-50%);
}
.datesfilter-wrap .form-check .form-control,
.datesfilter-wrap .form-check .form-select {
  border-top: none !important;
  border-bottom: none !important;
  border-right: none !important;
  border-radius: 0;
}
.datesfilter-wrap .form-check .form-control:hover,
.datesfilter-wrap .form-check .form-select:hover,
.datesfilter-wrap .form-check .form-control:focus,
.datesfilter-wrap .form-check .form-select:focus {
  box-shadow: none;
  border-left: 1px solid rgba(21, 21, 21, 0.16) !important;
}

/* Listing Carousel */
.listingimagewrap .ant-carousel .slick-arrow {
  left: 0;
  inset-inline-start: 0;
  z-index: 99;
  position: absolute;
  top: 50%;
  margin-top: 0;
  color: #fff;
  transform: translateY(-50%);
  font-size: 18px;
}
.listingimagewrap .ant-carousel .slick-arrow.slick-prev {
  left: 0;
  inset-inline-start: 0;
}
.listingimagewrap .ant-carousel .slick-arrow.slick-next {
  right: 0;
  inset-inline-end: 0;
  left: unset;
}

.listingfilter-wrap .ant-input-affix-wrapper.form-control {
  display: flex;
  flex-direction: row;
  padding: 0 10px;
}
/* .listingfilter-wrap .ant-input-affix-wrapper.form-control .ant-input { height: 34px; font-size: 13px;font-family: "Poppins", sans-serif; color: #151515;  } */
.listingfilter-wrap .ant-input-affix-wrapper.form-control:focus,
.listingfilter-wrap .ant-input-affix-wrapper.form-control:hover,
.listingfilter-wrap .ant-input-affix-wrapper.form-control:focus-within {
  box-shadow: none;
  border-color: #151515;
}
.listingfilter-wrap
  .ant-input-affix-wrapper.form-control
  .ant-input::placeholder {
  color: #151515;
  font-family: "Poppins-Regular", sans-serif;
}

/* View as section */
.viewas-wrap p {
  font-size: 12px;
  font-family: "Poppins-Bold", sans-serif;
  margin-bottom: 0;
  padding-left: 5px;
}
.viewas-wrap .viewswitcher {
  border: 1px solid rgba(21, 21, 21, 0.16);
  align-items: center;
  gap: 0.6rem;
}
.viewas-wrap .viewswitcher .ant-btn:hover,
.viewas-wrap .viewswitcher .ant-btn:focus {
  border-radius: 0;
}
.viewswitcher-btn .ant-btn.activeview {
  background: #151515;
  border-radius: 0;
  color: #fff;
}

.topbtnswrap .ant-btn span,
.topbtnswrap .ant-btn.addfilter span {
  font-size: 13px;
}
.ant-input-clear-icon {
  line-height: 1;
}

.unitwrap .form-select {
  border-top: none;
  border-bottom: none;
  border-left: none;
  border-radius: 0;
  width: fit-content;
}
.unitwrap.form-group input {
  border: none;
}
.listingfilter-wrap .unitwrap .form-select:focus,
.listingfilter-wrap .unitwrap .form-select:hover {
  box-shadow: none;
  border: none !important;
  border-right: 1px solid #e3e3e3 !important;
}

.tablelistwrap.tblistingwrap .ant-table-wrapper .ant-table-content table {
  border-spacing: 0 1.2rem;
}

.zerocolor {
  color: #ff0000 !important;
  font-family: "Poppins-Semibold", sans-serif;
}
.newmatchcolor {
  color: #25d366 !important;
  font-family: "Poppins-Semibold", sans-serif;
}

.listingagentwrap {
  max-width: 80px;
}
.listingagentwrap .agentwrap {
  display: flex;
  flex-direction: row;
  gap: 0.3rem;
  align-items: center;
}
.listingagentwrap .agentwrap p {
  white-space: nowrap;
  margin: 0;
  justify-content: center;
  display: flex;
}
.tblistingwrap
  .ant-table-wrapper
  .ant-table-tbody
  > tr
  > td.ant-table-cell.actionwrap {
  border-left: 1px solid rgba(21, 21, 21, 0.16) !important;
}
.tabtimeline .react-tabs .react-tabs__tab-list .react-tabs__tab {
  height: 29px;
  line-height: 1;
  border: none;
}
.tabtimeline .react-tabs .react-tabs__tab-list {
  background: none;
  border-bottom: 1px solid rgba(21, 21, 21, 0.06);
  padding: 0;
  height: unset;
  border-radius: 0;
  justify-content: space-between;
}
.tabtimeline .react-tabs__tab-panel--selected {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.3rem;
}
.tabtimeline .react-tabs .react-tabs__tab-panel button {
  height: 36px;
  width: fit-content;
  margin: 0 auto;
  margin-top: 0px;
  font-size: 12px;
  display: flex;
  align-content: center;
  justify-content: center;
}
.tabtimeline .react-tabs .react-tabs__tab-panel button i {
  line-height: 1;
}

.timeline.border-wrap.tabtimeline {
  min-height: 26.55rem;
}

/* Popups */
.ant-modal .ant-modal-content {
  padding: 0;
}
.ant-modal .ant-modal-header {
  padding: 10px 24px;
  border-bottom: 1px solid rgba(21, 21, 21, 0.16);
  box-shadow: 0 2px 5px rgba(21, 21, 21, 0.06);
}
.ant-modal .ant-modal-body {
  background: rgba(21, 21, 21, 0.01);
  padding: 20px 24px 18px;
  margin: -8px 0;
}
.ant-modal .ant-modal-footer {
  padding: 10px 24px;
  border-top: 1px solid rgba(21, 21, 21, 0.16);
  box-shadow: 0 -3px 5px rgba(21, 21, 21, 0.06);
}

.ant-modal .ant-modal-content textarea,
.ant-modal .ant-modal-content .ant-input,
.ant-modal .ant-modal-content input {
  font-family: "Poppins-Regular", sans-serif;
}

.tabtimeline #calllogbtn {
  background: #151515;
  color: #fff;
}
.tabtimeline #calllogbtn span,
.tabtimeline #calllogbtn i.bi {
  color: #fff;
}

/* Avatar */
.agentwrap .ant-avatar,
.agentwrap .ant-avatar .ant-avatar {
  margin: 0;
  position: relative;
  top: 0;
  border: none;
}

/* Match Pages */
.contentwrap .blacktabs {
  padding: 0.3rem 1rem 0;
  margin-top: 0.6rem;
}
.blacktabs .react-tabs__tab-list {
  margin-bottom: 0;
  border-color: rgba(21, 21, 21, 0.16);
  display: flex;
  gap: 1rem;
}
.blacktabs .blacktabs-content {
  border-left: 1px solid rgba(21, 21, 21, 0.16);
  padding-top: 1.3rem;
  border-right: 1px solid rgba(21, 1, 21, 0.16);
  border-bottom: 1px solid rgba(21, 21, 21, 0.16);
  margin-bottom: 3rem;
}
.blacktabs .react-tabs__tab-list .react-tabs__tab {
  border-radius: 0.3rem 0.3rem 0 0;
  background: #151515;
  color: #fff;
  gap: 1rem;
  display: flex;
  transition: 0.2s all ease-in-out;
}
.blacktabs .react-tabs__tab-list .react-tabs__tab.react-tabs__tab--selected {
  color: #151515;
  background: #fff;
  border: 1px solid rgba(21, 21, 21, 0.16);
  border-bottom: none;
  position: relative;
}
.blacktabs
  .react-tabs__tab-list
  .react-tabs__tab.react-tabs__tab--selected::before {
  background-color: #fff;
  height: 2px;
  position: absolute;
  bottom: 0;
}

.listingfilter-second .pledgegrp label:before {
  content: "";
  width: 9px;
  height: 9px;
  border-radius: 50%;
  display: flex;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background: #1da3a4;
}
.listingfilter-second
  .formcheckboxgroup
  .pledgegrp
  .ant-checkbox-wrapper:first-child
  span {
  color: #1da3a4;
}
.listingfilter-second
  .formcheckboxgroup
  .pledgegrp
  .ant-checkbox-wrapper:first-child
  span::before {
  background: #1da3a4;
}

/* =================== */
/* NEW Edit Listing V2 */
/* =================== */

/* HIDE SHOW TRIGGER */
.listtimelinehide {
  background: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: -10px;
  width: 20px;
  height: 20px;
  box-shadow: 0 0 6px rgba(21, 21, 21, 0.16);
  top: 5px;
  padding-right: 1px;
}

.editlistingv2-wrap.cont-wrap .ant-row > .ant-col {
  border: none;
}

.editwrap .form-group {
  display: flex;
  flex-direction: row;
  gap: 0.3rem;
  align-items: baseline;
  margin-bottom: 5px;
}
.editwrap .form-group label {
  font-size: 12px;
  margin-bottom: 0;
}
.editwrap .form-group .styles-module_Editext__main_container__2azCD,
.editwrap .form-group .styles-module_Editext__view_container__3oSYx {
  border: none;
  height: fit-content;
  width: 100%;
  align-items: baseline;
}
.editwrap .form-group .styles-module_Editext__view_container__3oSYx > div {
  font-family: "Poppins-Bold", sans-serif;
  font-size: 12px;
}
.editwrap .styles-module_Editext__view_container__3oSYx {
  justify-content: space-between;
}
.editwrap .ant-collapse {
  background: transparent;
  border: none;
  width: 96%;
}
.editwrap .ant-collapse > .ant-collapse-item {
  border: none;
}
.editwrap.ownerdetails
  .ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header {
  padding: 0;
  margin-top: 1rem;
}
.editwrap.ownerdetails
  .ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-header-text {
  font-size: 12px;
}
.editwrap .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 0;
}
.editwrap
  .ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-header-text {
  display: flex;
  flex-direction: row;
  gap: 0.3rem;
  align-items: center;
}
.cntwrap {
  background: rgba(21, 21, 21, 0.06);
  border-radius: 50%;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}

.edit-details .infogrp {
  display: flex;
  flex-direction: row;
}
.edit-details .infogrp p {
  font-size: 11px;
  margin-bottom: 0;
}
.rowzero {
  padding: 1rem 1rem 0.8rem;
}
.rowone {
  border-bottom: 1px solid rgba(21, 21, 21, 0.16);
  padding: 0 1rem 0.8rem;
}
.rowone .ant-col {
  gap: 0.6rem;
  border: none !important;
}
.editwrap .noimageyet {
  width: 100%;
}
.edittabs .react-tabs .react-tabs__tab-list {
  height: 36px;
  background: none;
  overflow: hidden;
  overflow-y: hidden;
  border-bottom: 1px solid rgba(21, 21, 21, 0.06);
  border-radius: 0;
  justify-content: space-between;
  padding: 0;
  margin-top: 1rem;
  margin-bottom: 0;
}
.editdetailswrap .input-group-text label {
  font-size: 11px;
}
.editdetailswrap > .ant-col > .ant-row {
  border-bottom: 1px solid rgba(21, 21, 21, 0.06);
  align-items: center;
  padding: 0.5rem 0;
}
.editdetailswrap > .ant-col > .ant-row .form-group {
  margin-bottom: 5px;
}
.editdetailswrap .form-group .ant-select .ant-select-selector {
  border: none;
}
.editdetailswrap .ant-select .ant-select-arrow {
  display: none;
}
.editdetailswrap .react-tabs .react-tabs__tab-list .react-tabs__tab {
  border-right: 0;
}
/* Agent avatar */
.avatar-group .ant-avatar,
.avatar-group .ant-avatar .ant-avatar {
  background: transparent;
  border: none;
  margin: 0;
  margin-right: 0px;
  position: relative;
  top: 0;
  width: 50px;
  height: 50px;
  line-height: 1;
  font-size: 0;
}
.ant-avatar > img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.editdetailswrap
  .avatar-group
  .ant-select.ant-select-single.ant-select-show-arrow {
  width: unset !important;
  margin-left: 5px;
  align-items: center;
  display: flex;
}

/* Dropdowns for Edit Wrap */
.editdetailswrap .form-group label {
  font-size: 10px;
  margin-bottom: 0;
}
.editdetailswrap .ant-select .ant-select-selector {
  padding: 0;
}
.editdetailswrap .ant-select .ant-select-selector .ant-select-selection-item {
  font-size: 13px;
  font-family: "Poppins-Bold", sans-serif;
  line-height: 32px;
  height: 32px;
  padding-inline-end: 0;
}
/* .editdetailswrap .ant-select .ant-select-selector .ant-select-selection-item::after {content: '\F4C9';right: 5px; position: absolute; width: 15px;font-family: "bootstrap-icons"; transform: rotate(-90deg); top: 15%; font-size: 10px;} */
.editdetailswrap
  .ant-select
  .ant-select-selector
  .ant-select-selection-placeholder {
  font-size: 13px;
  line-height: 32px;
  height: 32px;
  color: #151515;
}

/* edit listing status */
.form-group .stat.ant-select .ant-select-selector {
  background: none;
  border: none;
  padding: 0;
}
.form-group .stat.ant-select .ant-select-selector .ant-select-selection-item {
  font-size: 12px;
  padding: 0 2.5rem 0 0.5rem;
  font-family: "Poppins", sans-serif;
}
.editdetailswrap .ant-select.stat::after {
  content: "\F4C9";
  right: 5px;
  position: absolute;
  width: 15px;
  font-family: "bootstrap-icons";
  transform: rotate(-90deg);
  top: 15%;
  font-size: 10px;
}
.form-group
  .ant-select.stat.listingstat-uc
  .ant-select-selector
  .ant-select-selection-item {
  color: #c51162;
  background: rgba(197, 17, 98, 0.16);
}
.form-group .ant-select.stat.listingstat-uc::after {
  color: #c51162;
}
.form-group
  .ant-select.stat.listingstat-ctm
  .ant-select-selector
  .ant-select-selection-item {
  color: #ff9f0a;
  background-color: rgba(255, 159, 10, 0.15);
}
.form-group .ant-select.stat.listingstat-ctm::after {
  color: #ff9f0a;
}
.form-group
  .ant-select.stat.listingstat-y
  .ant-select-selector
  .ant-select-selection-item {
  color: #25d366;
  background-color: rgba(37, 211, 102, 0.15);
}
.form-group .ant-select.stat.listingstat-y::after {
  color: #25d366;
}

/* Input no border */
.editdetailswrap .form-group input {
  border: none !important;
  padding: 0;
  font-size: 13px;
  font-family: "Poppins-Bold", sans-serif;
  border-radius: 0;
  background: rgba(21, 21, 21, 0.02) !important;
}
.editdetailswrap .form-group input:focus,
.editdetailswrap .form-group input:hover {
  box-shadow: none;
  background: rgba(21, 21, 21, 0.02) !important;
}
.editdetailswrap .ant-select.ant-select-focused .ant-select-selector {
  box-shadow: none !important;
  border-radius: 0;
}
.edittabs .react-tabs .react-tabs__tab-list .react-tabs__tab {
  border: 0 !important;
  padding: 0 1px;
}

/* Dates */
.edit-details .form-group time {
  font-size: 13px;
  font-family: "Poppins-Bold", sans-serif;
  line-height: 32px;
  height: 32px;
}
.editdetailswrap .form-group .ant-picker {
  border: none;
  padding: 0;
  height: 32px;
  line-height: 32px;
}
.editwrap .ant-collapse-expand-icon {
  position: absolute;
  right: 0;
}
.editlistingv2-wrap .ck-content p {
  font-size: 12px;
}
.editlistingv2-wrap .generatebtn > span {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 0.3rem;
}
.editlistingv2-wrap .generatebtn span {
  font-size: 10px;
}
.editwrap.matchdetails .ant-collapse-content-box {
  padding: 0 10px 0 0;
}
.editlistingv2-wrap .unitlabel {
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 12px;
}
.editlistingv2-wrap .ck.ck-toolbar > .ck-toolbar__items {
  font-size: 11px;
}
.editlistingv2-wrap .matchfilter .form-group {
  display: flex;
  flex-direction: column;
  align-items: start;
  border-bottom: none;
  gap: unset;
}
.editlistingv2-wrap
  .matchfilter
  .ant-select.ant-select-show-search
  .ant-select-selector
  .ant-select-selection-item {
  line-height: 36px !important;
  font-size: 12px;
  height: 36px !important;
}
.editlistingv2-wrap
  .matchfilter
  .ant-select-selector
  .ant-select-selection-search
  input {
  border-radius: 0;
  padding: 0 10px;
}
.editlistingv2-wrap .ant-select.ant-select-show-search .ant-select-selector {
  border-radius: 0;
  padding: 0 0.5rem !important;
  font-size: 12px;
  height: 36px !important;
  border: 1px solid #e3e3e3;
}
.editlistingv2-wrap .ant-select-selector .ant-select-selection-placeholder {
  line-height: 26px !important;
}
.timexpandicon {
  position: absolute;
  left: -12px;
  background: #fff;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  align-content: center;
  justify-content: center;
  box-shadow: 0 0 3px rgba(21, 21, 21, 0.16);
  top: 5px;
}
.cont-wrap.editlistingv2-wrap > .ant-row > .ant-col:nth-child(2) {
  box-shadow: 0 0 5px rgba(221, 21, 21, 0.16);
}
.editlistingv2-wrap .editwrap.rentalwrap .ant-collapse-content-box {
  padding-left: 0;
}
.editlistingv2-wrap .editwrap.rentalwrap .form-group {
  flex-direction: column;
}
.editlistingv2-wrap .editwrap.rentalwrap .form-group label {
  font-size: 10px;
}
.editwrap.rentalwrap .form-group input {
  border-radius: 0;
}
.editlistingv2-wrap .ant-collapse-content-box .ant-btn span {
  font-size: 12px;
}

.editlistingv2-wrap
  .matchfilter
  .ant-select.ant-select-single.ant-select-show-arrow {
  padding: 0;
  border-radius: 0;
  height: 36px;
  line-height: 36px;
}
.editlistingv2-wrap .matchfilter .form-group label {
  white-space: nowrap;
  color: #151515;
  font-size: 10px;
  line-height: 2;
}
.editlistingv2-wrap .matchfilter .budget input {
  border: 1px solid #e3e3e3 !important;
  border-radius: 0;
  height: 36px;
  line-height: 36px;
}
.editlistingv2-wrap .form-group .ant-select .ant-select-selector {
  height: 36px !important;
  padding: 0 10px !important;
}
.editlistingv2-wrap
  .editdetailswrap
  .form-group
  .ant-select
  .ant-select-selector {
  padding: 0 8px !important;
  background-color: rgba(21, 21, 21, 0.03);
  border-radius: 0 !important;
}
.editlistingv2-wrap
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-search {
  inset-inline-start: 0;
  inset-inline-end: 0;
}
.editlistingv2-wrap
  .editdetailswrap
  .form-group.paymentfrequency
  .ant-select
  .ant-select-arrow,
.editlistingv2-wrap
  .editdetailswrap
  .form-group.noofcheques
  .ant-select
  .ant-select-arrow {
  display: block;
  top: 58%;
}

/* Timeline column */
.timeline-details {
  background-color: rgba(21, 21, 21, 0.02);
}
.ant-drawer .veditlisting .ant-drawer-body {
  padding: 0;
}
.timeline-details .anticon.anticon-caret-left {
  position: absolute;
  left: -0.6rem;
  background: #fff;
  color: #151515;
  border-radius: 50%;
  padding: 0 0.1rem 0 0;
  top: 0.3rem;
  text-align: center;
  box-shadow: 0 0 4px rgba(21, 21, 21, 0.3);
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.filteractivity {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.filteractivity p,
.filteractivity .ant-btn span {
  font-size: 10px;
}
.ant-mentions .rc-textarea {
  font-family: "Poppins-Regular", sans-serif;
  font-size: 12px;
}
.timeline-main-wrapper header h1 {
  font-size: 12px;
  color: #151515;
  font-weight: 400;
}
.timelinelist {
  padding: 1rem 1.5rem;
}
.timelinelist {
  font-size: 10px;
}
.timelinelist .ant-timeline-item-head.ant-timeline-item-head-custom {
  background: #fff;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  align-items: center;
  display: flex;
  justify-content: center;
}
.timelinelist .awriter {
  margin-bottom: 0;
  width: 85%;
  margin-left: 5px;
  font-size: 12px;
}
.timelinelist .ant-avatar > img {
  width: 100%;
  height: auto;
  top: 0;
  position: relative;
  border-radius: 0;
}
.editlistingv2-wrap
  .ant-collapse
  .ant-collapse-content
  > .ant-collapse-content-box {
  padding: 16px 10px 16px 0;
}
.editlistingv2-wrap .listtable .propadd,
.listtable .community {
  font-size: 10px;
}
.editlistingv2-wrap .publishstat span {
  white-space: nowrap;
}
/* Timelinecontent adesc */
.timelinelist .ant-timeline-item-content .adesc {
  background: #fff;
  border-radius: 0;
  border: 1px solid rgba(21, 21, 21, 0.06);
}
.timelinelist .ant-timeline-item-content .adesc p {
  font-size: 11px;
}
.timelinelist .ant-timeline-item-content .atime time {
  align-items: center;
}
.timelinelist .timephone .ant-timeline-item-content .adesc,
.timelinelist .timenote .ant-timeline-item-content .adesc {
  flex-direction: column;
}
.timelinelist
  .ant-timeline
  .ant-timeline-item:last-child
  .ant-timeline-item-tail {
  display: none;
}
/* status colors */
.timelinelist .ant-timeline-item-head.ant-timeline-item-head-custom i,
.timelinelist .ant-timeline-item-head.ant-timeline-item-head-custom svg {
  color: #fff;
  font-size: 10px;
}
/* note */
.timelinelist .timenote .ant-timeline-item-head.ant-timeline-item-head-custom {
  background: #ff8500;
}
/* phone */
.timelinelist .timephone .ant-timeline-item-head.ant-timeline-item-head-custom {
  background: #25d366;
}
/* offer */
.timelinelist .timeoffer .ant-timeline-item-head.ant-timeline-item-head-custom {
  background: #19c0cc;
}
/* Req_view */
.timelinelist
  .timereq_view
  .ant-timeline-item-head.ant-timeline-item-head-custom {
  background: #18af9d;
}
/* Followup */
.timelinelist
  .timefollowup
  .ant-timeline-item-head.ant-timeline-item-head-custom {
  background: #2153dd;
}

.editlistingv2-wrap .ant-collapse .pstat {
  background: transparent !important;
}
.ant-collapse-content .pstat .bi.bi-circle-fill {
  font-size: 8px;
  margin-right: 5px;
}

/* timeline tags */
.timereq_view .ant-timeline-item-content .adesc .ant-tag {
  margin-bottom: 0.5rem;
  transition: 0.3s all ease-in-out;
}
.timereq_view .ant-timeline-item-content .adesc .ant-tag:hover {
  background: #151515;
  border-color: #151515;
}
.timereq_view .ant-timeline-item-content .adesc .ant-tag a {
  text-decoration: none;
  font-size: 12px;
  color: #151515;
}
.timereq_view .ant-timeline-item-content .adesc .ant-tag:hover a {
  color: #fff;
}

/* Timeline Status */
.adesc-stat {
  display: flex;
  flex-direction: row;
  gap: 0.3rem;
  align-content: center;
}
.adesc-stat .pstat {
  width: fit-content;
  height: 25px;
  font-size: 11px;
  line-height: 1.2;
  font-family: "Poppins-Regular", sans-serif;
  margin: 0;
  color: #151515;
}
.adesc-stat .pstat.viewing {
  background-color: rgba(25, 192, 204, 0.16);
  color: #19c0cc;
  border-color: #19c0cc;
}
.adesc-stat .pstat.offer {
  background-color: rgba(37, 211, 102, 0.16);
  color: #25d366;
  border-color: #25d366;
}
.adesc-stat .pstat.secondviewing {
  background-color: rgba(37, 95, 211, 0.16);
  color: #255fd3;
  border-color: #255fd3;
}
.adesc-stat .pstat.deal {
  background-color: rgba(70, 138, 63, 0.16);
  color: #468a3f;
  border-color: #468a3f;
}
.adesc-stat .pstat.scheduled {
  background-color: rgba(255, 0, 0, 0.16);
  color: #f00;
  border-color: #f00;
}

/* Timeline Followup */
.timelinelist .timefollowup .ant-timeline-item-content .adesc {
  padding: 0;
}
.timelinelist .timefollowup .ant-timeline-item-content .adesc .ant-col-6 {
  padding: 0.55rem 0 0.55rem 0.5rem;
  border-right: 1px solid rgba(21, 21, 21, 0.06);
}
.timelinelist .timefollowup .ant-timeline-item-content .adesc .ant-col-18 {
  padding: 0.55rem 0.5rem;
}
li.ant-timeline-item > .ant-timeline-item-head {
  margin-top: 0.25rem !important;
}

/* Buttons v2 */
.editlistingv2-wrap button.ant-btn-primary {
  border-radius: 0;
  background: #151515;
  color: #fff;
  text-align: center;
  justify-content: center;
}
.editlistingv2-wrap button.ant-btn-primary span {
  color: #fff;
  font-size: 12px;
}
.editlistingv2-wrap button.ant-btn-secondary {
  border-radius: 0;
  background: #fff;
  color: #151515;
  text-align: center;
  justify-content: center;
  border: 1px solid rgba(21, 21, 21, 0.16);
  height: 36px;
}
.editlistingv2-wrap button.ant-btn-secondary span {
  color: #151515;
  font-size: 12px;
}

.btnprimary {
  background: #151515 !important;
  color: #fff !important;
}

.filterwrap-share {
  margin-top: 0.2rem;
  gap: 0.8rem;
}
.filterwrap-share .ant-checkbox {
  display: none;
}
.filterwrap-share .ant-checkbox-wrapper {
  margin-bottom: 0;
  position: relative;
  transition: all 0.3s ease-in-out;
}
.filterwrap-share .ant-checkbox-wrapper span {
  font-size: 0.73rem;
  padding: 0.3rem;
  white-space: nowrap;
}
.filterwrap-share .ant-checkbox-group {
  gap: 0.8rem;
}
.filterwrap-share .ant-checkbox-wrapper.ant-checkbox-checked span::after {
  content: "\F623";
  font-family: bootstrap-icons;
  margin-left: 2px;
  position: absolute;
  right: 0;
}
.filterwrap-share .ant-checkbox-wrapper.ant-checkbox-wrapper-checked::after {
  background: #151515;
  bottom: 0;
  content: "";
  display: flex;
  height: 2px;
  left: 0;
  position: absolute;
  width: 100%;
}
.filterwrap-share .ant-checkbox-wrapper.ant-checkbox-wrapper-checked::after {
  background: #151515;
  bottom: 0;
  content: "";
  display: flex;
  height: 2px;
  left: 0;
  position: absolute;
  width: 100%;
}
.filterwrap-share
  .formcheckboxgroup
  .ant-checkbox-wrapper.ant-checkbox-wrapper-checked {
  padding-right: 1.2rem;
}
.filterwrap-share .formcheckboxgroup .ant-checkbox-wrapper {
  margin-bottom: 0;
  padding-left: 0.5rem;
  position: relative;
  transition: all 0.2s ease-in-out;
}
.filterwrap-share .ant-checkbox-wrapper.ant-checkbox-wrapper-checked span {
  font-family: Poppins-Bold, sans-serif;
}
.filterwrap-share
  .ant-checkbox-wrapper.ant-checkbox-wrapper-checked
  span::after {
  content: "\F623";
  font-family: bootstrap-icons;
  margin-left: 2px;
  position: absolute;
  right: 0;
}
.filterwrap-share .ant-checkbox-wrapper.ant-checkbox-wrapper-checked {
  padding-right: 1.2rem;
}

/* Property Image */
.edit-actions .editwrap.propertyimage {
  padding-bottom: 0;
}
.editwrap.propertyimage .anticon.anticon-caret-left.slick-arrow.slick-prev svg,
.editwrap.propertyimage
  .anticon.anticon-caret-right.slick-arrow.slick-next
  svg {
  font-size: 16px;
  overflow: visible;
  color: #fff;
}
.editwrap.propertyimage .anticon.anticon-caret-left.slick-arrow.slick-prev {
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
  font-size: 16px;
  inset-inline-start: 0;
}
.editwrap.propertyimage .anticon.anticon-caret-right.slick-arrow.slick-next {
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
  font-size: 16px;
  inset-inline-end: 0;
}
.editwrap.propertyimage .ant-carousel .slick-dots {
  bottom: 0;
}
.editwrap.propertyimage .ant-carousel .slick-dots li button {
  background: #151515;
}

/* .editlistingv2-wrap .ant-row > .ant-col {transition:all 0.3s ease-in-out} */
.editlistingv2-wrap .infogrp {
  display: flex;
  flex-direction: row;
  gap: 0.2rem;
}
.editlistingv2-wrap .infogrp p {
  margin-bottom: 0;
}

/* PORTALS */
.editlistingv2-wrap.cont-wrap .ant-row.tabportal > .ant-col {
  flex-wrap: wrap;
  margin-top: 1rem;
}
.editlistingv2-wrap.cont-wrap .ant-row.tabportal .input-group .control-labels {
  font-size: 12px;
  margin-left: 5px;
}

.editlistingv2-wrap .btn.btn-link.viewall {
  font-size: 12px;
  text-decoration: none;
  color: #151515;
  display: flex;
  width: fit-content;
  padding: 0.5rem 0;
}

/* Listing upload */
.editlistingv2-wrap .upload-group label {
  max-width: 100%;
  border: 1px dashed rgba(21, 21, 21, 0.16);
  margin: 1rem 0;
  height: 4rem;
}

.searchbar .ant-input-affix-wrapper {
  padding: 0 10px;
  overflow: hidden;
  font-family: "Poppins-Regular", sans-serif;
  font-size: 13px;
  height: 36px;
  max-width: 350px;
}
.searchbar .ant-input-affix-wrapper .ant-input {
  height: 36px;
  font-family: "Poppins-Regular", sans-serif;
  line-height: 36px;
  border: none !important;
}

/* Collapsible */
.moreoptions.ant-collapse .ant-collapse-header {
  width: fit-content;
  padding: 0 !important;
  border: none;
}
.ant-collapse.moreoptions {
  background: transparent;
  border: none !important;
  width: fit-content;
}
.ant-collapse.moreoptions .ant-collapse-item {
  border: none;
}
.moreoptions.ant-collapse.moreoptions .ant-collapse-header-text {
  font-size: 10px;
}
.ant-collapse.moreoptions .ant-collapse-content-box {
  padding: 10px 0;
  border: 1px solid rgba(21, 21, 21, 0.16);
}
.ant-collapse.moreoptions .ant-collapse-content.ant-collapse-content-active {
  border-top: 0;
}

.editlistingv2-wrap
  .ant-select.ant-select-show-search
  .ant-select-selector
  .ant-select-selection-item {
  font-size: 12px;
  height: 36px;
  line-height: 35px !important;
}
.editlistingv2-wrap
  .curations
  .ant-select.ant-select-show-search
  .ant-select-selector
  .ant-select-selection-item {
  line-height: 28px !important;
  height: 28px !important;
  padding-right: 10px;
}
.editlistingv2-wrap
  .editdetailswrap
  .form-group
  .ant-select
  .ant-select-selector {
  height: fit-content !important;
}
/* STOCK */
.tbstockwrap.ant-table-wrapper
  .ant-table-tbody
  > tr
  > td.ant-table-cell.actionwrap {
  border-left: 1px solid rgba(21, 21, 21, 0.06) !important;
}
.tbstockwrap.ant-table-wrapper .ant-table-content table {
  border: none !important;
  border-radius: 0;
  border-collapse: separate;
  border-spacing: 0 0.8rem;
}

.font-semibold {
  font-family: "Poppins-Semibold", sans-serif;
}

.tbstockwrap.ant-table-wrapper
  .ant-table-content
  table
  .ant-table-row
  td.ant-table-cell:last-child {
  border-right: 1px solid rgba(21, 21, 21, 0.06) !important;
}
.tbstockwrap.ant-table-wrapper
  .ant-table-content
  table
  .ant-table-row
  td.ant-table-cell:first-child {
  border-left: 1px solid rgba(21, 21, 21, 0.06) !important;
}
.tbstockwrap.ant-table-wrapper
  .ant-table-content
  table
  .ant-table-row
  td.ant-table-cell {
  border-top: 1px solid rgba(21, 21, 21, 0.06) !important;
  border-bottom: 1px solid rgba(21, 21, 21, 0.06) !important;
  vertical-align: top;
}
.tbstockwrap.ant-table-wrapper
  .ant-table-content
  table
  .ant-table-row
  td.ant-table-cell
  .confirmedowner
  label {
  color: #c51162;
}
.tbstockwrap.ant-table-wrapper
  .ant-table-content
  table
  .ant-table-row
  td.ant-table-cell
  .comingtomarket
  label {
  color: #ff9f0a;
}
.tbstockwrap.ant-table-wrapper
  .ant-table-content
  table
  .ant-table-row
  td.ant-table-cell
  .published
  label {
  color: #25d366;
}
.tbstockwrap.ant-table-wrapper
  .ant-table-content
  table
  .ant-table-row
  td.ant-table-cell
  .feeds
  label {
  color: #004d40;
}
.tbstockwrap.ant-table-wrapper
  .ant-table-content
  table
  .ant-table-row
  td.ant-table-cell
  .statstock
  a {
  color: #151515;
  font-size: 13px;
  text-align: center;
}
.tbstockwrap.ant-table-wrapper
  .ant-table-content
  table
  .ant-table-row
  td.ant-table-cell
  .statstock
  a:hover {
  opacity: 0.7;
}
.stockwrap .filterwrap p,
.stockwrap .filterwrap label {
  margin-bottom: 0;
  white-space: nowrap;
  font-size: 12px;
}
.publisheddate-wrap {
  align-items: center;
  border: 1px solid hsla(0, 0%, 8%, 0.16);
  border-radius: 0.3rem;
  height: 31px;
  justify-content: space-between;
  overflow: hidden;
}
.publisheddate-wrap .titledate {
  background: hsla(0, 0%, 8%, 0.1);
  font-size: 11px;
  margin-bottom: 0;
  padding: 5px;
  white-space: nowrap;
}
.stockwrap .filterwrap .form-check label {
  padding-left: 0 !important;
  height: 25px;
  line-height: 25px;
  position: relative;
}
.stockwrap .filterwrap .form-check {
  margin: 0 5px;
}
.publisheddate-wrap .form-check input.form-control {
  border-radius: 0;
  min-width: 75px;
}
.publisheddate-wrap .form-check .form-select {
  border-radius: 0;
}
.stockwrap .filterwrap .ant-col-24 {
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}
.stockwrap .filterwrap .commfilter {
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
}
.stockwrap .filterwrap .commfilter span {
  transition: all 0.3s ease-in-out;
  padding-inline-start: 0;
  padding-inline-end: 0;
}
.stockwrap .filterwrap .commfilter .ant-checkbox-checked + span {
  padding-right: 1rem;
  padding-left: 1rem;
}
.stockwrap .filterwrap .commfilter .ant-checkbox-checked + span:before {
  background-color: #25d366;
  border-radius: 50%;
  content: "";
  display: flex;
  height: 9px;
  left: 0;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 9px;
}
.stockwrap .filterwrap .commfilter .ant-checkbox-checked + span:after {
  content: "\F623";
  font-family: bootstrap-icons;
  margin-left: 2px;
  position: absolute;
  right: 0;
}
.stockwrap .filterwrap .ant-checkbox .ant-checkbox-input {
  display: none;
}

.stockwrap .filterwrap .commfilter .ant-checkbox-checked:after {
  background: #151515;
  bottom: 0;
  content: "";
  display: flex;
  height: 2px;
  left: 0;
  position: absolute;
  width: 100%;
}
.stockwrap .ant-table-wrapper .ant-table-content table {
  border-collapse: separate;
  border-spacing: 0 0.6rem;
  border-right: 0 !important;
}
.stockwrap .ant-table-content table tr td.ant-table-cell {
  padding: 1.3rem 8px !important;
}
.stockwrap .ant-table-content table tr td.ant-table-cell label {
  font-size: 12px;
}
.stockwrap .ant-table-content table tr td.ant-table-cell:first-child {
  border-left: 1px solid hsla(0, 0%, 8%, 0.16);
}
.stockwrap .ant-table-content table tr td.ant-table-cell:last-child {
  border-right: 1px solid hsla(0, 0%, 8%, 0.16);
}
.stockwrap .bedswrap {
  border: none;
}
.stockwrap .ant-select .ant-select-selector {
  height: 25px;
  line-height: 25px;
  margin: 0;
  border: none;
  background: rgba(21, 21, 21, 0.03) !important;
  padding: 0 3px;
  font-size: 13px;
}
.stockwrap
  .ant-select
  .ant-select-selector
  span.ant-select-selection-placeholder {
  line-height: 25px !important;
  color: #151515;
}
.stockwrap
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-search {
  inset-inline-start: 0;
  inset-inline-end: 0;
}
.stockwrap .ant-select .ant-select-selector .ant-select-selection-item {
  font-size: 12px;
  line-height: 25px !important;
  height: 25px;
}
.stockwrap .ant-select .ant-select-arrow {
  color: #151515;
  font-size: 10px;
  top: 50%;
}
.stockwrap .filterwrap {
  background: transparent;
  border-bottom: none;
}
.stockwrap .filterwrap .ant-col-24:last-child {
  padding-bottom: 0;
}
.stockwrap .ant-table-wrapper .ant-table-tbody > tr.ant-table-row:hover > td,
.stockwrap
  .ant-table-wrapper
  .ant-table-tbody
  > tr
  > td.ant-table-cell-row-hover {
  background: #fafafa;
}

/* .stockwrap .formcheckboxgroup .ant-checkbox-wrapper.ant-checkbox-wrapper-checked:after {background: #151515;bottom: 0;content: "";display: flex;height: 2px;left: 0;position: absolute;width: 100%;} */
.stockwrap
  .formcheckboxgroup
  .ant-checkbox-wrapper.ant-checkbox-wrapper-checked
  span:after {
  content: "\F623";
  font-family: bootstrap-icons;
  margin-left: 2px;
  position: absolute;
  right: 0;
}
.stockwrap .formcheckboxgroup .ant-checkbox-wrapper {
  transition: all 0.3s ease-in-out;
}
.stockwrap
  .formcheckboxgroup
  .ant-checkbox-wrapper.ant-checkbox-wrapper-checked {
  padding-right: 1.2rem;
}
.stockwrap span.ant-checkbox.ant-checkbox-checked {
  display: none;
}
.stockwrap .resetbtn {
  padding: 0;
}
.stockwrap .resetbtn i,
.stockwrap .resetbtn span {
  font-size: 0.7rem;
}
.stockwrap .ant-btn.resetbtn:hover {
  background: none;
}
.stockwrap .ant-btn.resetbtn:hover::after {
  background: rgba(21, 21, 21, 0.16);
  bottom: 0;
  content: "";
  display: flex;
  height: 2px;
  left: 0;
  position: absolute;
  width: 100%;
}
.stockwrap .ant-table-content {
  overflow: hidden;
}

.stockwrap .ant-table-footer {
  padding: 0;
}
.stockwrap .ant-table-footer .ant-table-wrapper .ant-table-content table {
  border-spacing: 0;
}
.totalTablewrap label {
  font-size: 0.67rem;
}
.totalTablewrap td {
  padding: 1.3rem 8px !important;
}

.stockwrap .ant-table .ant-table-thead > tr > th.ant-table-cell {
  background: transparent !important;
  padding: 5px !important;
}
.stockwrap .ant-card .ant-card-body {
  padding: 0.7rem;
}
.stockwrap .ant-card-meta-description i {
  font-size: 1.6rem;
}
.stockwrap .ant-card-meta-description .confirmedowner i {
  color: #c51162;
}
.stockwrap .ant-card-meta-description .comingtomarket i {
  color: #ff9f0a;
}
.stockwrap .ant-card-meta-description .published i {
  color: #25d366;
}
.stockwrap .ant-card-meta-description .feeds i {
  color: #004d40;
}
.stockwrap .ant-card-meta-description .average i {
  color: #151515;
}
.stockwrap .ant-card-meta-description p,
.stockwrap .ant-card-meta-description span {
  margin-bottom: 0;
  color: #151515;
}
.stockwrap .ant-card-meta-description p {
  line-height: 1.8;
  display: flex;
  align-items: center;
}
.stockwrap .ant-card-meta-title {
  font-size: 11px;
  color: #151515;
  text-transform: uppercase;
  letter-spacing: 2px;
  white-space: normal;
  font-weight: 200;
}
.stockwrap .totalname {
  font-size: 12px;
  font-family: "Poppins-Bold", sans-serif;
}
.stockwrap .nototal {
  font-size: 1.7rem;
  font-family: "Poppins-Bold", sans-serif;
  margin-right: 0.6rem;
}
.stockwrap .nototalpercent {
  padding: 3px 5px;
  color: rgba(197, 17, 98, 1);
  border-radius: 36px;
  font-size: 14px;
  line-height: 1;
}
.stockwrap .confirmedowner .nototalpercent {
  background: rgba(197, 17, 98, 0.16);
}
.stockwrap .comingtomarket .nototalpercent {
  background: rgba(255, 159, 10, 0.16);
}
.stockwrap .published .nototalpercent {
  background: rgba(37, 211, 102, 0.16);
}
.stockwrap .feeds .nototalpercent {
  background: rgba(0, 77, 64, 0.16);
}
.stockwrap .average .nototalpercent {
  background: rgba(21, 21, 21, 0.16);
}
.stockwrap .totalcards-wrap > .ant-col:first-child {
  max-width: 20%;
  flex: 1 1 20%;
}
.stockwrap .totalcards-wrap > .ant-col:nth-last-child(2) {
  max-width: 20%;
  flex: 1 1 20%;
}
.stockwrap .totalcards-wrap > .ant-col {
  max-width: 20%;
  flex: 1 1;
}

.stockwrap .ant-card-meta-description .ant-col.ant-col-4 {
  display: flex;
  justify-content: end;
  padding-right: 2px;
}
.stockwrap hr {
  border-top: 1px solid rgba(21, 21, 21, 0.6);
  margin-bottom: 0;
}

.stockwrap .filterrow,
.stockwrap .tablerow {
  background: #fff;
  padding-top: 1rem;
}
.stockwrap .ant-table .ant-table-content tr {
  background: transparent;
}
.stockwrap .ant-table .ant-table-content .info-group p,
.stockwrap .ant-table .ant-table-content .info-group a,
.stockwrap .ant-table .ant-table-content .info-group span {
  font-family: "Poppins-Semibold", sans-serif;
}
.stockwrap
  .ant-table
  .ant-table-content
  .ant-table-cell:last-child
  .info-group
  span,
.stockwrap .ant-table .ant-table-thead .ant-table-cell:last-child {
  text-align: center;
}
.stockwrap .ant-table-summary .ant-table-cell:last-child .totalaverage {
  text-align: right;
}
.stockwrap .formcheckboxgroup {
  border-bottom: 0;
}
.stockwrap .filterwrap .ant-btn {
  margin: 0;
  padding: 0;
  height: 29px;
  margin-bottom: 5px;
  position: relative;
}
.stockwrap .filterwrap .ant-btn:hover {
  background: transparent;
}
.stockwrap .filterwrap .ant-btn.filterbtn:hover::after {
  background: #151515;
  height: 1px;
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
}
.stockwrap .filterwrap .ant-btn span {
  font-size: 12px;
}
.ant-popover .popfilter {
  height: fit-content;
  align-items: start;
  justify-content: start;
}
.ant-popover .popfilter .titledate {
  font-size: 13px;
  font-family: "Poppins-Bold", sans-serif;
  padding: 5px 12px;
  border-bottom: 1px solid rgba(21, 21, 21, 0.06);
  width: 100%;
  margin-bottom: 0;
}
.stockwrap .ant-popover-inner {
  padding: 0;
}
.stockwrap .ant-table-wrapper .ant-table-summary {
  background: rgba(21, 21, 21, 0.02);
}
.stockwrap .ant-table-wrapper .ant-table-summary .ant-table-cell {
  border: none !important;
}
.ant-popover.ant-popover-placement-bottomRight
  .ant-popover-content
  .ant-popover-inner {
  padding: 1rem !important;
}
.ant-popover-inner {
  padding: 1rem !important;
}

.stockwrap .tablerow .ant-table {
  min-height: 50vh;
}

.viewswitcher .viewswitcher-btn button.ant-btn {
  margin-top: -1px;
}
/* AI Search */
.aisearchwrap .itemlabel {
  background: #fff;
  padding: 8px 1rem;
  position: relative;
  z-index: 9999;
  cursor: pointer;
}
.searchbar.w-100.form-group {
  position: relative;
  z-index: 99;
}

.stockwrap
  .ant-table
  .ant-table-thead
  > tr
  > th.ant-table-cell:nth-child(1n + 4) {
  text-align: center;
  min-width: 80px;
}
.stockwrap .ant-table .ant-table-thead > tr > th.ant-table-cell:nth-child(4) {
  color: #c51162;
}
.stockwrap .ant-table .ant-table-thead > tr > th.ant-table-cell:nth-child(5) {
  color: #ff9f0a;
}
.stockwrap .ant-table .ant-table-thead > tr > th.ant-table-cell:nth-child(6) {
  color: #25d366;
}
.stockwrap .ant-table .ant-table-thead > tr > th.ant-table-cell:nth-child(7) {
  color: #004d40;
}
.stockwrap .statstock .info-group .infostat {
  display: flex;
  flex-direction: column;
}

.stockwrap .ant-table .ant-table-thead th.ant-table-cell:nth-child(8) .infostat,
.stockwrap
  .ant-table
  .ant-table-thead
  > tr
  > th.ant-table-cell:nth-child(9)
  .infostat {
  text-align: center;
}

.stockwrap .ant-table .ant-table-cell .confirmedowner span.percentage {
  color: #c51162;
}
.stockwrap .ant-table .ant-table-cell .comingtomarket span.percentage {
  color: #ff9f0a;
}
.stockwrap .ant-table .ant-table-cell .published span.percentage {
  color: #25d366;
}
.stockwrap .ant-table .ant-table-cell .feeds span.percentage {
  color: #004d40;
}
.stockwrap .ant-table-summary .ant-table-cell .confirmedowner span {
  color: #c51162;
  font-size: 14px;
  font-family: "Poppins-Bold", sans-serif;
}
.stockwrap .ant-table-summary .ant-table-cell .comingtomarket span {
  color: #ff9f0a;
  font-size: 14px;
  font-family: "Poppins-Bold", sans-serif;
}
.stockwrap .ant-table-summary .ant-table-cell .published span {
  color: #25d366;
  font-size: 14px;
  font-family: "Poppins-Bold", sans-serif;
}
.stockwrap .ant-table-summary .ant-table-cell .feeds span {
  color: #004d40;
  font-size: 14px;
  font-family: "Poppins-Bold", sans-serif;
}
.stockwrap .ant-table-summary .ant-table-cell .average p {
  font-size: 14px;
  font-family: "Poppins-Bold", sans-serif;
  text-align: center;
}
.stockwrap .ant-select-multiple.ant-select-show-search .ant-select-selector {
  height: 25px;
  margin-top: 0;
  line-height: 23px;
  align-items: start;
}
.stockwrap
  .ant-select-multiple.ant-select-show-search
  .ant-select-selector
  span.ant-select-selection-item-content {
  line-height: 1.8;
  font-size: 12px;
}
.stockwrap .ant-select-multiple .ant-select-selection-item-remove {
  line-height: 1.8;
}
.stockwrap
  .ant-select.ant-select-show-search
  .ant-select-selector
  .ant-select-selection-item {
  font-size: 10px;
  line-height: 21px !important;
  height: 21px;
}

/* Area Stock */
.monthfilter {
  width: 100%;
  display: flex;
}
.monthfilter .ant-segmented {
  width: 100%;
  background: #fff;
  box-shadow: none;
  padding: 0;
}
.monthfilter .ant-segmented-group label.ant-segmented-item {
  flex: 1 1 auto;
  box-shadow: none;
  border-radius: 0;
}
.monthfilter .ant-segmented .ant-segmented-item {
  position: relative;
}
.monthfilter
  .ant-segmented
  .ant-segmented-item.ant-segmented-item-selected::after {
  border-radius: 0;
}
.monthfilter .ant-segmented .ant-segmented-item.ant-segmented-item-selected {
  font-family: "Poppins-Bold", sans-serif;
}

.monthfilter .ant-segmented-item {
  transition: #151515 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.monthfilter .ant-segmented-item-selected {
  background-color: #151515;
  color: #fff;
}
.monthfilter .ant-segmented-thumb {
  background-color: #151515;
  color: #fff;
}
.resetfilter {
  justify-content: end;
  display: flex;
}
header.ant-layout-header {
  width: 100% !important;
}
.stockwrap
  .ant-table
  .ant-table-content
  .ant-table-row
  .ant-table-cell:nth-child(4)
  .info-group
  span {
  text-align: center;
}
.monthfilter
  .ant-segmented-item
  .ant-segmented-item-label
  .d-flex
  span:first-child {
  font-family: "Poppins-Semibold", sans-serif;
  font-size: 13px;
  color: #151515;
  text-transform: uppercase;
  margin-bottom: 5px;
}
.monthfilter .ant-segmented-item .ant-segmented-item-label .d-flex {
  line-height: 1.6;
  padding: 5px 0;
  text-align: left;
  color: #151515;
}
.monthfilter
  .ant-segmented-item
  .ant-segmented-item-label
  .d-flex
  span:last-child {
  font-size: 11px;
  text-align: left;
  color: #151515;
}
.monthfilter
  .ant-segmented-item.ant-segmented-item-selected
  .ant-segmented-item-label
  .d-flex
  span {
  color: #fff;
}
.areastockwrap .totalcards-wrap .ant-card {
  max-height: 5.5rem;
}

.areastockwrap .ant-checkbox-group .ant-checkbox-wrapper span:last-child {
  position: relative;
  transition: all 0.3s ease-in-out;
}
.areastockwrap
  .ant-checkbox-group
  .ant-checkbox-wrapper.ant-checkbox-wrapper-checked
  span:last-child {
  padding-right: 1.5rem;
}
.areastockwrap
  .filterwrap
  .ant-checkbox-group
  .ant-checkbox-wrapper-checked
  span:last-child::before {
  content: "\F623";
  font-family: bootstrap-icons;
  margin-left: 2px;
  position: absolute;
  right: 0;
}
.areastockwrap
  .ant-checkbox-group
  .ant-checkbox-wrapper.ant-checkbox-wrapper-checked
  span:last-child::after {
  background: #151515;
  bottom: -2px;
  content: "";
  display: flex;
  height: 2px;
  left: -10px;
  position: absolute;
  width: 115%;
}
.areastockwrap
  .ant-checkbox-group
  .ant-checkbox-wrapper.ant-checkbox-wrapper-checked:first-child
  span:last-child::after {
  background: #985fef;
}
.areastockwrap
  .ant-checkbox-group
  .ant-checkbox-wrapper.ant-checkbox-wrapper-checked:nth-child(2)
  span:last-child::after {
  background: #ef5fd7;
}
.areastockwrap
  .ant-checkbox-group
  .ant-checkbox-wrapper.ant-checkbox-wrapper-checked:nth-child(3)
  span:last-child::after {
  background: #cc0066;
}
.gap-10 {
  gap: 10px;
}
@media screen and (max-width: 1440px) {
  .stockwrap .ant-card-meta-description span.totalname {
    font-size: 10px;
    text-transform: uppercase;
  }
  .stockwrap .ant-card-meta-description span.nototal {
    font-size: 16px;
  }
}
@media screen and (max-width: 1024px) {
  .stockwrap .totalcards-wrap {
    flex-wrap: wrap;
  }
  .stockwrap div.totalcards-wrap > .ant-col {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    margin-bottom: 1rem;
  }
  .stockwrap div.totalcards-wrap > .ant-col:first-child {
    flex: 1 1 33.33%;
    max-width: 33.33%;
  }
  .stockwrap div.totalcards-wrap > .ant-col:nth-last-child(2) {
    flex: 1 1 33.33%;
    max-width: 33.33%;
  }
}
.checkboxfilterareastock .ant-checkbox-wrapper::before {
  content: "";
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #196f64;
}
.checkboxfilterareastock .ant-checkbox-wrapper span {
  color: #196f64;
  position: relative;
  transition: all 0.3s ease-in-out;
}
.checkboxfilterareastock .form-check {
  min-height: fit-content;
  align-items: center;
  display: flex;
}
.checkboxfilterareastock
  .ant-checkbox-wrapper
  .ant-checkbox.ant-checkbox-checked
  + span {
  font-family: "Poppins-Bold", sans-serif;
  padding-right: 1.5rem;
}
.checkboxfilterareastock
  .ant-checkbox-wrapper
  .ant-checkbox.ant-checkbox-checked
  + span::after {
  bottom: -2px;
  content: "";
  display: flex;
  height: 2px;
  left: -10px;
  position: absolute;
  width: 115%;
  background-color: #196f64;
}
.checkboxfilterareastock {
  flex-flow: row;
}

.checkboxfilterareastock
  .ant-checkbox-wrapper
  .ant-checkbox.ant-checkbox-checked
  + span::before {
  content: "\F623";
  font-family: bootstrap-icons;
  margin-left: 2px;
  position: absolute;
  right: 0;
}

.printprevbrochure {
  min-height: 14in;
}

.pstat.slp,
.pstat.rented,
.pstat.rlp {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-indent: 4px;
  padding: 8px 10px;
  gap: 0.2rem;
}
.pstat.slp {
  color: #fff;
  background-color: #083d77;
}
.pstat.rented {
  color: #fff;
  background-color: #151515;
}
.pstat.rlp {
  color: #fff;
  background-color: #151515;
}
.pstat.uf {
  color: #fff;
  background-color: #ecc30b;
}
/* .pstat.slp img, .pstat.rented img, .pstat.rlp img {filter: invert(1)} */

label[for="qrcode"] .dropzone {
  cursor: pointer;
}
label[for="qrcode"] .dropzone:hover {
  border-color: #151515 !important;
}
button.ant-btn.ant-btn-default:hover {
  border-color: #151515 !important;
  color: #151515 !important;
}
.modalbrochurepdf .react-tabs__tab-panel.react-tabs__tab-panel--selected {
  width: 1080px;
}

.savedetails.ant-btn {
  background: #151515;
}
.savedetails.ant-btn:hover {
  background: #fff;
}
.savedetails.ant-btn span {
  color: #fff;
}
.savedetails.ant-btn:hover span {
  color: #151515;
}
.ant-select-selector {
  margin: 0 !important;
}

@media (max-width: 1024px) {
  .selectColumn {
    width: 100% !important;
  }
  .selectColumn .ant-space-item {
    width: 100% !important;
  }
  .ant-input-affix-wrapper {
    max-width: 100% !important;
  }
  .listingfilter-wrap .price-wrap .form-select {
    width: 100px;
  }
  .listingfilter-second .formcheckboxgroup {
    display: block !important;
  }
  .FilterDiv {
    max-width: 290px;
  }
  .TableborderNoneMain .ant-table {
    border: none !important;
  }

  .TableborderNoneMain .ant-table .ant-table-thead {
    display: none !important;
  }
  .TableborderNoneMain.ant-table-wrapper .ant-table-content table {
    border-right: none !important;
  }
}

@media (max-width: 768px) {
  .tableLeadsResponsive .ant-table {
    border: none !important;
  }

  .tableLeadsResponsive .ant-table .ant-table-thead {
    display: none !important;
  }
  .tableLeadsResponsive.ant-table-wrapper .ant-table-content table {
    border-right: none !important;
  }

  .checkBoxGroupWrap {
    flex-wrap: wrap !important;
  }
}
@media (max-width: 650px) {
  .p18pt10 {
    font-size: 18px;
    padding-top: 10px;
  }
  .topbtnswrap .ant-btn span,
  .topbtnswrap .ant-btn.addfilter span {
    font-size: 10px;
  }
  .veditlisting .ant-drawer-header {
    flex-direction: column;
  }
  .veditlisting .ant-drawer-header .ant-drawer-header-title {
    width: 100%;
  }
  .veditlisting .ant-drawer-header .ant-drawer-extra {
    width: 100%;
  }
  .veditlisting .ant-drawer-header .ant-drawer-extra .ant-space {
    justify-content: end;
    width: 100%;
    flex-wrap: wrap;
  }
  .veditlisting
    .ant-drawer-header
    .ant-drawer-extra
    .ant-space
    .ant-space-item
    button {
    padding: 4px 2px;
  }

  .react-tabs .react-tabs__tab-list .react-tabs__tab {
    font-size: 12px !important;
  }

  .react-tabs
    .react-tabs__tab-list
    .react-tabs__tab
    .tabs-view
    .react-tabs
    .react-tabs__tab-list {
    gap: 0.6rem;
  }

  .fontRes-90 {
    font-size: 9px !important;
  }

  .fontRes-11 {
    font-size: 0.75rem !important;
  }

  .datesfilter-wrap .addeddate-wrap {
    flex-wrap: wrap !important;
    height: auto !important;
  }

  .datesfilter-wrap .publisheddate-wrap {
    flex-wrap: wrap !important;
    height: auto !important;
  }
  .photorequest .tabs-team .tcontractwrap {
    padding: 1rem !important;
  }
  .marketrequest .btn.btn-secondary {
    font-size: 9px !important;
  }
}

@media (max-width: 425px) {
  .react-tabs .react-tabs__tab-list .react-tabs__tab {
    font-size: 9.2px !important;
  }

  .RoleDrawerClass .ant-drawer-header {
    flex-direction: column;
    align-items: baseline;
  }

  /* .tabs-view.responsiveTabs .react-tabs .react-tabs__tab-list .react-tabs__tab{
    padding: 0 0.8rem !important
  } */
  .tabs-view .react-tabs .react-tabs__tab-list {
    gap: 0.4rem;
  }
  .tabs-view .react-tabs .react-tabs__tab-list .react-tabs__tab {
    padding: 0 0.2rem;
  }

  .fontRes-9 {
    font-size: 9px !important;
  }

  .fontRes-1 {
    font-size: 0.75rem !important;
  }
}
.FilterButtonStyling {
  border: none !important;
  height: 26px !important;
  box-shadow: none !important;
}

.moreoptions
  .ant-collapse-item
  .ant-collapse-content
  .ant-collapse-content-box {
  display: flex;
  align-items: center;
  gap: 5px;
  flex-wrap: wrap;
}
.borderGray {
  border: 1px solid #15151529;
}

.flex-align-center {
  align-items: baseline !important;
}

.ant-tooltip .ant-tooltip-inner {
  font-size: 10px;
}

.XScroll {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  flex-wrap: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
  gap: 10px;
}

.XScroll button {
  white-space: nowrap;
}

.AddUserButton {
  background: #fff;
  border: 1px solid rgba(21, 21, 21, 0.2);
  color: #151515;
  display: flex;
  align-items: center;
  font-family: Poppins-Regular, sans-serif;
  height: 36px;
  font-size: 14px;
  gap: 5px;
}

.pricefiltergrp .pricefilt {
  position: relative;
}
.pricefiltergrp .pricefilt .clear-icon {
  position: absolute;
  right: 10px; /* Adjust as needed */
  top: 50%;
  transform: translateY(-50%);
  font-size: 0.875rem; /* Adjust size as needed */
  color: #ccc; /* Light color for the clear icon */
}

.bedroomfilter .ant-checkbox-group .ant-checkbox-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 50px;
  border: 1px solid #d9d9d9;
  font-size: 16px;
  line-height: 38px;
}
.bedroomfilter .ant-checkbox-group .ant-checkbox-wrapper .ant-checkbox {
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
}
.bedroomfilter .ant-checkbox-group .ant-checkbox-wrapper-checked:not(.ant-checkbox-wrapper-disabled) {
  background: #151515;
  border-color: #151515;
  color:#fff
}

.deleteButton{
  background: red !important;
    color: white !important; 
    border: 1px solid #fff !important;
    transition: 0.3s ease-in;
}

.deleteButton:hover{
  background: #c50000 !important;
}


.sharewhatsapp.ant-modal .ant-modal-content {padding: 0; border-radius:8px !important; overflow:hidden}
.sharewhatsapp.ant-modal div.ant-modal-body {align-items:flex-start; padding-left:1rem}

.table-responsive.tablelistwrap.tblistingwrap :where(.css-dev-only-do-not-override-d2lrxs).ant-checkbox-checked .ant-checkbox-inner:after { top: -7px;width: 5px;}
.table-responsive.tablelistwrap.tblistingwrap .ant-checkbox-checked .ant-checkbox-inner {position:relative; overflow:hidden}

.custom-confirm-button {
    border: 1px solid #25d366 !important; /* Set your desired border color */
    color: #fff; /* Optional: Text color */
    background-color: #25d366; /* Optional: Background color */
  }
  
  .custom-confirm-button:hover {border-color: #1b8a53 !important; /* Optional: Hover state */}


  @media screen and (min-width:1800px) {
    .ant-modal.brochureStyle {width:67.5vw !important }
    .ant-modal.brochureStyle .ant-tabs-content-holder #rc-tabs-0-panel-2 .ant-layout-content {padding-bottom:8rem}

  }



  .error-border .ant-picker-input {
    border-color: red !important;  /* Add a red border to the DatePicker input */
}