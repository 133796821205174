*, p, span {font-family: "Poppins-Regular", sans-serif;}

.bg-gray {background-color: rgba(21,21,21,0.03);}

/* TOP */
.logo {margin:1rem 0; width:fit-content}
header .ant-space .ant-space-item { margin-right: 1rem; margin-bottom: 0;}


/* Font Sizes */
.font-8 {font-size:8px;}
.font-10 {font-size:10px}
.font-12 {font-size: 12px;}

.mourequest-wrap {border-radius: 0.3rem; overflow:hidden}
.mourequest-wrap.ant-table-wrapper .ant-table-tbody > tr > td {vertical-align: top; white-space: normal !important; font-size: 13px;}
.mourequest-wrap .ant-table-cell.propertywrap {z-index: 5;}

/* Tenancy */
.tenancy-wrap thead, .mouwrap thead {display:none}
.tenancy-wrap .info-title, .mouwrap .info-title {margin-bottom:0; color:rgba(21,21,21,0.70); font-size:11px;}
.tenancy-wrap .info-group, .mouwrap .info-group {margin-bottom:0.5rem}
.tenancy-wrap p, .mouwrap p {font-size: 12px;}
.tenancy-wrap .info-group p, .mouwrap .info-group p {margin-bottom:0; white-space: normal; }
.tenancy-wrap .info-group a.font-bold, .mouwrap .info-group a.font-bold {font-size:16px;}
.tenancy-wrap .info-check, .mouwrap .info-check {display:flex; flex-direction: row; gap:0.3rem; align-items: center;}
.tenancy-wrap .info-check p, .mouwrap .info-check p {font-size:12px; white-space: normal;margin-bottom:0}
.tenancy-wrap .info-check .anticon-check, .mouwrap .info-check .anticon-check {color:#1BDD77}
.tenancy-wrap .info-check .anticon-close, .mouwrap .info-check .anticon-close {color:#ff0000}
.tenancy-wrap .info-icon, .mouwrap .info-icon {font-size:1.8rem; align-content: center;   display: flex; margin-top: 5px;}
.tenancy-wrap .ant-table-measure-row, .mouwrap .ant-table-measure-row {display:none}
.tenancy-wrap, .mouwrap{overflow-y: hidden;}
.tcontractwrap .react-tabs .react-tabs__tab-list {height: 30px; line-height: 30px; overflow-y:hidden;background: none; border-bottom:1px solid rgba(21,21,21,0.16); border-radius:0;padding: 0;}
.tcontractwrap .react-tabs .react-tabs__tab-list .react-tabs__tab {height:100%; border:none;}
.tcontractwrap .react-tabs .react-tabs__tab--selected::after {height:3px;}
.tcontractwrap .ant-table-wrapper .ant-table-tbody > tr {border-bottom:1px solid rgba(21,21,21,0.16); box-shadow: none;}
.tcontractwrap .ant-table-wrapper .ant-table-tbody > tr > td {vertical-align: top;border-bottom:1px solid rgba(21,21,21,0.03); } 
.tenancy-wrap.ant-table tr {box-shadow: none;}

/* Contract Status */
.tcontractwrap .c-icon {position:relative; gap:0.6rem; display:flex; font-size:12px; flex-direction:row; align-items:center; font-size:13px; align-self:center}
.tcontractwrap .c-icon::after{width:12px; height:12px; background-color:#151515;border-radius:50%; display:block; content:""}
.tcontractwrap .c-icon.signed{ color:#1BDD77;}
.tcontractwrap .c-icon.signed::after{background-color:#1BDD77;}
.tcontractwrap .c-icon.action {color:#ff0000}
.tcontractwrap .c-icon.action::after{background-color:#ff0000; }
.tcontractwrap .c-icon.pending{color:#FECD00}
.tcontractwrap .c-icon.pending::after{background-color:#FECD00; }
.tcontractwrap .c-icon.changes{color:#1D01FF}
.tcontractwrap .c-icon.changes::after{background-color:#1D01FF; }
.tcontractwrap .c-icon.dismissed{color:#8a8a8a}
.tcontractwrap .c-icon.dismissed::after{background-color:#8a8a8a; }
.tcontractwrap .ant-table-wrapper .ant-table-body {overflow-y: hidden !important;}
.tcontractwrap .datestat {color:#8a8a8a; font-size:12px}

.ant-upload-wrapper .ant-upload { display: flex; flex-direction: column; margin-bottom: 1rem;width:100%}
.ant-upload-wrapper span.ant-upload{margin-bottom: 0;}
.ant-upload-wrapper .ant-upload label {font-size: 0.73rem; display: flex; font-weight: normal;margin-bottom: 5px;}
.ant-upload .ant-btn { border: 1px solid rgba(21,21,21,0.06);justify-content: left;display: flex; height: 36px; align-items: center; padding:0}
.ant-upload .ant-btn span { font-size: 13px;padding: 0 16px;background: rgba(21,21,21,0.06); height: 100%; display: flex; align-items: center;}

.requestwrap .border.ant-row > .ant-col {padding: 0.5rem 1.5rem !important}
.requestwrap .ant-picker {height:36px; overflow:hidden}
.requestwrap .ant-picker-input input {font-size:12px}

@media screen and (max-width:1440px) {
    .ant-modal.modaltenancypdf {width: 100% !important;}
}

/* MOU REQUEST */
.mourequest-wrap .ant-table .ant-table-thead > tr > th.ant-table-cell {white-space: normal !important; text-transform: capitalize;}
.mourequest-wrap.ant-table-wrapper .ant-table-tbody > tr.ant-table-measure-row > td {padding:0 !important}
.mourequest-wrap.ant-table-wrapper .ant-table-tbody {overflow-y:hidden}
.mourequest-wrap .ant-table-thead {display:none}

.contractactions .ant-card {margin-top:1rem; height:100%}
.contractactions button{height:100%; width:100%; padding:1rem 0;}
.contractactions .ant-card-body {padding:0; height:100%}
.contractactions .ant-btn {display: flex; flex-direction:column; }


.contractactions .ant-btn .anticon.anticon { font-size: 2rem; margin: 1rem auto; opacity:0.7}
.contractactions .ant-btn p {white-space: normal;}

.previewpdfactions { width:100%; border-top: 1px solid rgba(21,21,21,0.03); display:flex; flex-direction: row; gap:1rem; padding:1rem 0; justify-content: end; margin-top:1rem}

.previewpdfactions .ant-btn-secondary {background-color:#151515; border-color:#151515; color:#FFF; height:36px}
.docchecklist  {gap:1.5rem; padding:0.5rem 0 1rem 0}
.docchecklist .info-check {gap:0.6rem; color:#151515; text-decoration:none}
.docchecklist .info-check .ant-icon {color:#151515}
.previewpdf .ant-btn { width: fit-content;}

.ant-modal.modaltenancypdf .ant-modal-content {max-height: 80vh; height: 100%;overflow:hidden; padding:0}
.ant-modal.modaltenancypdf .ant-modal-content .pdfscroll {overflow-y: scroll;width: 100%;  display: flex;flex-direction: column;   max-height: 78vh; background: #efefef; padding: 2rem 0;box-shadow: inset 0 0 10px rgba(21,21,21,0.16);}
.tenancypdf-buttons a, .tenancypdf-buttons button{ display:flex; flex-direction:row; gap:0.6rem; align-items:center; border-top: 1px solid rgba(21,21,21,0.06); border-radius: 0; padding: 1rem;}
.tenancypdf-buttons a:hover, .tenancypdf-buttons button:hover {   background: rgba(21,21,21,0.16);}
.form-group .addterms {gap:0.6rem}
.form-group .addterms .ant-input-affix-wrapper {height:36px; margin-bottom:5px; padding: 0 1rem; overflow:hidden}
.form-group .addterms .ant-input-affix-wrapper input , .form-group .addterms .ant-input-affix-wrapper input:focus{height:36px;border:none !important}
.addterms .ant-input-show-count-suffix {font-size:11px}


/* Photography / Videography Request */
.photorequest .form-group > p {font-weight: 700; margin-bottom: 0; font-size:13px;}
.photorequest .form-group > span, .photorequestmodal .form-group > span  {font-size: 12px; color: rgba(21,21,21,0.80); margin-bottom:5px}
.photorequest .info-check, .photorequestmodal .info-check{ display:flex; flex-direction: row; gap:0.6rem; align-items:center}
.photorequest .info-check p {font-size:12px; margin-bottom:0}
.photorequest .ant-picker-input, .photorequest .ant-picker{height:36px; padding:0 16px; overflow:hidden}
.photorequest .ant-picker-input input, .photorequest .ant-picker input {height:36px;}
.photorequest .info-group .info-title {color: hsla(0,0%,8%,.7);font-size: 11px; margin-bottom: 0;}
.photorequest .info-group {margin-bottom: .5rem;}
.photorequest .ant-table-thead {display:none}
.photorequest .ant-table-wrapper .ant-table-content table{border-right:none !important}
.requestbuttons {width:100%; background:#fafafa; padding:0.7rem }
.requestbuttons .ant-col {display:flex; flex-direction: row; gap:0.6rem;}
.photorequestmodal {max-height:85vh; border-radius: 0.6rem;}
.photorequestmodal .ant-modal-footer { position: sticky;   bottom: 0; width: 100%; background: #FFF !important;  box-shadow: 0 -3px 5px rgba(21,21,21,0.06); padding: 0.6rem; }
.photorequestmodal .ant-modal-content {padding: 0;overflow-y: scroll;height:85vh}
.photorequestmodal.ant-modal .ant-modal-body {padding:1.5rem}
.photorequestmodal.ant-modal .ant-modal-header {padding:1rem 1.5rem 0}

.photorequest .tabs-team .tcontractwrap {background: #fff;border: 1px solid hsla(0,0%,8%,.16); border-top-color: rgba(20, 20, 20, 0.16); border-top-style: solid; border-top-width: 1px;padding: 2rem; }

.photorequest .tabs-team .react-tabs__tab-list {background: transparent; padding: 0;border-bottom: none; height: unset;  bottom: -1px; position: relative;}
.photorequest .tabs-team .tcontractwrap .react-tabs .react-tabs__tab-list { height: 30px; line-height: 30px;   overflow-y: hidden;background: none; border-bottom: 1px solid rgba(21,21,21,0.16); border-radius: 0;padding: 0;margin-bottom:1rem}
.photorequest .tabs-team .tcontractwrap .react-tabs .react-tabs__tab-list .react-tabs__tab {border: none; height: 100%;background:transparent; color:#151515;   }

.commision-wrap .tabs-months .react-tabs__tab-list{ width:100%}
.commision-wrap .tabs-months .react-tabs .react-tabs__tab-list .react-tabs__tab {padding:0 1rem}
.photorequest .react-tabs .react-tabs__tab--selected::after {background:#FFF}
.photorequest .tcontractwrap .react-tabs .react-tabs__tab--selected::after{background: #151515;}
.images .row.upload-group {border: 1px solid rgba(21,21,21,0.06);   padding: 2rem;width: 100%; margin: 0 auto 1rem;}
.images .row.upload-group input {text-align: center;}
.images .upload-group button.ant-btn { width: fit-content; margin: 2rem auto 0;  padding: 0 1rem;  height: 36px;}
 .timeline-wrap .tab-content .tab-pane.images .input-group {padding:0; text-align:left;}
 .imgupgrp .form-check-input[type="radio"], .imgupgrp .form-check-input[type="checkbox"] {margin-left: 0; position: relative;  width: 13px; height: 13px;}
 .imgupgrp .btn.btn-text {height: fit-content;}
 .imgupgrp .btn.btn-text:hover {opacity: 0.6;}


.gap-06{gap:0.6rem}

/* Commission */
.tcontractwrap .tabs-team .react-tabs .react-tabs__tab--selected::after {background:#FFF}
.docs-complete p, .docs-complete span {color:#1BDD77}
.docs-incomplete p, .docs-incomplete span {color:#ff0000}
.commisionmodal-wrap .ant-modal {height:90vh; overflow-y: scroll;}
.commisionmodal-wrap .form-group > p {font-size: 12px; margin-bottom: 0.3rem;}
.commisionmodal-wrap .listinfo li:first-child {list-style: none;}
.commisionmodal-wrap .listinfo li {list-style: disc inside;}
.commisionmodal-wrap .ant-upload .ant-btn > span:nth-child(2) {background-color:transparent;}
.commisionmodal-wrap .ant-upload-wrapper .ant-upload { margin-bottom: 0;}
.tcontractwrap .ant-table-content {overflow-y: hidden;}
.info-group .ant-btn span {font-size: 12px;}
.info-group .ant-btn { height: fit-content; line-height: 1;  }
.react-tabs__tab-list {overflow-y: hidden !important;}

.tcontractwrap .react-tabs .tabs-team .react-tabs__tab--selected::after {background-color: #FFF;}

/* Brochure */
.ant-modal-wrap .ant-modal.modalbrochurepdf {width:95% !important; border-radius:10px; max-height: 80vh ; height: 100%;overflow:hidden; padding:0 }
.ant-modal-wrap .ant-modal.modalbrochurepdf .pdfscroll {border-left:1px solid rgba(21,21,21,0.16)}
.ant-modal.modalbrochurepdf .ant-modal-content .pdfscroll {overflow-y: scroll;width: 100%;  display: flex;flex-direction: column;   max-height: 77vh; background: #efefef; padding:1rem 0 1rem 1rem;box-shadow: inset 0 0 10px rgba(21,21,21,0.16);}


@media (max-width:560px) {
    .tenancypdf-buttons a, .tenancypdf-buttons button{gap:5px !important; padding: 0 !important; font-size: 9px !important; border-top:none !important }

}