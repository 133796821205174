div.react-trello-board {background: transparent;width: 100%; height:fit-content; padding-bottom:1rem}


div.react-trello-board p {margin-bottom:0; font-size:11px;}
.smooth-dnd-draggable-wrapper article span.lbfWCZ { font-family: 'Poppins-Bold', sans-serif; font-size: 12px;}
.smooth-dnd-container.horizontal  .react-trello-lane > header {padding: 10px 0;justify-content: space-around;}
.smooth-dnd-container.horizontal  .react-trello-lane > header > span {font-size: 14px; font-weight: 400;font-family: 'Poppins-Bold', sans-serif; display:flex; flex-direction:row; align-items:center; color:#FFF}
.smooth-dnd-container.horizontal .react-trello-lane[title="New Enquiry"] > header {background-color: #580aff;}
.smooth-dnd-container.horizontal .react-trello-lane[title="Working"] > header {background-color: #25d366;}
.smooth-dnd-container.horizontal .react-trello-lane[title="Viewings"] > header {background-color: #19c0cc;}
.smooth-dnd-container.horizontal .react-trello-lane[title="Second Viewings"] > header {background-color: #255fd3;}
.smooth-dnd-container.horizontal .react-trello-lane[title="Offers"] > header {background-color: #ffba08;}
.smooth-dnd-container.horizontal .react-trello-lane[title="Offers"] > header {background-color: #ffba08;}
.smooth-dnd-container.horizontal .react-trello-lane[title="Offer Accepted"] > header {background-color: #19c0cc;}

.smooth-dnd-container.horizontal .react-trello-lane {width: 100%;justify-content: space-between; padding:10px;}
.react-trello-lane > header > span:last-child {background: #FFF;padding: 0 5px; width: fit-content; float: right; font-size: 12px; border-radius: 3px; color:#151515}
.react-trello-lane > header > span:last-child span {color:#151515; font-size:11px;}
button{opacity: 0.8; margin-top: 0.5rem;}


.created p:last-child{font-size:8px; letter-spacing:1px; text-transform:uppercase;}
div.react-trello-board .lastactivity{ margin-top:5px; gap:0.6rem}
div.react-trello-board .lastactivity p {font-size: 10px;}
div.react-trello-board .lastactivity > p:first-child { text-transform:uppercase; letter-spacing:1px; font-family: "Poppins-Bold", sans-serif}
.imagewrap .ant-avatar img {height: 23px; width: unset;}

.lastactivity .ant-timeline-item-head i { background-color: #151515; color: #fff;border-radius: 50%; width: 20px;height: 20px; display: block;text-align: center;padding-top: 2px; font-size:10px;}
.lastactivity.timenote .ant-timeline-item-head i.bi { background-color:#ff8500}
.lastactivity.timereq_viewing .ant-timeline-item-head i.bi { background-color:#34b7f1}

.imagewrap {border-top:1px solid rgba(21,21,21,0.06); padding-top:8px; gap:0.3rem}
div.react-trello-board .propinfo {display:flex; flex-direction:row; gap:0.3rem; align-items:center}
.smooth-dnd-container.horizontal {display: flex;flex-direction: row;height: 68.5vh;}
.smooth-dnd-container.horizontal .react-trello-lane {background:rgba(21,21,21,0.06); flex:1 1 auto; max-height:100%;padding: 0;overflow: hidden;border-radius: 5px;}

.smooth-dnd-container.horizontal .react-trello-lane .react-trello-card {margin-bottom:1rem;padding: 0;min-height: 13.7rem;}
.smooth-dnd-container.horizontal .react-trello-card header{padding:10px 10px 5px; margin-bottom:0; height: 4.7rem;}
/* .react-trello-card > div {height: 162.5px;}
.carddesc > div.ant-col.cardinfo {height: 115px;} */


.smooth-dnd-container.horizontal .react-trello-card .carddesc .ant-col{padding:10px}
.smooth-dnd-container.horizontal .react-trello-card .carddesc .ant-col.footer{padding:10px; border-top:1px solid rgba(21,21,21,0.06)}
.smooth-dnd-container.horizontal .react-trello-card .carddesc > .ant-col {padding: 5px 10px;}

/* NEw */
.pipeline button.editbtn {box-shadow:none; height:26px; border-radius:0;align-self:end;padding: 0 5px;  margin-right: -5px; }
.pipeline button.ant-btn-primary.editbtn span{ font-size:12px; }
.leadname p {font-weight:400}
.leadname p:first-child{font-size:13px; }
.leadname p:last-child{font-size:11px; }
.lastcall p:first-child{font-size:11px; }
.lastcall p:last-child{font-size:9px; }

.created p:last-child{font-size:10px}
.carddesc .ant-row span {font-size:11px;}
.carddesc .ant-row .desclabel {display: flex; align-items: start; flex-direction: column; gap: 0.3rem;}
.carddesc .ant-row .descvalue {justify-content: end; display: flex; align-items: end; flex-direction: column; gap: 0.3rem;}
.react-trello-card header > span:nth-child(2) {padding: 0;}
.react-trello-card header > div:last-child {display: none;} /* Close button for cards*/
.react-trello-card .footer {display: flex; flex-direction: row; gap:0.6rem}
.react-trello-card .footer .ant-btn span {font-size:12px}
.smooth-dnd-container.horizontal .react-trello-card .carddesc .ant-col{padding:0 10px}
.smooth-dnd-container.horizontal .react-trello-card .carddesc > .ant-col {padding:10px}
.react-trello-card .carddesc .ant-row .descitem {justify-content:start; align-items:center; display:flex;margin-top: 5px;}
.react-trello-card .carddesc .ant-row .descitem i.bi, .react-trello-card .carddesc .ant-row .descitem i.fa {font-size:11px}

.react-trello-card .footer .ant-btn.ant-btn-primary.editbtn {border-radius: 3px;} 
.react-trello-card .footer .ant-btn.ant-btn-secondary.editbtn {background:#1B4FD7;border-radius:3px }
.react-trello-card .footer .ant-btn.ant-btn-secondary.editbtn span {color:#FFF}

.smooth-dnd-container.horizontal .react-trello-lane:last-child { min-height: 19.17rem; background: #F9F9F9; box-shadow: inset 5px 0 6px rgba(21,21,21,0.02); position: absolute; bottom: -1.5rem; margin: 0; right: 0; height: calc(100% - 5.7rem); border-radius: 0; border-top: 1px solid rgba(21,21,21,0.16); max-width: 320px;}

.react-trello-lane .smooth-dnd-container.vertical {height: 100%; display: flex;min-height: 14.5rem;flex-direction: column;}
.react-trello-card .footer > .ant-btn.ant-btn-primary.editbtn > i {font-size: 10px;padding: 0 3px;}

.smooth-dnd-container.horizontal .react-trello-lane .react-trello-card:hover {background:rgba(255,255,255,0.8); opacity:0.9; box-shadow:0 0 6px rgba(21,21,21,0.26)}

.cardinfo .ant-tag span {font-size: 11px;white-space:normal}
.cardinfo .ant-tag {margin-bottom:3px; border: none; background: rgba(21,21,21,0.06);}

.cardinfo .activityinfo p:first-child {font-size:8px; text-transform:uppercase; letter-spacing:2px}
.cardinfo .activityinfo p:last-child {font-size:11px;}

.pipeline .agentwrap .ant-avatar img { width: 100%;border-radius: 0;}

.smooth-dnd-container.horizontal .react-trello-lane:nth-child(6) {margin-right: 17rem;}
.ant-col-board {position:unset}
.ant-col-board div{ position:unset}

.smooth-dnd-container.horizontal .react-trello-lane:last-child > header > span {font-size:21px; color:#151515; }
.smooth-dnd-container.horizontal .react-trello-lane:last-child > header {padding:1.3rem 10px 10px;}
.smooth-dnd-container.horizontal .react-trello-lane > div {padding: 10px;}
.smooth-dnd-container.vertical > .smooth-dnd-draggable-wrapper {overflow:unset}

.react-trello-lane .smooth-dnd-container.vertical > .smooth-dnd-draggable-wrapper {overflow: unset;}

.react-trello-card .btngrp {display: flex;align-items: center;gap:0.6rem; justify-content:end}
.react-trello-card .btngrp .ant-btn {padding:0; margin:0}
div.react-trello-board .leadname p.font-bold {font-size: 13px;}
.leadname .ctype {font-size: 10px; background: #151515; color: #FFF; padding: 3px 7px; border-radius: 3px;}
.leadname .ctype.buyer {background-color:#985fef}
.leadname .ctype.tenant {background-color:#ef5fd7}
.leadname .ctype.landlord {background-color:#CC0066}
.leadname .ctype.seller {background-color:#184D47}
.leadname .ctype.agent {background-color:#14B1AB}
.leadname .ctype.other {background-color:#342EAD}
.leadname .ctype.portal {background-color:#AEF4A4}
.leadname .ctype.buyertenant {background-color:#E67FA2}

.filterwrap .ant-checkbox span.ant-checkbox-inner {display:none}
.filterwrap .ant-checkbox-group .ant-checkbox-wrapper::before {content:""; height:10px; width:10px;border-radius:50%; background-color:#151515; }
/* .filterwrap .ant-checkbox-group .ant-checkbox-wrapper:first-child .ant-checkbox +span {color:#985fef}
.filterwrap .ant-checkbox-group .ant-checkbox-wrapper:first-child::before{background-color:#985fef}
.filterwrap .ant-checkbox-group .ant-checkbox-wrapper:nth-child(2) .ant-checkbox +span {color:#ef5fd7}
.filterwrap .ant-checkbox-group .ant-checkbox-wrapper:nth-child(2)::before{background-color:#ef5fd7}
.filterwrap .ant-checkbox-group .ant-checkbox-wrapper:nth-child(3) .ant-checkbox +span {color:#CC0066}
.filterwrap .ant-checkbox-group .ant-checkbox-wrapper:nth-child(3)::before{background-color:#CC0066}
.filterwrap .ant-checkbox-group .ant-checkbox-wrapper:nth-child(4) .ant-checkbox +span {color:#184D47}
.filterwrap .ant-checkbox-group .ant-checkbox-wrapper:nth-child(4)::before{background-color:#184D47} */

