@media(max-width:1024px) {
    .ant-collapse.moreoptions .ant-collapse-content-box{
        border: none;
        padding: 10px 10px;
    }
}

@media(max-width:650px){
    .ant-btn span {font-size: 10px;}
    .leadswrap .ant-pagination .ant-pagination-total-text{
        display: none;
    }
    .leadswrap .ant-spin-container .ant-pagination.ant-table-pagination{
        justify-content: center;
    }
}
